import { Col, Row } from 'antd';
import ChartTitle from 'components/ChartTitle/ChartTitle';
import { DualAxesChart } from 'components/ColumnChart/ColumnChart';
import LineChart from 'components/LineChart/LineChart';
import React from 'react';

const RowTwo: React.FC<{ configTable: any }> = ({ configTable }) => (
  <>
    <Row
      style={{ marginTop: '100px' }}
      gutter={[
        { xs: 0, sm: 20 },
        { xs: 20, sm: 0 },
      ]}
    >
      <ChartTitle
        title="５か年　PLグラフ"
        subTitle="年ごとの黒字化や営業利益率、累計利益率に関する達成目標が未達の場合、下のグラフから問題となる年を判断しましょう。"
      />
      <Col xs={24} sm={12}>
        <ChartTitle title="利益推移グラフ" subTitle="" />
        {configTable?.dataLineChart && <LineChart configChart={configTable?.dataLineChart} />}
      </Col>
      <Col xs={24} sm={12}>
        <ChartTitle title="費用推移グラフ" subTitle="" />
        {configTable?.dataColumnChart && <DualAxesChart configChart={configTable?.dataColumnChart} />}
      </Col>
    </Row>
    {configTable?.dataLineChart && (
      <table className="for-test" hidden>
        <tr>
          <td data-testid="linechart-row0-year">{configTable.dataLineChart.data[0].year}</td>
          <td data-testid="linechart-row0-profit">{configTable.dataLineChart.data[0].profit}</td>
          <td data-testid="linechart-row0-value">{configTable.dataLineChart.data[0].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row1-year"> {configTable.dataLineChart.data[1].year}</td>
          <td data-testid="linechart-row1-profit">{configTable.dataLineChart.data[1].profit}</td>
          <td data-testid="linechart-row1-value">{configTable.dataLineChart.data[1].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row2-year">{configTable.dataLineChart.data[2].year}</td>
          <td data-testid="linechart-row2-profit">{configTable.dataLineChart.data[2].profit}</td>
          <td data-testid="linechart-row2-value">{configTable.dataLineChart.data[2].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row3-year">{configTable.dataLineChart.data[3].year}</td>
          <td data-testid="linechart-row3-profit">{configTable.dataLineChart.data[3].profit}</td>
          <td data-testid="linechart-row3-value">{configTable.dataLineChart.data[3].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row4-year"> {configTable.dataLineChart.data[4].year}</td>
          <td data-testid="linechart-row4-profit">{configTable.dataLineChart.data[4].profit}</td>
          <td data-testid="linechart-row4-value"> {configTable.dataLineChart.data[4].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row5-year"> {configTable.dataLineChart.data[5].year}</td>
          <td data-testid="linechart-row5-profit">{configTable.dataLineChart.data[5].profit}</td>
          <td data-testid="linechart-row5-value"> {configTable.dataLineChart.data[5].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row6-year"> {configTable.dataLineChart.data[6].year}</td>
          <td data-testid="linechart-row6-profit">{configTable.dataLineChart.data[6].profit}</td>
          <td data-testid="linechart-row6-value"> {configTable.dataLineChart.data[6].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row7-year"> {configTable.dataLineChart.data[7].year}</td>
          <td data-testid="linechart-row7-profit">{configTable.dataLineChart.data[7].profit}</td>
          <td data-testid="linechart-row7-value"> {configTable.dataLineChart.data[7].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row8-year"> {configTable.dataLineChart.data[8].year}</td>
          <td data-testid="linechart-row8-profit">{configTable.dataLineChart.data[8].profit}</td>
          <td data-testid="linechart-row8-value"> {configTable.dataLineChart.data[8].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row9-year"> {configTable.dataLineChart.data[9].year}</td>
          <td data-testid="linechart-row9-profit">{configTable.dataLineChart.data[9].profit}</td>
          <td data-testid="linechart-row9-value"> {configTable.dataLineChart.data[9].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row10-year"> {configTable.dataLineChart.data[10].year}</td>
          <td data-testid="linechart-row10-profit">{configTable.dataLineChart.data[10].profit}</td>
          <td data-testid="linechart-row10-value"> {configTable.dataLineChart.data[10].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row11-year"> {configTable.dataLineChart.data[11].year}</td>
          <td data-testid="linechart-row11-profit">{configTable.dataLineChart.data[11].profit}</td>
          <td data-testid="linechart-row11-value"> {configTable.dataLineChart.data[11].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row12-year"> {configTable.dataLineChart.data[12].year}</td>
          <td data-testid="linechart-row12-profit">{configTable.dataLineChart.data[12].profit}</td>
          <td data-testid="linechart-row12-value"> {configTable.dataLineChart.data[12].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row13-year"> {configTable.dataLineChart.data[13].year}</td>
          <td data-testid="linechart-row13-profit">{configTable.dataLineChart.data[13].profit}</td>
          <td data-testid="linechart-row13-value"> {configTable.dataLineChart.data[13].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row14-year"> {configTable.dataLineChart.data[14].year}</td>
          <td data-testid="linechart-row14-profit">{configTable.dataLineChart.data[14].profit}</td>
          <td data-testid="linechart-row14-value"> {configTable.dataLineChart.data[14].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row15-year"> {configTable.dataLineChart.data[15].year}</td>
          <td data-testid="linechart-row15-profit">{configTable.dataLineChart.data[15].profit}</td>
          <td data-testid="linechart-row15-value"> {configTable.dataLineChart.data[15].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row16-year"> {configTable.dataLineChart.data[16].year}</td>
          <td data-testid="linechart-row16-profit">{configTable.dataLineChart.data[16].profit}</td>
          <td data-testid="linechart-row16-value"> {configTable.dataLineChart.data[16].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row17-year"> {configTable.dataLineChart.data[17].year}</td>
          <td data-testid="linechart-row17-profit">{configTable.dataLineChart.data[17].profit}</td>
          <td data-testid="linechart-row17-value"> {configTable.dataLineChart.data[17].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row18-year"> {configTable.dataLineChart.data[18].year}</td>
          <td data-testid="linechart-row18-profit">{configTable.dataLineChart.data[18].profit}</td>
          <td data-testid="linechart-row18-value"> {configTable.dataLineChart.data[18].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row19-year"> {configTable.dataLineChart.data[19].year}</td>
          <td data-testid="linechart-row19-profit">{configTable.dataLineChart.data[19].profit}</td>
          <td data-testid="linechart-row19-value"> {configTable.dataLineChart.data[19].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row20-year"> {configTable.dataLineChart.data[20].year}</td>
          <td data-testid="linechart-row20-profit">{configTable.dataLineChart.data[20].profit}</td>
          <td data-testid="linechart-row20-value"> {configTable.dataLineChart.data[20].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row21-year"> {configTable.dataLineChart.data[21].year}</td>
          <td data-testid="linechart-row21-profit">{configTable.dataLineChart.data[21].profit}</td>
          <td data-testid="linechart-row21-value"> {configTable.dataLineChart.data[21].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row22-year"> {configTable.dataLineChart.data[22].year}</td>
          <td data-testid="linechart-row22-profit">{configTable.dataLineChart.data[22].profit}</td>
          <td data-testid="linechart-row22-value"> {configTable.dataLineChart.data[22].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row23-year"> {configTable.dataLineChart.data[23].year}</td>
          <td data-testid="linechart-row23-profit">{configTable.dataLineChart.data[23].profit}</td>
          <td data-testid="linechart-row23-value"> {configTable.dataLineChart.data[23].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row24-year"> {configTable.dataLineChart.data[24].year}</td>
          <td data-testid="linechart-row24-profit">{configTable.dataLineChart.data[24].profit}</td>
          <td data-testid="linechart-row24-value"> {configTable.dataLineChart.data[24].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row25-year"> {configTable.dataLineChart.data[25].year}</td>
          <td data-testid="linechart-row25-profit">{configTable.dataLineChart.data[25].profit}</td>
          <td data-testid="linechart-row25-value"> {configTable.dataLineChart.data[25].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row26-year"> {configTable.dataLineChart.data[26].year}</td>
          <td data-testid="linechart-row26-profit">{configTable.dataLineChart.data[26].profit}</td>
          <td data-testid="linechart-row26-value"> {configTable.dataLineChart.data[26].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row27-year"> {configTable.dataLineChart.data[27].year}</td>
          <td data-testid="linechart-row27-profit">{configTable.dataLineChart.data[27].profit}</td>
          <td data-testid="linechart-row27-value"> {configTable.dataLineChart.data[27].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row28-year"> {configTable.dataLineChart.data[28].year}</td>
          <td data-testid="linechart-row28-profit">{configTable.dataLineChart.data[28].profit}</td>
          <td data-testid="linechart-row28-value"> {configTable.dataLineChart.data[28].value}</td>
        </tr>
        <tr>
          <td data-testid="linechart-row29-year"> {configTable.dataLineChart.data[29].year}</td>
          <td data-testid="linechart-row29-profit">{configTable.dataLineChart.data[29].profit}</td>
          <td data-testid="linechart-row29-value"> {configTable.dataLineChart.data[29].value}</td>
        </tr>
      </table>
    )}
    {configTable?.dataLineChart && (
      <table className="for-test" hidden>
        <tr>
          <td data-testid="columnchart-0-0-year"> {configTable.dataColumnChart.data[0][0].year}</td>
          <td data-testid="columnchart-0-0-title"> {configTable.dataColumnChart.data[0][0].title}</td>
          <td data-testid="columnchart-0-0-value"> {configTable.dataColumnChart.data[0][0].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-0-1-year"> {configTable.dataColumnChart.data[0][1].year}</td>
          <td data-testid="columnchart-0-1-title"> {configTable.dataColumnChart.data[0][1].title}</td>
          <td data-testid="columnchart-0-1-value"> {configTable.dataColumnChart.data[0][1].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-0-2-year"> {configTable.dataColumnChart.data[0][2].year}</td>
          <td data-testid="columnchart-0-2-title"> {configTable.dataColumnChart.data[0][2].title}</td>
          <td data-testid="columnchart-0-2-value"> {configTable.dataColumnChart.data[0][2].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-0-3-year"> {configTable.dataColumnChart.data[0][3].year}</td>
          <td data-testid="columnchart-0-3-title"> {configTable.dataColumnChart.data[0][3].title}</td>
          <td data-testid="columnchart-0-3-value"> {configTable.dataColumnChart.data[0][3].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-0-4-year"> {configTable.dataColumnChart.data[0][4].year}</td>
          <td data-testid="columnchart-0-4-title"> {configTable.dataColumnChart.data[0][4].title}</td>
          <td data-testid="columnchart-0-4-value"> {configTable.dataColumnChart.data[0][4].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-0-5-year"> {configTable.dataColumnChart.data[0][5].year}</td>
          <td data-testid="columnchart-0-5-title"> {configTable.dataColumnChart.data[0][5].title}</td>
          <td data-testid="columnchart-0-5-value"> {configTable.dataColumnChart.data[0][5].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-0-6-year"> {configTable.dataColumnChart.data[0][6].year}</td>
          <td data-testid="columnchart-0-6-title"> {configTable.dataColumnChart.data[0][6].title}</td>
          <td data-testid="columnchart-0-6-value"> {configTable.dataColumnChart.data[0][6].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-0-7-year"> {configTable.dataColumnChart.data[0][7].year}</td>
          <td data-testid="columnchart-0-7-title"> {configTable.dataColumnChart.data[0][7].title}</td>
          <td data-testid="columnchart-0-7-value"> {configTable.dataColumnChart.data[0][7].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-0-8-year"> {configTable.dataColumnChart.data[0][8].year}</td>
          <td data-testid="columnchart-0-8-title"> {configTable.dataColumnChart.data[0][8].title}</td>
          <td data-testid="columnchart-0-8-value"> {configTable.dataColumnChart.data[0][8].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-0-9-year"> {configTable.dataColumnChart.data[0][9].year}</td>
          <td data-testid="columnchart-0-9-title"> {configTable.dataColumnChart.data[0][9].title}</td>
          <td data-testid="columnchart-0-9-value"> {configTable.dataColumnChart.data[0][9].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-0-10-year"> {configTable.dataColumnChart.data[0][10].year}</td>
          <td data-testid="columnchart-0-10-title"> {configTable.dataColumnChart.data[0][10].title}</td>
          <td data-testid="columnchart-0-10-value"> {configTable.dataColumnChart.data[0][10].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-0-11-year"> {configTable.dataColumnChart.data[0][11].year}</td>
          <td data-testid="columnchart-0-11-title"> {configTable.dataColumnChart.data[0][11].title}</td>
          <td data-testid="columnchart-0-11-value"> {configTable.dataColumnChart.data[0][11].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-0-12-year"> {configTable.dataColumnChart.data[0][12].year}</td>
          <td data-testid="columnchart-0-12-title"> {configTable.dataColumnChart.data[0][12].title}</td>
          <td data-testid="columnchart-0-12-value"> {configTable.dataColumnChart.data[0][12].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-0-13-year"> {configTable.dataColumnChart.data[0][13].year}</td>
          <td data-testid="columnchart-0-13-title"> {configTable.dataColumnChart.data[0][13].title}</td>
          <td data-testid="columnchart-0-13-value"> {configTable.dataColumnChart.data[0][13].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-0-14-year"> {configTable.dataColumnChart.data[0][14].year}</td>
          <td data-testid="columnchart-0-14-title"> {configTable.dataColumnChart.data[0][14].title}</td>
          <td data-testid="columnchart-0-14-value"> {configTable.dataColumnChart.data[0][14].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-1-0-year"> {configTable.dataColumnChart.data[1][0].year}</td>
          <td data-testid="columnchart-1-0-name"> {configTable.dataColumnChart.data[1][0].name}</td>
          <td data-testid="columnchart-1-0-count"> {configTable.dataColumnChart.data[1][0].count}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-1-1-year"> {configTable.dataColumnChart.data[1][1].year}</td>
          <td data-testid="columnchart-1-1-name"> {configTable.dataColumnChart.data[1][1].name}</td>
          <td data-testid="columnchart-1-1-count"> {configTable.dataColumnChart.data[1][1].count}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-1-2-year"> {configTable.dataColumnChart.data[1][2].year}</td>
          <td data-testid="columnchart-1-2-name"> {configTable.dataColumnChart.data[1][2].name}</td>
          <td data-testid="columnchart-1-2-count"> {configTable.dataColumnChart.data[1][2].count}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-1-3-year"> {configTable.dataColumnChart.data[1][3].year}</td>
          <td data-testid="columnchart-1-3-name"> {configTable.dataColumnChart.data[1][3].name}</td>
          <td data-testid="columnchart-1-3-count"> {configTable.dataColumnChart.data[1][3].count}</td>
        </tr>
        <tr>
          <td data-testid="columnchart-1-4-year"> {configTable.dataColumnChart.data[1][4].year}</td>
          <td data-testid="columnchart-1-4-name"> {configTable.dataColumnChart.data[1][4].name}</td>
          <td data-testid="columnchart-1-4-count"> {configTable.dataColumnChart.data[1][4].count}</td>
        </tr>
      </table>
    )}
  </>
);
export default RowTwo;
