/* eslint-disable complexity */
import React from 'react';
import SheetTable from '../../../components/SheetTable/SheetTable';
import { THEME_TABLE, OPTIONS_TABLE, OPTIONS_TABLE_ROW_3 } from './AssignmentCalculatorGrade.state';

const CalculatorGradeForm: React.FC<{ configTable: any }> = ({ configTable }) => (
  <div>
    <div>
      <p>売上（年間）</p>
      {configTable?.configTable1 && (
        <SheetTable dataCfg={configTable?.configTable1} options={OPTIONS_TABLE} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table1-row0-value1">{configTable.configTable1.data[0].value1}</td>
        <td data-testid="table1-row0-value2">{configTable.configTable1.data[0].value2}</td>
        <td data-testid="table1-row0-value3">{configTable.configTable1.data[0].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row1-value1">{configTable.configTable1.data[1].value1}</td>
        <td data-testid="table1-row1-value2">{configTable.configTable1.data[1].value2}</td>
        <td data-testid="table1-row1-value3">{configTable.configTable1.data[1].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row2-value1">{configTable.configTable1.data[2].value1}</td>
        <td data-testid="table1-row2-value2">{configTable.configTable1.data[2].value2}</td>
        <td data-testid="table1-row2-value3">{configTable.configTable1.data[2].value3}</td>
      </tr>
    </table>

    {/* row 2 */}
    <div>
      <p>販促活動費（年間）</p>
      {configTable?.configTable2 && (
        <SheetTable dataCfg={configTable?.configTable2} options={OPTIONS_TABLE_ROW_3} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table2-row0-value1">{configTable.configTable2.data[0].value1}</td>
        <td data-testid="table2-row0-value2">{configTable.configTable2.data[0].value2}</td>
        <td data-testid="table2-row0-value3">{configTable.configTable2.data[0].value3}</td>
      </tr>
      <tr>
        <td data-testid="table2-row1-value1">{configTable.configTable2.data[1].value1}</td>
        <td data-testid="table2-row1-value2">{configTable.configTable2.data[1].value2}</td>
        <td data-testid="table2-row1-value3">{configTable.configTable2.data[1].value3}</td>
      </tr>
    </table>

    {/* row 3 */}
    <div>
      <p>梱包材料費（年間）</p>
      {configTable?.configTable3 && (
        <SheetTable dataCfg={configTable?.configTable3} options={OPTIONS_TABLE_ROW_3} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table3-row0-value1">{configTable.configTable3.data[0].value1}</td>
        <td data-testid="table3-row0-value2">{configTable.configTable3.data[0].value2}</td>
        <td data-testid="table3-row0-value3">{configTable.configTable3.data[0].value3}</td>
      </tr>
      <tr>
        <td data-testid="table3-row1-value1">{configTable.configTable3.data[1].value1}</td>
        <td data-testid="table3-row1-value2">{configTable.configTable3.data[1].value2}</td>
        <td data-testid="table3-row1-value3">{configTable.configTable3.data[1].value3}</td>
      </tr>
    </table>

    {/* row 4 */}
    <div>
      <p>物流・輸送費（年間）</p>
      {configTable?.configTable4 && (
        <SheetTable dataCfg={configTable?.configTable4} options={OPTIONS_TABLE_ROW_3} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table4-row0-value1">{configTable.configTable4.data[0].value1}</td>
        <td data-testid="table4-row0-value2">{configTable.configTable4.data[0].value2}</td>
        <td data-testid="table4-row0-value3">{configTable.configTable4.data[0].value3}</td>
      </tr>
      <tr>
        <td data-testid="table4-row1-value1">{configTable.configTable4.data[1].value1}</td>
        <td data-testid="table4-row1-value2">{configTable.configTable4.data[1].value2}</td>
        <td data-testid="table4-row1-value3">{configTable.configTable4.data[1].value3}</td>
      </tr>
    </table>

    {/* row 5 */}
    <div>
      <p>その他経費（年間）</p>
      {configTable?.configTable5 && (
        <SheetTable dataCfg={configTable?.configTable5} options={OPTIONS_TABLE_ROW_3} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table5-row0-value1">{configTable.configTable5.data[0].value1}</td>
        <td data-testid="table5-row0-value2">{configTable.configTable5.data[0].value2}</td>
        <td data-testid="table5-row0-value3">{configTable.configTable5.data[0].value3}</td>
      </tr>
      <tr>
        <td data-testid="table5-row1-value1">{configTable.configTable5.data[1].value1}</td>
        <td data-testid="table5-row1-value2">{configTable.configTable5.data[1].value2}</td>
        <td data-testid="table5-row1-value3">{configTable.configTable5.data[1].value3}</td>
      </tr>
    </table>

    {/* row 6 */}
    <div>
      {/* <p>機器稼働費（年間）</p> */}
      {configTable?.configTable6 && (
        <SheetTable dataCfg={configTable?.configTable6} options={OPTIONS_TABLE} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table6-row0-title">{configTable.configTable6.data[0].title}</td>
        <td data-testid="table6-row0-value1">{configTable.configTable6.data[0].value1}</td>
      </tr>
    </table>
  </div>
);

export default CalculatorGradeForm;
