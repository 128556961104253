import React from 'react';
import { Col, Row } from 'antd';
import ChartTitle from 'components/ChartTitle/ChartTitle';
import ColumnChart from 'components/ColumnChart/ColumnChart';
import PieChart from 'components/PieChart/PieChart';
import styles from '../style.module.scss';

const Row3: React.FC<{ configTable: any }> = ({ configTable }) => (
  <>
    <Row
      gutter={[
        { xs: 0, sm: 20 },
        { xs: 20, sm: 0 },
      ]}
    >
      <ChartTitle
        title="生産計画表"
        subTitle="生産計画表では、設問の回答結果から算出した収量の推移や等級割合のシミュレーション結果を確認できます。"
      />
      <Col xs={24} sm={10}>
        <Row className={`${styles.subTitle} ${styles.borderSubTitleB}`}>
          <div className="left">等級割合グラフ</div>
        </Row>
        {configTable?.dataPieChartTab4 && <PieChart configChart={configTable?.dataPieChartTab4} />}
      </Col>
      <Col xs={24} sm={14}>
        <Row className={`${styles.subTitle} ${styles.borderSubTitleB}`}>
          <div className="left">年別収量推移グラフ</div>
        </Row>
        {configTable?.dataColumnChartTab4Two && <ColumnChart configChart={configTable?.dataColumnChartTab4Two} />}
      </Col>
    </Row>
    {configTable?.dataPieChartTab4 && (
      <table className="for-test" hidden>
        <tr>
          <td data-testid="datapiecharttab4-row0--type">{configTable?.dataPieChartTab4.data[0].type}</td>
          <td data-testid="datapiecharttab4-row0-value">{configTable?.dataPieChartTab4.data[0].value}</td>
        </tr>
        <tr>
          <td data-testid="datapiecharttab4-row1--type">{configTable?.dataPieChartTab4.data[1].type}</td>
          <td data-testid="datapiecharttab4-row1-value">{configTable?.dataPieChartTab4.data[1].value}</td>
        </tr>
      </table>
    )}
    {configTable?.dataColumnChartTab4Two && (
      <table className="for-test" hidden>
        <tr>
          <td data-testid="datacolumncharttab42-row0-month">{configTable?.dataColumnChartTab4Two.data[0].month}</td>
          <td data-testid="datacolumncharttab42-row0--type">{configTable?.dataColumnChartTab4Two.data[0].type}</td>
          <td data-testid="datacolumncharttab42-row0-value">{configTable?.dataColumnChartTab4Two.data[0].value}</td>
          <td data-testid="datacolumncharttab42-row1-month">{configTable?.dataColumnChartTab4Two.data[1].month}</td>
          <td data-testid="datacolumncharttab42-row1--type">{configTable?.dataColumnChartTab4Two.data[1].type}</td>
          <td data-testid="datacolumncharttab42-row1-value">{configTable?.dataColumnChartTab4Two.data[1].value}</td>
        </tr>
        <tr>
          <td data-testid="datacolumncharttab42-row2-month">{configTable?.dataColumnChartTab4Two.data[2].month}</td>
          <td data-testid="datacolumncharttab42-row2--type">{configTable?.dataColumnChartTab4Two.data[2].type}</td>
          <td data-testid="datacolumncharttab42-row2-value">{configTable?.dataColumnChartTab4Two.data[2].value}</td>
          <td data-testid="datacolumncharttab42-row3-month">{configTable?.dataColumnChartTab4Two.data[3].month}</td>
          <td data-testid="datacolumncharttab42-row3--type">{configTable?.dataColumnChartTab4Two.data[3].type}</td>
          <td data-testid="datacolumncharttab42-row3-value">{configTable?.dataColumnChartTab4Two.data[3].value}</td>
        </tr>
        <tr>
          <td data-testid="datacolumncharttab42-row4-month">{configTable?.dataColumnChartTab4Two.data[4].month}</td>
          <td data-testid="datacolumncharttab42-row4--type">{configTable?.dataColumnChartTab4Two.data[4].type}</td>
          <td data-testid="datacolumncharttab42-row4-value">{configTable?.dataColumnChartTab4Two.data[4].value}</td>
          <td data-testid="datacolumncharttab42-row5-month">{configTable?.dataColumnChartTab4Two.data[5].month}</td>
          <td data-testid="datacolumncharttab42-row5--type">{configTable?.dataColumnChartTab4Two.data[5].type}</td>
          <td data-testid="datacolumncharttab42-row5-value">{configTable?.dataColumnChartTab4Two.data[5].value}</td>
        </tr>
        <tr>
          <td data-testid="datacolumncharttab42-row6-month">{configTable?.dataColumnChartTab4Two.data[6].month}</td>
          <td data-testid="datacolumncharttab42-row6--type">{configTable?.dataColumnChartTab4Two.data[6].type}</td>
          <td data-testid="datacolumncharttab42-row6-value">{configTable?.dataColumnChartTab4Two.data[6].value}</td>
          <td data-testid="datacolumncharttab42-row7-month">{configTable?.dataColumnChartTab4Two.data[7].month}</td>
          <td data-testid="datacolumncharttab42-row7--type">{configTable?.dataColumnChartTab4Two.data[7].type}</td>
          <td data-testid="datacolumncharttab42-row7-value">{configTable?.dataColumnChartTab4Two.data[7].value}</td>
        </tr>
        <tr>
          <td data-testid="datacolumncharttab42-row8-month">{configTable?.dataColumnChartTab4Two.data[8].month}</td>
          <td data-testid="datacolumncharttab42-row8--type">{configTable?.dataColumnChartTab4Two.data[8].type}</td>
          <td data-testid="datacolumncharttab42-row8-value">{configTable?.dataColumnChartTab4Two.data[8].value}</td>
          <td data-testid="datacolumncharttab42-row9-month">{configTable?.dataColumnChartTab4Two.data[9].month}</td>
          <td data-testid="datacolumncharttab42-row9--type">{configTable?.dataColumnChartTab4Two.data[9].type}</td>
          <td data-testid="datacolumncharttab42-row9-value">{configTable?.dataColumnChartTab4Two.data[9].value}</td>
        </tr>
      </table>
    )}
  </>
);

export default Row3;
