import { S2DataConfig } from '@antv/s2';
import { IndexedObject } from 'constants/types';
import { Calculator } from 'libs/calculators';
import { numberWithCommas, removeCommas } from 'libs/helpers/functions';
import { TTemplate } from 'types/template';
import { TTrainingResult } from 'types/trainingResult';

type column = {
  type: string;
  value: number;
};

export const OPTIONS_TABLE_VALUE = {
  style: {
    colCfg: {
      widthByFieldValue: {
        col1: 150,
        col2: 150,
        col3: 150,
        col4: 150,
        col5: 150,
        col6: 200,
        col7: 200,
      },
    },
  },
  conditions: {
    text: [
      {
        field: 'col1',
        mapping(index: any, value: any) {
          if (Number(removeCommas(value.col1)) < 0) {
            return { fill: '#FF4D4F' };
          }
          return { fill: '#00000' };
        },
      },
      {
        field: 'col2',
        mapping(index: any, value: any) {
          if (Number(removeCommas(value.col1)) < 0) {
            return { fill: '#FF4D4F' };
          }
          return { fill: '#00000' };
        },
      },
      {
        field: 'col3',
        mapping(index: any, value: any) {
          if (Number(removeCommas(value.col1)) < 0) {
            return { fill: '#FF4D4F' };
          }
          return { fill: '#00000' };
        },
      },
      {
        field: 'col4',
        mapping(index: any, value: any) {
          if (Number(removeCommas(value.col1)) < 0) {
            return { fill: '#FF4D4F' };
          }
          return { fill: '#00000' };
        },
      },
      {
        field: 'col5',
        mapping(index: any, value: any) {
          if (Number(removeCommas(value.col1)) < 0) {
            return { fill: '#FF4D4F' };
          }
          return { fill: '#00000' };
        },
      },
      {
        field: 'col6',
        mapping(index: any, value: any) {
          if (Number(removeCommas(value.col1)) < 0) {
            return { fill: '#FF4D4F' };
          }
          return { fill: '#00000' };
        },
      },
      {
        field: 'col7',
        mapping(index: any, value: any) {
          if (Number(removeCommas(value.col1)) < 0) {
            return { fill: '#FF4D4F' };
          }
          return { fill: '#00000' };
        },
      },
    ],
  },
};

export const OPTIONS_TABLE_INVEST = {
  conditions: {
    text: [
      {
        field: '投資額',
        mapping(index: any, value: any) {
          if (Number(removeCommas(value.投資額)) < 0) {
            return { fill: '#FF4D4F' };
          }
          return { fill: '#00000' };
        },
      },
    ],
  },
};

export type TConfigTab2Fn = {
  CONFIG_TABLE_VALUE: S2DataConfig;
  CONFIG_TABLE_VALUE_DATA: any;
  DATA_COLUMN_CHART_TAB2: IndexedObject;
  DATA_COLUMN_CHART_TAB2_DATA: any;
  CONFIG_TABLE_INVEST: S2DataConfig;
  CONFIG_TABLE_INVEST_DATA: any;
};

export const configTab2Fn = (template: TTemplate, trainingResult: TTrainingResult): TConfigTab2Fn => {
  const cal = new Calculator(template, trainingResult);

  const toString = (value: number | string) => {
    if (typeof value === 'number') {
      return numberWithCommas(value);
    }
    return value;
  };

  const CONFIG_TABLE_VALUE_DATA = [
    {
      row1: '1年目',
      col1: numberWithCommas(cal.Y201(1)),
      col2: toString(cal.Y261(1)),
      col3: numberWithCommas(cal.Y262(1)),
      col4: numberWithCommas(cal.Y263(1)),
      col5: toString(cal.Y266(1)),
      col6: numberWithCommas(cal.Y265(1)),
      col7: numberWithCommas(cal.Y264(1)),
    },
    {
      row1: '2年目',
      col1: numberWithCommas(cal.Y201(2)),
      col2: toString(cal.Y261(2)),
      col3: numberWithCommas(cal.Y262(2)),
      col4: numberWithCommas(cal.Y263(2)),
      col5: toString(cal.Y266(2)),
      col6: numberWithCommas(cal.Y265(2)),
      col7: numberWithCommas(cal.Y264(2)),
    },
    {
      row1: '3年目',
      col1: numberWithCommas(cal.Y201(3)),
      col2: toString(cal.Y261(3)),
      col3: numberWithCommas(cal.Y262(3)),
      col4: numberWithCommas(cal.Y263(3)),
      col5: toString(cal.Y266(3)),
      col6: numberWithCommas(cal.Y265(3)),
      col7: numberWithCommas(cal.Y264(3)),
    },
    {
      row1: '4年目',
      col1: numberWithCommas(cal.Y201(4)),
      col2: toString(cal.Y261(4)),
      col3: numberWithCommas(cal.Y262(4)),
      col4: numberWithCommas(cal.Y263(4)),
      col5: toString(cal.Y266(4)),
      col6: numberWithCommas(cal.Y265(4)),
      col7: numberWithCommas(cal.Y264(4)),
    },
    {
      row1: '5年目',
      col1: numberWithCommas(cal.Y201(5)),
      col2: toString(cal.Y261(5)),
      col3: numberWithCommas(cal.Y262(5)),
      col4: numberWithCommas(cal.Y263(5)),
      col5: toString(cal.Y266(5)),
      col6: numberWithCommas(cal.Y265(5)),
      col7: numberWithCommas(cal.Y264(5)),
    },
  ];

  const CONFIG_TABLE_VALUE = {
    fields: {
      rows: ['row1'],
      values: ['col1', 'col2', 'col3', 'col4', 'col5', 'col6', 'col7'],
    },
    meta: [
      {
        field: 'row1',
        name: ' ',
      },
      {
        field: 'col1',
        name: '投資額',
      },
      {
        field: 'col2',
        name: '自己資金',
      },
      {
        field: 'col3',
        name: '補助金額',
      },
      {
        field: 'col4',
        name: '銀行借入額',
      },
      {
        field: 'col5',
        name: 'その他借入額',
      },
      {
        field: 'col6',
        name: '銀行借入金　年度末の返済額',
      },
      {
        field: 'col7',
        name: 'その他借入金　年度末の返済額',
      },
    ],
    data: CONFIG_TABLE_VALUE_DATA,
  };

  const DATA_COLUMN_CHART_TAB2_DATA = [
    {
      type: 'ハウス施設投資',
      value: cal.Y210(),
    },
    {
      type: '機器投資',
      value: cal.Y220(),
    },
    {
      type: 'モニタリング',
      value: cal.Y230(),
    },
    {
      type: '制御盤',
      value: cal.Y240(),
    },
    {
      type: 'その他大型機械',
      value: cal.Y250(),
    },
    {
      type: 'ロボット投資',
      value: cal.Y280(),
    },
    {
      type: 'AI投資',
      value: cal.Y290(),
    },
  ];

  const DATA_COLUMN_CHART_TAB2 = {
    data: DATA_COLUMN_CHART_TAB2_DATA,
    xField: 'type',
    yField: 'value',
    seriesField: '',
    color: (type: string) => {
      if (type === '10-30分' || type === '30+分') {
        return '#F4664A';
      }

      return '#5B8FF9';
    },
    label: {
      content: (originData: column) => {
        const val = parseFloat(String(originData.value));
        return numberWithCommas(val);
      },
      offset: 10,
    },
    yAxis: {
      label: {
        formatter: (datum: string) => numberWithCommas(Number(datum)),
      },
    },
    tooltip: {
      formatter: (datum: IndexedObject) => ({ name: datum.type, value: numberWithCommas(datum.value) }),
    },
  };

  const CONFIG_TABLE_INVEST_DATA = [
    {
      投資対象: 'ハウス施設投資',
      投資額: numberWithCommas(cal.Y210()),
    },
    {
      投資対象: '機器投資',
      投資額: numberWithCommas(cal.Y220()),
    },
    {
      投資対象: 'モニタリング',
      投資額: numberWithCommas(cal.Y230()),
    },
    {
      投資対象: '制御盤',
      投資額: numberWithCommas(cal.Y240()),
    },
    {
      投資対象: 'その他大型機械',
      投資額: numberWithCommas(cal.Y250()),
    },
    {
      投資対象: 'ロボット投資',
      投資額: numberWithCommas(cal.Y280()),
    },
    {
      投資対象: 'AI投資',
      投資額: numberWithCommas(cal.Y290()),
    },
  ];
  const CONFIG_TABLE_INVEST = {
    fields: {
      rows: ['投資対象'],
      values: ['投資額'],
    },
    data: CONFIG_TABLE_INVEST_DATA,
  };

  return {
    CONFIG_TABLE_VALUE,
    CONFIG_TABLE_VALUE_DATA,
    DATA_COLUMN_CHART_TAB2,
    DATA_COLUMN_CHART_TAB2_DATA,
    CONFIG_TABLE_INVEST,
    CONFIG_TABLE_INVEST_DATA,
  };
};
