import { Col, Row } from 'antd';
import AchievementGoal from 'components/AchievementGoal/achievementGoal';
import ChartTitle from 'components/ChartTitle/ChartTitle';
import SheetTable from 'components/SheetTable/SheetTable';
import React from 'react';

const RowOne: React.FC<{ configTable: any }> = ({ configTable }) => (
  <>
    <Row
      gutter={[
        { xs: 0, sm: 20 },
        { xs: 20, sm: 0 },
      ]}
    >
      <ChartTitle title="５か年　PL一覧表" />
      <Col xs={24} sm={18} style={{ minHeight: '520px' }}>
        {configTable?.configTable && (
          <SheetTable dataCfg={configTable?.configTable} options={configTable.OPTIONS_TABLE} />
        )}
      </Col>
      <Col xs={24} sm={6}>
        <AchievementGoal />
      </Col>
    </Row>
    {configTable?.configTable && (
      <table className="for-test" hidden>
        <tr>
          <td data-testid="table-row0-col1">{configTable.configTable.data[0].col1}</td>
          <td data-testid="table-row0-col2">{configTable.configTable.data[0].col2}</td>
          <td data-testid="table-row0-col3">{configTable.configTable.data[0].col3}</td>
          <td data-testid="table-row0-col4">{configTable.configTable.data[0].col4}</td>
          <td data-testid="table-row0-col5">{configTable.configTable.data[0].col5}</td>
        </tr>
        <tr>
          <td data-testid="table-row1-col1">{configTable.configTable.data[1].col1}</td>
          <td data-testid="table-row1-col2">{configTable.configTable.data[1].col2}</td>
          <td data-testid="table-row1-col3">{configTable.configTable.data[1].col3}</td>
          <td data-testid="table-row1-col4">{configTable.configTable.data[1].col4}</td>
          <td data-testid="table-row1-col5">{configTable.configTable.data[1].col5}</td>
        </tr>
        <tr>
          <td data-testid="table-row2-col1">{configTable.configTable.data[2].col1}</td>
          <td data-testid="table-row2-col2">{configTable.configTable.data[2].col2}</td>
          <td data-testid="table-row2-col3">{configTable.configTable.data[2].col3}</td>
          <td data-testid="table-row2-col4">{configTable.configTable.data[2].col4}</td>
          <td data-testid="table-row2-col5">{configTable.configTable.data[2].col5}</td>
        </tr>
        <tr>
          <td data-testid="table-row3-col1">{configTable.configTable.data[3].col1}</td>
          <td data-testid="table-row3-col2">{configTable.configTable.data[3].col2}</td>
          <td data-testid="table-row3-col3">{configTable.configTable.data[3].col3}</td>
          <td data-testid="table-row3-col4">{configTable.configTable.data[3].col4}</td>
          <td data-testid="table-row3-col5">{configTable.configTable.data[3].col5}</td>
        </tr>
        <tr>
          <td data-testid="table-row4-col1">{configTable.configTable.data[4].col1}</td>
          <td data-testid="table-row4-col2">{configTable.configTable.data[4].col2}</td>
          <td data-testid="table-row4-col3">{configTable.configTable.data[4].col3}</td>
          <td data-testid="table-row4-col4">{configTable.configTable.data[4].col4}</td>
          <td data-testid="table-row4-col5">{configTable.configTable.data[4].col5}</td>
        </tr>
        <tr>
          <td data-testid="table-row5-col1">{configTable.configTable.data[5].col1}</td>
          <td data-testid="table-row5-col2">{configTable.configTable.data[5].col2}</td>
          <td data-testid="table-row5-col3">{configTable.configTable.data[5].col3}</td>
          <td data-testid="table-row5-col4">{configTable.configTable.data[5].col4}</td>
          <td data-testid="table-row5-col5">{configTable.configTable.data[5].col5}</td>
        </tr>
        <tr>
          <td data-testid="table-row6-col1">{configTable.configTable.data[6].col1}</td>
          <td data-testid="table-row6-col2">{configTable.configTable.data[6].col2}</td>
          <td data-testid="table-row6-col3">{configTable.configTable.data[6].col3}</td>
          <td data-testid="table-row6-col4">{configTable.configTable.data[6].col4}</td>
          <td data-testid="table-row6-col5">{configTable.configTable.data[6].col5}</td>
        </tr>
        <tr>
          <td data-testid="table-row7-col1">{configTable.configTable.data[7].col1}</td>
          <td data-testid="table-row7-col2">{configTable.configTable.data[7].col2}</td>
          <td data-testid="table-row7-col3">{configTable.configTable.data[7].col3}</td>
          <td data-testid="table-row7-col4">{configTable.configTable.data[7].col4}</td>
          <td data-testid="table-row7-col5">{configTable.configTable.data[7].col5}</td>
        </tr>
        <tr>
          <td data-testid="table-row8-col1">{configTable.configTable.data[8].col1}</td>
          <td data-testid="table-row8-col2">{configTable.configTable.data[8].col2}</td>
          <td data-testid="table-row8-col3">{configTable.configTable.data[8].col3}</td>
          <td data-testid="table-row8-col4">{configTable.configTable.data[8].col4}</td>
          <td data-testid="table-row8-col5">{configTable.configTable.data[8].col5}</td>
        </tr>
        <tr>
          <td data-testid="table-row9-col1">{configTable.configTable.data[9].col1}</td>
          <td data-testid="table-row9-col2">{configTable.configTable.data[9].col2}</td>
          <td data-testid="table-row9-col3">{configTable.configTable.data[9].col3}</td>
          <td data-testid="table-row9-col4">{configTable.configTable.data[9].col4}</td>
          <td data-testid="table-row9-col5">{configTable.configTable.data[9].col5}</td>
        </tr>
        <tr>
          <td data-testid="table-row10-col1">{configTable.configTable.data[10].col1}</td>
          <td data-testid="table-row10-col2">{configTable.configTable.data[10].col2}</td>
          <td data-testid="table-row10-col3">{configTable.configTable.data[10].col3}</td>
          <td data-testid="table-row10-col4">{configTable.configTable.data[10].col4}</td>
          <td data-testid="table-row10-col5">{configTable.configTable.data[10].col5}</td>
        </tr>
        <tr>
          <td data-testid="table-row11-col1">{configTable.configTable.data[11].col1}</td>
          <td data-testid="table-row11-col2">{configTable.configTable.data[11].col2}</td>
          <td data-testid="table-row11-col3">{configTable.configTable.data[11].col3}</td>
          <td data-testid="table-row11-col4">{configTable.configTable.data[11].col4}</td>
          <td data-testid="table-row11-col5">{configTable.configTable.data[11].col5}</td>
        </tr>
        <tr>
          <td data-testid="table-row12-col1">{configTable.configTable.data[12].col1}</td>
          <td data-testid="table-row12-col2">{configTable.configTable.data[12].col2}</td>
          <td data-testid="table-row12-col3">{configTable.configTable.data[12].col3}</td>
          <td data-testid="table-row12-col4">{configTable.configTable.data[12].col4}</td>
          <td data-testid="table-row12-col5">{configTable.configTable.data[12].col5}</td>
        </tr>
        <tr>
          <td data-testid="table-row13-col1">{configTable.configTable.data[13].col1}</td>
          <td data-testid="table-row13-col2">{configTable.configTable.data[13].col2}</td>
          <td data-testid="table-row13-col3">{configTable.configTable.data[13].col3}</td>
          <td data-testid="table-row13-col4">{configTable.configTable.data[13].col4}</td>
          <td data-testid="table-row13-col5">{configTable.configTable.data[13].col5}</td>
        </tr>
        <tr>
          <td data-testid="table-row14-col1">{configTable.configTable.data[14].col1}</td>
          <td data-testid="table-row14-col2">{configTable.configTable.data[14].col2}</td>
          <td data-testid="table-row14-col3">{configTable.configTable.data[14].col3}</td>
          <td data-testid="table-row14-col4">{configTable.configTable.data[14].col4}</td>
          <td data-testid="table-row14-col5">{configTable.configTable.data[14].col5}</td>
        </tr>
        <tr>
          <td data-testid="table-row15-col1">{configTable.configTable.data[15].col1}</td>
          <td data-testid="table-row15-col2">{configTable.configTable.data[15].col2}</td>
          <td data-testid="table-row15-col3">{configTable.configTable.data[15].col3}</td>
          <td data-testid="table-row15-col4">{configTable.configTable.data[15].col4}</td>
          <td data-testid="table-row15-col5">{configTable.configTable.data[15].col5}</td>
        </tr>
        <tr>
          <td data-testid="table-row16-col1">{configTable.configTable.data[16].col1}</td>
          <td data-testid="table-row16-col2">{configTable.configTable.data[16].col2}</td>
          <td data-testid="table-row16-col3">{configTable.configTable.data[16].col3}</td>
          <td data-testid="table-row16-col4">{configTable.configTable.data[16].col4}</td>
          <td data-testid="table-row16-col5">{configTable.configTable.data[16].col5}</td>
        </tr>
      </table>
    )}
  </>
);
export default RowOne;
