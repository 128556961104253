/* eslint-disable complexity */
import React from 'react';
import ColumnChart from 'components/ColumnChart/ColumnChart';
import PieChart from 'components/PieChart/PieChart';
import styles from './style.module.scss';
import SheetTable from '../../../components/SheetTable/SheetTable';
import {
  OPTIONS_TABLE1,
  THEME_TABLE,
  OPTIONS_TABLE2,
  OPTIONS_TABLE3,
  OPTIONS_TABLE4,
  OPTIONS_TABLE5,
  OPTIONS_TABLE6,
  OPTIONS_TABLE7,
  OPTIONS_TABLE8,
} from './AssignmentQuestion2.state';

const AssignmentQuestionForm: React.FC<{ configTable: any }> = ({ configTable }) => (
  <div>
    <div>
      <p>土地・施設</p>
      {configTable?.configTable1 && (
        <SheetTable dataCfg={configTable?.configTable1} options={OPTIONS_TABLE1} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table1-row0-value1">{configTable.configTable1.data[0].value1}</td>
        <td data-testid="table1-row0-value2">{configTable.configTable1.data[0].value2}</td>
      </tr>
      <tr>
        <td data-testid="table1-row1-value1">{configTable.configTable1.data[1].value1}</td>
        <td data-testid="table1-row1-value2">{configTable.configTable1.data[1].value2}</td>
      </tr>
      <tr>
        <td />
        <td data-testid="table1-row2-value2">{configTable.configTable1.data[2].value2}</td>
      </tr>
      <tr>
        <td />
        <td data-testid="table1-row3-value2">{configTable.configTable1.data[3].value2}</td>
      </tr>
      <tr>
        <td />
        <td data-testid="table1-row4-value2">{configTable.configTable1.data[4].value2}</td>
      </tr>
    </table>

    {/* row 2 */}
    <div>
      <p>機械</p>
      {configTable?.configTable2 && (
        <SheetTable dataCfg={configTable?.configTable2} options={OPTIONS_TABLE2} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table2-row0-value1">{configTable.configTable2.data[0].value1}</td>
        <td data-testid="table2-row0-value2">{configTable.configTable2.data[0].value2}</td>
      </tr>
      <tr>
        <td data-testid="table2-row1-value1">{configTable.configTable2.data[1].value1}</td>
        <td data-testid="table2-row1-value2">{configTable.configTable2.data[1].value2}</td>
      </tr>
      <tr>
        <td data-testid="table2-row2-value1">{configTable.configTable2.data[2].value1}</td>
        <td data-testid="table2-row2-value2">{configTable.configTable2.data[2].value2}</td>
      </tr>
      <tr>
        <td data-testid="table2-row3-value1">{configTable.configTable2.data[3].value1}</td>
        <td data-testid="table2-row3-value2">{configTable.configTable2.data[3].value2}</td>
      </tr>
      <tr>
        <td data-testid="table2-row4-value1">{configTable.configTable2.data[4].value1}</td>
        <td data-testid="table2-row4-value2">{configTable.configTable2.data[4].value2}</td>
      </tr>
      <tr>
        <td data-testid="table2-row5-value1">{configTable.configTable2.data[5].value1}</td>
        <td data-testid="table2-row5-value2">{configTable.configTable2.data[5].value2}</td>
      </tr>
      <tr>
        <td data-testid="table2-row6-value1">{configTable.configTable2.data[6].value1}</td>
        <td data-testid="table2-row6-value2">{configTable.configTable2.data[6].value2}</td>
      </tr>
      <tr>
        <td />
        <td data-testid="table2-row7-value2">{configTable.configTable2.data[7].value2}</td>
      </tr>
    </table>

    {/* row 3 */}
    <div>
      <p>モニタリング装置</p>
      {configTable?.configTable3 && (
        <SheetTable dataCfg={configTable?.configTable3} options={OPTIONS_TABLE3} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table3-row0-value1">{configTable.configTable3.data[0].value1}</td>
        <td data-testid="table3-row0-value2">{configTable.configTable3.data[0].value2}</td>
      </tr>
      <tr>
        <td data-testid="table3-row1-value1">{configTable.configTable3.data[1].value1}</td>
        <td data-testid="table3-row1-value2">{configTable.configTable3.data[1].value2}</td>
      </tr>
      <tr>
        <td />
        <td data-testid="table3-row2-value2">{configTable.configTable3.data[2].value2}</td>
      </tr>
    </table>

    {/* row 4 */}
    <div>
      <p>環境制御装置</p>
      {configTable?.configTable4 && (
        <SheetTable dataCfg={configTable?.configTable4} options={OPTIONS_TABLE4} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table4-row0-value1">{configTable.configTable4.data[0].value1}</td>
        <td data-testid="table4-row0-value2">{configTable.configTable4.data[0].value2}</td>
      </tr>
      <tr>
        <td />
        <td data-testid="table4-row1-value2">{configTable.configTable4.data[1].value2}</td>
      </tr>
    </table>

    {/* row 5 */}
    <div>
      <p>他大型機械</p>
      {configTable?.configTable5 && (
        <SheetTable dataCfg={configTable?.configTable5} options={OPTIONS_TABLE5} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td />
        <td data-testid="table5-row0-value2">{configTable.configTable5.data[0].value2}</td>
      </tr>
      <tr>
        <td />
        <td data-testid="table5-row1-value2">{configTable.configTable5.data[1].value2}</td>
      </tr>
      <tr>
        <td />
        <td data-testid="table5-row2-value2">{configTable.configTable5.data[2].value2}</td>
      </tr>
      <tr>
        <td />
        <td data-testid="table5-row3-value2">{configTable.configTable5.data[3].value2}</td>
      </tr>
    </table>

    {/* row 6 */}
    <div>
      <p>機器の稼働頻度</p>
      {configTable?.configTable6 && (
        <SheetTable dataCfg={configTable?.configTable6} options={OPTIONS_TABLE6} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table6-row0-value1">{configTable.configTable6.data[0].value1}</td>
        <td data-testid="table6-row0-value2">{configTable.configTable6.data[0].value2}</td>
      </tr>
      <tr>
        <td data-testid="table6-row1-value1">{configTable.configTable6.data[1].value1}</td>
        <td data-testid="table6-row1-value2">{configTable.configTable6.data[1].value2}</td>
      </tr>
      <tr>
        <td />
        <td data-testid="table6-row2-value2">{configTable.configTable6.data[2].value2}</td>
      </tr>
    </table>

    {/* row Robot and AI */}

    <div>
      <p>ロボット・AI投資</p>
      {configTable?.configTableRobotAndAI && (
        <SheetTable dataCfg={configTable?.configTableRobotAndAI} options={{ height: 150 }} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td />
        <td data-testid="table-robot-and-ai-row0-value2">{configTable.configTableRobotAndAI.data[0].value1}</td>
      </tr>
      <tr>
        <td />
        <td data-testid="table-robot-and-ai-row1-value2">{configTable.configTableRobotAndAI.data[1].value1}</td>
      </tr>
      <tr>
        <td />
        <td data-testid="table-robot-and-ai-row2-value2">{configTable.configTableRobotAndAI.data[2].value1}</td>
      </tr>
    </table>

    {/* row 7 */}
    <div>
      <p>初期投資合計、減価償却費</p>
      {configTable?.configTable7 && (
        <SheetTable dataCfg={configTable?.configTable7} options={OPTIONS_TABLE7} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table7-row0-value1">{configTable.configTable7.data[0].value1}</td>
      </tr>
      <tr>
        <td data-testid="table7-row1-value1">{configTable.configTable7.data[1].value1}</td>
      </tr>
      <tr>
        <td data-testid="table7-row2-value1">{configTable.configTable7.data[2].value1}</td>
      </tr>
    </table>

    {/* row 8 */}
    <div className={styles.mt_20}>
      {configTable?.dataChart1 && <ColumnChart configChart={configTable?.dataChart1} />}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="datachart1-row0-value">{configTable.dataChart1.data[0].value}</td>
      </tr>
      <tr>
        <td data-testid="datachart1-row1-value">{configTable.dataChart1.data[1].value}</td>
      </tr>
      <tr>
        <td data-testid="datachart1-row2-value">{configTable.dataChart1.data[2].value}</td>
      </tr>
      <tr>
        <td data-testid="datachart1-row3-value">{configTable.dataChart1.data[3].value}</td>
      </tr>
      <tr>
        <td data-testid="datachart1-row4-value">{configTable.dataChart1.data[4].value}</td>
      </tr>
      <tr>
        <td data-testid="datachart1-row5-value">{configTable.dataChart1.data[5].value}</td>
      </tr>
    </table>

    {/* row 9 */}
    <div>
      <p>シミュレーションの結果、収量が確定しました。</p>
      {configTable?.configTable8 && (
        <SheetTable dataCfg={configTable?.configTable8} options={OPTIONS_TABLE8} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table8-row0-value1">{configTable.configTable8.data[0].value1}</td>
      </tr>
      <tr>
        <td data-testid="table8-row1-value1">{configTable.configTable8.data[1].value1}</td>
      </tr>
      <tr>
        <td data-testid="table8-row2-value1">{configTable.configTable8.data[2].value1}</td>
      </tr>
      <tr>
        <td data-testid="table8-row3-value1">{configTable.configTable8.data[3].value1}</td>
      </tr>
    </table>

    {/* row 10 */}
    <div>{configTable?.dataPieChart && <PieChart configChart={configTable?.dataPieChart} />}</div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="datapiechart-row0-value">{configTable.dataPieChart.data[0].value}</td>
      </tr>
      <tr>
        <td data-testid="datapiechart-row1-value">{configTable.dataPieChart.data[1].value}</td>
      </tr>
    </table>

    <div>{configTable?.dataChart2 && <ColumnChart configChart={configTable?.dataChart2} />}</div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="datachart2-row0-value">{configTable.dataChart2.data[0].value}</td>
        <td data-testid="datachart2-row1-value">{configTable.dataChart2.data[1].value}</td>
      </tr>
      <tr>
        <td data-testid="datachart2-row2-value">{configTable.dataChart2.data[2].value}</td>
        <td data-testid="datachart2-row3-value">{configTable.dataChart2.data[3].value}</td>
      </tr>
      <tr>
        <td data-testid="datachart2-row4-value">{configTable.dataChart2.data[4].value}</td>
        <td data-testid="datachart2-row5-value">{configTable.dataChart2.data[5].value}</td>
      </tr>
      <tr>
        <td data-testid="datachart2-row6-value">{configTable.dataChart2.data[6].value}</td>
        <td data-testid="datachart2-row7-value">{configTable.dataChart2.data[7].value}</td>
      </tr>
      <tr>
        <td data-testid="datachart2-row8-value">{configTable.dataChart2.data[8].value}</td>
        <td data-testid="datachart2-row9-value">{configTable.dataChart2.data[9].value}</td>
      </tr>
      <tr>
        <td data-testid="datachart2-row10-value">{configTable.dataChart2.data[10].value}</td>
        <td data-testid="datachart2-row11-value">{configTable.dataChart2.data[11].value}</td>
      </tr>
      <tr>
        <td data-testid="datachart2-row12-value">{configTable.dataChart2.data[12].value}</td>
        <td data-testid="datachart2-row13-value">{configTable.dataChart2.data[13].value}</td>
      </tr>
      <tr>
        <td data-testid="datachart2-row14-value">{configTable.dataChart2.data[14].value}</td>
        <td data-testid="datachart2-row15-value">{configTable.dataChart2.data[15].value}</td>
      </tr>
      <tr>
        <td data-testid="datachart2-row16-value">{configTable.dataChart2.data[16].value}</td>
        <td data-testid="datachart2-row17-value">{configTable.dataChart2.data[17].value}</td>
      </tr>
      <tr>
        <td data-testid="datachart2-row18-value">{configTable.dataChart2.data[18].value}</td>
        <td data-testid="datachart2-row19-value">{configTable.dataChart2.data[19].value}</td>
      </tr>
      <tr>
        <td data-testid="datachart2-row20-value">{configTable.dataChart2.data[20].value}</td>
        <td data-testid="datachart2-row21-value">{configTable.dataChart2.data[21].value}</td>
      </tr>
      <tr>
        <td data-testid="datachart2-row22-value">{configTable.dataChart2.data[22].value}</td>
        <td data-testid="datachart2-row23-value">{configTable.dataChart2.data[23].value}</td>
      </tr>
    </table>
  </div>
);

export default AssignmentQuestionForm;
