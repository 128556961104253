/* eslint-disable complexity */
import React from 'react';
import './style.css';
import ColumnChart from 'components/ColumnChart/ColumnChart';
import { getNumberOnly } from 'libs/helpers/functions';
import SheetTable from '../../../components/SheetTable/SheetTable';
import styles from './style.module.scss';
import { THEME_TABLE } from './AssignmentCalculationSettingsYields.state';

const AssignmentCalculationYieldsForm: React.FC<{ configTable: any }> = ({ configTable }) => (
  <div>
    <div>
      <p>シミュレーションの結果栽培に必要な労働時間が確定しました！</p>
      {configTable?.configTable1 && (
        <SheetTable dataCfg={configTable?.configTable1} options={configTable?.optionTable1} themeCfg={THEME_TABLE} />
      )}
      {getNumberOnly(configTable?.dataTable1[0].value1) > getNumberOnly(configTable?.dataTable1[1].value1) ? (
        <p className={styles.descTable}>
          栽培作業者（パート等）の労働時間が不足しています。雇用計画を見直してください。
        </p>
      ) : (
        ''
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table1-row0-value1">{configTable.dataTable1[0].value1}</td>
      </tr>
      <tr>
        <td data-testid="table1-row1-value1">{configTable.dataTable1[1].value1}</td>
      </tr>
    </table>

    {/* row 2 */}
    <div>
      <p className={styles.titleTable}>栽培に必要な月別工数（時間）</p>
      <p>工数（時間）</p>
      {configTable?.dataChart && <ColumnChart configChart={configTable?.dataChart} />}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="chart-row0-value1">{configTable.dataChart.data[0].value1}</td>
        <td data-testid="chart-row1-value1">{configTable.dataChart.data[1].value1}</td>
        <td data-testid="chart-row2-value1">{configTable.dataChart.data[2].value1}</td>
        <td data-testid="chart-row3-value1">{configTable.dataChart.data[3].value1}</td>
        <td data-testid="chart-row4-value1">{configTable.dataChart.data[4].value1}</td>
        <td data-testid="chart-row5-value1">{configTable.dataChart.data[5].value1}</td>
        <td data-testid="chart-row6-value1">{configTable.dataChart.data[6].value1}</td>
        <td data-testid="chart-row7-value1">{configTable.dataChart.data[7].value1}</td>
        <td data-testid="chart-row8-value1">{configTable.dataChart.data[8].value1}</td>
        <td data-testid="chart-row9-value1">{configTable.dataChart.data[9].value1}</td>
      </tr>
      <tr>
        <td data-testid="chart-row10-value1">{configTable.dataChart.data[10].value1}</td>
        <td data-testid="chart-row11-value1">{configTable.dataChart.data[11].value1}</td>
        <td data-testid="chart-row12-value1">{configTable.dataChart.data[12].value1}</td>
        <td data-testid="chart-row13-value1">{configTable.dataChart.data[13].value1}</td>
        <td data-testid="chart-row14-value1">{configTable.dataChart.data[14].value1}</td>
        <td data-testid="chart-row15-value1">{configTable.dataChart.data[15].value1}</td>
        <td data-testid="chart-row16-value1">{configTable.dataChart.data[16].value1}</td>
        <td data-testid="chart-row17-value1">{configTable.dataChart.data[17].value1}</td>
        <td data-testid="chart-row18-value1">{configTable.dataChart.data[18].value1}</td>
        <td data-testid="chart-row19-value1">{configTable.dataChart.data[19].value1}</td>
      </tr>
      <tr>
        <td data-testid="chart-row20-value1">{configTable.dataChart.data[20].value1}</td>
        <td data-testid="chart-row21-value1">{configTable.dataChart.data[21].value1}</td>
        <td data-testid="chart-row22-value1">{configTable.dataChart.data[22].value1}</td>
        <td data-testid="chart-row23-value1">{configTable.dataChart.data[23].value1}</td>
        <td data-testid="chart-row24-value1">{configTable.dataChart.data[24].value1}</td>
        <td data-testid="chart-row25-value1">{configTable.dataChart.data[25].value1}</td>
        <td data-testid="chart-row26-value1">{configTable.dataChart.data[26].value1}</td>
        <td data-testid="chart-row27-value1">{configTable.dataChart.data[27].value1}</td>
        <td data-testid="chart-row28-value1">{configTable.dataChart.data[28].value1}</td>
        <td data-testid="chart-row29-value1">{configTable.dataChart.data[29].value1}</td>
      </tr>
      <tr>
        <td data-testid="chart-row30-value1">{configTable.dataChart.data[30].value1}</td>
        <td data-testid="chart-row31-value1">{configTable.dataChart.data[31].value1}</td>
        <td data-testid="chart-row32-value1">{configTable.dataChart.data[32].value1}</td>
        <td data-testid="chart-row33-value1">{configTable.dataChart.data[33].value1}</td>
        <td data-testid="chart-row34-value1">{configTable.dataChart.data[34].value1}</td>
        <td data-testid="chart-row35-value1">{configTable.dataChart.data[35].value1}</td>
        <td data-testid="chart-row36-value1">{configTable.dataChart.data[36].value1}</td>
        <td data-testid="chart-row37-value1">{configTable.dataChart.data[37].value1}</td>
        <td data-testid="chart-row38-value1">{configTable.dataChart.data[38].value1}</td>
        <td data-testid="chart-row39-value1">{configTable.dataChart.data[39].value1}</td>
      </tr>
      <tr>
        <td data-testid="chart-row40-value1">{configTable.dataChart.data[40].value1}</td>
        <td data-testid="chart-row41-value1">{configTable.dataChart.data[41].value1}</td>
        <td data-testid="chart-row42-value1">{configTable.dataChart.data[42].value1}</td>
        <td data-testid="chart-row43-value1">{configTable.dataChart.data[43].value1}</td>
        <td data-testid="chart-row44-value1">{configTable.dataChart.data[44].value1}</td>
        <td data-testid="chart-row45-value1">{configTable.dataChart.data[45].value1}</td>
        <td data-testid="chart-row46-value1">{configTable.dataChart.data[46].value1}</td>
        <td data-testid="chart-row47-value1">{configTable.dataChart.data[47].value1}</td>
        <td data-testid="chart-row48-value1">{configTable.dataChart.data[48].value1}</td>
        <td data-testid="chart-row49-value1">{configTable.dataChart.data[49].value1}</td>
      </tr>
      <tr>
        <td data-testid="chart-row50-value1">{configTable.dataChart.data[50].value1}</td>
        <td data-testid="chart-row51-value1">{configTable.dataChart.data[51].value1}</td>
        <td data-testid="chart-row52-value1">{configTable.dataChart.data[52].value1}</td>
        <td data-testid="chart-row53-value1">{configTable.dataChart.data[53].value1}</td>
        <td data-testid="chart-row54-value1">{configTable.dataChart.data[54].value1}</td>
        <td data-testid="chart-row55-value1">{configTable.dataChart.data[55].value1}</td>
        <td data-testid="chart-row56-value1">{configTable.dataChart.data[56].value1}</td>
        <td data-testid="chart-row57-value1">{configTable.dataChart.data[57].value1}</td>
        <td data-testid="chart-row58-value1">{configTable.dataChart.data[58].value1}</td>
        <td data-testid="chart-row59-value1">{configTable.dataChart.data[59].value1}</td>
      </tr>
      <tr>
        <td data-testid="chart-row60-value1">{configTable.dataChart.data[60].value1}</td>
        <td data-testid="chart-row61-value1">{configTable.dataChart.data[61].value1}</td>
        <td data-testid="chart-row62-value1">{configTable.dataChart.data[62].value1}</td>
        <td data-testid="chart-row63-value1">{configTable.dataChart.data[63].value1}</td>
        <td data-testid="chart-row64-value1">{configTable.dataChart.data[64].value1}</td>
        <td data-testid="chart-row65-value1">{configTable.dataChart.data[65].value1}</td>
        <td data-testid="chart-row66-value1">{configTable.dataChart.data[66].value1}</td>
        <td data-testid="chart-row67-value1">{configTable.dataChart.data[67].value1}</td>
        <td data-testid="chart-row68-value1">{configTable.dataChart.data[68].value1}</td>
        <td data-testid="chart-row69-value1">{configTable.dataChart.data[69].value1}</td>
      </tr>
      <tr>
        <td data-testid="chart-row70-value1">{configTable.dataChart.data[70].value1}</td>
        <td data-testid="chart-row71-value1">{configTable.dataChart.data[71].value1}</td>
        <td data-testid="chart-row72-value1">{configTable.dataChart.data[72].value1}</td>
        <td data-testid="chart-row73-value1">{configTable.dataChart.data[73].value1}</td>
        <td data-testid="chart-row74-value1">{configTable.dataChart.data[74].value1}</td>
        <td data-testid="chart-row75-value1">{configTable.dataChart.data[75].value1}</td>
        <td data-testid="chart-row76-value1">{configTable.dataChart.data[76].value1}</td>
        <td data-testid="chart-row77-value1">{configTable.dataChart.data[77].value1}</td>
        <td data-testid="chart-row78-value1">{configTable.dataChart.data[78].value1}</td>
        <td data-testid="chart-row79-value1">{configTable.dataChart.data[79].value1}</td>
      </tr>
      <tr>
        <td data-testid="chart-row80-value1">{configTable.dataChart.data[80].value1}</td>
        <td data-testid="chart-row81-value1">{configTable.dataChart.data[81].value1}</td>
        <td data-testid="chart-row82-value1">{configTable.dataChart.data[82].value1}</td>
        <td data-testid="chart-row83-value1">{configTable.dataChart.data[83].value1}</td>
        <td data-testid="chart-row84-value1">{configTable.dataChart.data[84].value1}</td>
        <td data-testid="chart-row85-value1">{configTable.dataChart.data[85].value1}</td>
        <td data-testid="chart-row86-value1">{configTable.dataChart.data[86].value1}</td>
        <td data-testid="chart-row87-value1">{configTable.dataChart.data[87].value1}</td>
        <td data-testid="chart-row88-value1">{configTable.dataChart.data[88].value1}</td>
        <td data-testid="chart-row89-value1">{configTable.dataChart.data[89].value1}</td>
      </tr>
      <tr>
        <td data-testid="chart-row90-value1">{configTable.dataChart.data[90].value1}</td>
        <td data-testid="chart-row91-value1">{configTable.dataChart.data[91].value1}</td>
        <td data-testid="chart-row92-value1">{configTable.dataChart.data[92].value1}</td>
        <td data-testid="chart-row93-value1">{configTable.dataChart.data[93].value1}</td>
        <td data-testid="chart-row94-value1">{configTable.dataChart.data[94].value1}</td>
        <td data-testid="chart-row95-value1">{configTable.dataChart.data[95].value1}</td>
        <td data-testid="chart-row96-value1">{configTable.dataChart.data[96].value1}</td>
        <td data-testid="chart-row97-value1">{configTable.dataChart.data[97].value1}</td>
        <td data-testid="chart-row98-value1">{configTable.dataChart.data[98].value1}</td>
        <td data-testid="chart-row99-value1">{configTable.dataChart.data[99].value1}</td>
      </tr>
      <tr>
        <td data-testid="chart-row100-value1">{configTable.dataChart.data[100].value1}</td>
        <td data-testid="chart-row101-value1">{configTable.dataChart.data[101].value1}</td>
        <td data-testid="chart-row102-value1">{configTable.dataChart.data[102].value1}</td>
        <td data-testid="chart-row103-value1">{configTable.dataChart.data[103].value1}</td>
        <td data-testid="chart-row104-value1">{configTable.dataChart.data[104].value1}</td>
        <td data-testid="chart-row105-value1">{configTable.dataChart.data[105].value1}</td>
        <td data-testid="chart-row106-value1">{configTable.dataChart.data[106].value1}</td>
        <td data-testid="chart-row107-value1">{configTable.dataChart.data[107].value1}</td>
        <td data-testid="chart-row108-value1">{configTable.dataChart.data[108].value1}</td>
        <td data-testid="chart-row109-value1">{configTable.dataChart.data[109].value1}</td>
      </tr>
      <tr>
        <td data-testid="chart-row110-value1">{configTable.dataChart.data[110].value1}</td>
        <td data-testid="chart-row111-value1">{configTable.dataChart.data[111].value1}</td>
        <td data-testid="chart-row112-value1">{configTable.dataChart.data[112].value1}</td>
        <td data-testid="chart-row113-value1">{configTable.dataChart.data[113].value1}</td>
        <td data-testid="chart-row114-value1">{configTable.dataChart.data[114].value1}</td>
        <td data-testid="chart-row115-value1">{configTable.dataChart.data[115].value1}</td>
        <td data-testid="chart-row116-value1">{configTable.dataChart.data[116].value1}</td>
        <td data-testid="chart-row117-value1">{configTable.dataChart.data[117].value1}</td>
        <td data-testid="chart-row118-value1">{configTable.dataChart.data[118].value1}</td>
        <td data-testid="chart-row119-value1">{configTable.dataChart.data[119].value1}</td>
      </tr>
    </table>

    {/* row 3 */}
    <div>
      <p className={styles.titleTable}>栽培に必要な月別工数（時間）</p>
      {configTable?.configTable2 && (
        <SheetTable dataCfg={configTable?.configTable2} options={configTable?.optionTable2} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table2-row0-month10">{configTable.configTable2.data[0].month10}</td>
        <td data-testid="table2-row0-month11">{configTable.configTable2.data[0].month11}</td>
        <td data-testid="table2-row0-month12">{configTable.configTable2.data[0].month12}</td>
        <td data-testid="table2-row0-month1">{configTable.configTable2.data[0].month1}</td>
        <td data-testid="table2-row0-month2">{configTable.configTable2.data[0].month2}</td>
        <td data-testid="table2-row0-month3">{configTable.configTable2.data[0].month3}</td>
        <td data-testid="table2-row0-month4">{configTable.configTable2.data[0].month4}</td>
        <td data-testid="table2-row0-month5">{configTable.configTable2.data[0].month5}</td>
        <td data-testid="table2-row0-month6">{configTable.configTable2.data[0].month6}</td>
        <td data-testid="table2-row0-month7">{configTable.configTable2.data[0].month7}</td>
        <td data-testid="table2-row0-month8">{configTable.configTable2.data[0].month8}</td>
        <td data-testid="table2-row0-month9">{configTable.configTable2.data[0].month9}</td>
        <td data-testid="table2-row0-total">{configTable.configTable2.data[0].total}</td>
      </tr>
      <tr>
        <td data-testid="table2-row1-month10">{configTable.configTable2.data[1].month10}</td>
        <td data-testid="table2-row1-month11">{configTable.configTable2.data[1].month11}</td>
        <td data-testid="table2-row1-month12">{configTable.configTable2.data[1].month12}</td>
        <td data-testid="table2-row1-month1">{configTable.configTable2.data[1].month1}</td>
        <td data-testid="table2-row1-month2">{configTable.configTable2.data[1].month2}</td>
        <td data-testid="table2-row1-month3">{configTable.configTable2.data[1].month3}</td>
        <td data-testid="table2-row1-month4">{configTable.configTable2.data[1].month4}</td>
        <td data-testid="table2-row1-month5">{configTable.configTable2.data[1].month5}</td>
        <td data-testid="table2-row1-month6">{configTable.configTable2.data[1].month6}</td>
        <td data-testid="table2-row1-month7">{configTable.configTable2.data[1].month7}</td>
        <td data-testid="table2-row1-month8">{configTable.configTable2.data[1].month8}</td>
        <td data-testid="table2-row1-month9">{configTable.configTable2.data[1].month9}</td>
        <td data-testid="table2-row1-total">{configTable.configTable2.data[1].total}</td>
      </tr>
      <tr>
        <td data-testid="table2-row2-month10">{configTable.configTable2.data[2].month10}</td>
        <td data-testid="table2-row2-month11">{configTable.configTable2.data[2].month11}</td>
        <td data-testid="table2-row2-month12">{configTable.configTable2.data[2].month12}</td>
        <td data-testid="table2-row2-month1">{configTable.configTable2.data[2].month1}</td>
        <td data-testid="table2-row2-month2">{configTable.configTable2.data[2].month2}</td>
        <td data-testid="table2-row2-month3">{configTable.configTable2.data[2].month3}</td>
        <td data-testid="table2-row2-month4">{configTable.configTable2.data[2].month4}</td>
        <td data-testid="table2-row2-month5">{configTable.configTable2.data[2].month5}</td>
        <td data-testid="table2-row2-month6">{configTable.configTable2.data[2].month6}</td>
        <td data-testid="table2-row2-month7">{configTable.configTable2.data[2].month7}</td>
        <td data-testid="table2-row2-month8">{configTable.configTable2.data[2].month8}</td>
        <td data-testid="table2-row2-month9">{configTable.configTable2.data[2].month9}</td>
        <td data-testid="table2-row2-total">{configTable.configTable2.data[2].total}</td>
      </tr>
      <tr>
        <td data-testid="table2-row3-month10">{configTable.configTable2.data[3].month10}</td>
        <td data-testid="table2-row3-month11">{configTable.configTable2.data[3].month11}</td>
        <td data-testid="table2-row3-month12">{configTable.configTable2.data[3].month12}</td>
        <td data-testid="table2-row3-month1">{configTable.configTable2.data[3].month1}</td>
        <td data-testid="table2-row3-month2">{configTable.configTable2.data[3].month2}</td>
        <td data-testid="table2-row3-month3">{configTable.configTable2.data[3].month3}</td>
        <td data-testid="table2-row3-month4">{configTable.configTable2.data[3].month4}</td>
        <td data-testid="table2-row3-month5">{configTable.configTable2.data[3].month5}</td>
        <td data-testid="table2-row3-month6">{configTable.configTable2.data[3].month6}</td>
        <td data-testid="table2-row3-month7">{configTable.configTable2.data[3].month7}</td>
        <td data-testid="table2-row3-month8">{configTable.configTable2.data[3].month8}</td>
        <td data-testid="table2-row3-month9">{configTable.configTable2.data[3].month9}</td>
        <td data-testid="table2-row3-total">{configTable.configTable2.data[3].total}</td>
      </tr>
      <tr>
        <td data-testid="table2-row4-month10">{configTable.configTable2.data[4].month10}</td>
        <td data-testid="table2-row4-month11">{configTable.configTable2.data[4].month11}</td>
        <td data-testid="table2-row4-month12">{configTable.configTable2.data[4].month12}</td>
        <td data-testid="table2-row4-month1">{configTable.configTable2.data[4].month1}</td>
        <td data-testid="table2-row4-month2">{configTable.configTable2.data[4].month2}</td>
        <td data-testid="table2-row4-month3">{configTable.configTable2.data[4].month3}</td>
        <td data-testid="table2-row4-month4">{configTable.configTable2.data[4].month4}</td>
        <td data-testid="table2-row4-month5">{configTable.configTable2.data[4].month5}</td>
        <td data-testid="table2-row4-month6">{configTable.configTable2.data[4].month6}</td>
        <td data-testid="table2-row4-month7">{configTable.configTable2.data[4].month7}</td>
        <td data-testid="table2-row4-month8">{configTable.configTable2.data[4].month8}</td>
        <td data-testid="table2-row4-month9">{configTable.configTable2.data[4].month9}</td>
        <td data-testid="table2-row4-total">{configTable.configTable2.data[4].total}</td>
      </tr>
      <tr>
        <td data-testid="table2-row5-month10">{configTable.configTable2.data[5].month10}</td>
        <td data-testid="table2-row5-month11">{configTable.configTable2.data[5].month11}</td>
        <td data-testid="table2-row5-month12">{configTable.configTable2.data[5].month12}</td>
        <td data-testid="table2-row5-month1">{configTable.configTable2.data[5].month1}</td>
        <td data-testid="table2-row5-month2">{configTable.configTable2.data[5].month2}</td>
        <td data-testid="table2-row5-month3">{configTable.configTable2.data[5].month3}</td>
        <td data-testid="table2-row5-month4">{configTable.configTable2.data[5].month4}</td>
        <td data-testid="table2-row5-month5">{configTable.configTable2.data[5].month5}</td>
        <td data-testid="table2-row5-month6">{configTable.configTable2.data[5].month6}</td>
        <td data-testid="table2-row5-month7">{configTable.configTable2.data[5].month7}</td>
        <td data-testid="table2-row5-month8">{configTable.configTable2.data[5].month8}</td>
        <td data-testid="table2-row5-month9">{configTable.configTable2.data[5].month9}</td>
        <td data-testid="table2-row5-total">{configTable.configTable2.data[5].total}</td>
      </tr>
      <tr>
        <td data-testid="table2-row6-month10">{configTable.configTable2.data[6].month10}</td>
        <td data-testid="table2-row6-month11">{configTable.configTable2.data[6].month11}</td>
        <td data-testid="table2-row6-month12">{configTable.configTable2.data[6].month12}</td>
        <td data-testid="table2-row6-month1">{configTable.configTable2.data[6].month1}</td>
        <td data-testid="table2-row6-month2">{configTable.configTable2.data[6].month2}</td>
        <td data-testid="table2-row6-month3">{configTable.configTable2.data[6].month3}</td>
        <td data-testid="table2-row6-month4">{configTable.configTable2.data[6].month4}</td>
        <td data-testid="table2-row6-month5">{configTable.configTable2.data[6].month5}</td>
        <td data-testid="table2-row6-month6">{configTable.configTable2.data[6].month6}</td>
        <td data-testid="table2-row6-month7">{configTable.configTable2.data[6].month7}</td>
        <td data-testid="table2-row6-month8">{configTable.configTable2.data[6].month8}</td>
        <td data-testid="table2-row6-month9">{configTable.configTable2.data[6].month9}</td>
        <td data-testid="table2-row6-total">{configTable.configTable2.data[6].total}</td>
      </tr>
      <tr>
        <td data-testid="table2-row7-month10">{configTable.configTable2.data[7].month10}</td>
        <td data-testid="table2-row7-month11">{configTable.configTable2.data[7].month11}</td>
        <td data-testid="table2-row7-month12">{configTable.configTable2.data[7].month12}</td>
        <td data-testid="table2-row7-month1">{configTable.configTable2.data[7].month1}</td>
        <td data-testid="table2-row7-month2">{configTable.configTable2.data[7].month2}</td>
        <td data-testid="table2-row7-month3">{configTable.configTable2.data[7].month3}</td>
        <td data-testid="table2-row7-month4">{configTable.configTable2.data[7].month4}</td>
        <td data-testid="table2-row7-month5">{configTable.configTable2.data[7].month5}</td>
        <td data-testid="table2-row7-month6">{configTable.configTable2.data[7].month6}</td>
        <td data-testid="table2-row7-month7">{configTable.configTable2.data[7].month7}</td>
        <td data-testid="table2-row7-month8">{configTable.configTable2.data[7].month8}</td>
        <td data-testid="table2-row7-month9">{configTable.configTable2.data[7].month9}</td>
        <td data-testid="table2-row7-total">{configTable.configTable2.data[7].total}</td>
      </tr>
      <tr>
        <td data-testid="table2-row8-month10">{configTable.configTable2.data[8].month10}</td>
        <td data-testid="table2-row8-month11">{configTable.configTable2.data[8].month11}</td>
        <td data-testid="table2-row8-month12">{configTable.configTable2.data[8].month12}</td>
        <td data-testid="table2-row8-month1">{configTable.configTable2.data[8].month1}</td>
        <td data-testid="table2-row8-month2">{configTable.configTable2.data[8].month2}</td>
        <td data-testid="table2-row8-month3">{configTable.configTable2.data[8].month3}</td>
        <td data-testid="table2-row8-month4">{configTable.configTable2.data[8].month4}</td>
        <td data-testid="table2-row8-month5">{configTable.configTable2.data[8].month5}</td>
        <td data-testid="table2-row8-month6">{configTable.configTable2.data[8].month6}</td>
        <td data-testid="table2-row8-month7">{configTable.configTable2.data[8].month7}</td>
        <td data-testid="table2-row8-month8">{configTable.configTable2.data[8].month8}</td>
        <td data-testid="table2-row8-month9">{configTable.configTable2.data[8].month9}</td>
        <td data-testid="table2-row8-total">{configTable.configTable2.data[8].total}</td>
      </tr>
      <tr>
        <td data-testid="table2-row9-month10">{configTable.configTable2.data[9].month10}</td>
        <td data-testid="table2-row9-month11">{configTable.configTable2.data[9].month11}</td>
        <td data-testid="table2-row9-month12">{configTable.configTable2.data[9].month12}</td>
        <td data-testid="table2-row9-month1">{configTable.configTable2.data[9].month1}</td>
        <td data-testid="table2-row9-month2">{configTable.configTable2.data[9].month2}</td>
        <td data-testid="table2-row9-month3">{configTable.configTable2.data[9].month3}</td>
        <td data-testid="table2-row9-month4">{configTable.configTable2.data[9].month4}</td>
        <td data-testid="table2-row9-month5">{configTable.configTable2.data[9].month5}</td>
        <td data-testid="table2-row9-month6">{configTable.configTable2.data[9].month6}</td>
        <td data-testid="table2-row9-month7">{configTable.configTable2.data[9].month7}</td>
        <td data-testid="table2-row9-month8">{configTable.configTable2.data[9].month8}</td>
        <td data-testid="table2-row9-month9">{configTable.configTable2.data[9].month9}</td>
        <td data-testid="table2-row9-total">{configTable.configTable2.data[9].total}</td>
      </tr>
      <tr>
        <td data-testid="table2-row10-month10">{configTable.configTable2.data[10].month10}</td>
        <td data-testid="table2-row10-month11">{configTable.configTable2.data[10].month11}</td>
        <td data-testid="table2-row10-month12">{configTable.configTable2.data[10].month12}</td>
        <td data-testid="table2-row10-month1">{configTable.configTable2.data[10].month1}</td>
        <td data-testid="table2-row10-month2">{configTable.configTable2.data[10].month2}</td>
        <td data-testid="table2-row10-month3">{configTable.configTable2.data[10].month3}</td>
        <td data-testid="table2-row10-month4">{configTable.configTable2.data[10].month4}</td>
        <td data-testid="table2-row10-month5">{configTable.configTable2.data[10].month5}</td>
        <td data-testid="table2-row10-month6">{configTable.configTable2.data[10].month6}</td>
        <td data-testid="table2-row10-month7">{configTable.configTable2.data[10].month7}</td>
        <td data-testid="table2-row10-month8">{configTable.configTable2.data[10].month8}</td>
        <td data-testid="table2-row10-month9">{configTable.configTable2.data[10].month9}</td>
        <td data-testid="table2-row10-total">{configTable.configTable2.data[10].total}</td>
      </tr>
    </table>

    {/* row 4 */}
    <div>
      <p>人件費（年間）</p>
      {configTable?.configTable3 && (
        <SheetTable dataCfg={configTable?.configTable3} options={configTable?.optionTable3} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table3-row0-value1">{configTable.configTable3.data[0].value1}</td>
        <td data-testid="table3-row0-value2">{configTable.configTable3.data[0].value2}</td>
      </tr>
      <tr>
        <td data-testid="table3-row1-value1">{configTable.configTable3.data[1].value1}</td>
        <td data-testid="table3-row1-value2">{configTable.configTable3.data[1].value2}</td>
      </tr>
      <tr>
        <td data-testid="table3-row2-value1">{configTable.configTable3.data[2].value1}</td>
        <td data-testid="table3-row2-value2">{configTable.configTable3.data[2].value2}</td>
      </tr>
      <tr>
        <td data-testid="table3-row3-value1">{configTable.configTable3.data[3].value1}</td>
        <td data-testid="table3-row3-value2">{configTable.configTable3.data[3].value2}</td>
      </tr>
      <tr>
        <td data-testid="table3-row4-value1">{configTable.configTable3.data[4].value1}</td>
        <td data-testid="table3-row4-value2">{configTable.configTable3.data[4].value2}</td>
      </tr>
      <tr>
        <td data-testid="table3-row5-value1">{configTable.configTable3.data[5].value1}</td>
        <td data-testid="table3-row5-value2">{configTable.configTable3.data[5].value2}</td>
      </tr>
    </table>
  </div>
);

export default AssignmentCalculationYieldsForm;
