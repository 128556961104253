import React from 'react';
import { Col, RadioChangeEvent, Row } from 'antd';
import ChartTitle from 'components/ChartTitle/ChartTitle';
import ColumnChart from 'components/ColumnChart/ColumnChart';
import CommonRadio from 'components/CommonRadio/CommonRadio';
import SheetTable from 'components/SheetTable/SheetTable';
import { RADIO_DATA } from 'pages/Client/AssignmentsResults/Tabs/tab1/assignmentsResults';
import { TTargetYear } from 'libs/calculators';
import styles from '../style.module.scss';

const RowThree: React.FC<{
  configTable: any;
  onChange?: (e: RadioChangeEvent) => void;
  profitAndLossEachYear?: TTargetYear;
}> = ({ configTable, onChange, profitAndLossEachYear }) => (
  <>
    <Row
      style={{ marginTop: '100px' }}
      gutter={[
        { xs: 0, sm: 20 },
        { xs: 20, sm: 0 },
      ]}
    >
      <ChartTitle
        title="各年　PLグラフ"
        subTitle="「５か年　PLグラフ」で判断した問題のある年を下のラジオボタンで選択し、コスト詳細を確認しましょう！
            そしてどこのコストを下げるのか、または売上を向上させるのか決めましょう。​"
      />
      <Col xs={24} sm={12}>
        <CommonRadio radioOptions={RADIO_DATA} onChange={onChange} value={String(profitAndLossEachYear)} />
        <div className={styles.subTitle}>
          <div className="left">{profitAndLossEachYear}年目PL</div>
        </div>
        {configTable?.dataColumnChart2 && <ColumnChart configChart={configTable?.dataColumnChart2} />}
      </Col>
      <Col xs={24} sm={12}>
        {configTable?.dataTableDiagram1 && (
          <SheetTable dataCfg={configTable?.dataTableDiagram1} options={configTable?.OPTIONS_TABLE} />
        )}
      </Col>
    </Row>
    {configTable?.dataColumnChart2 && (
      <table className="for-test" hidden>
        <tr>
          <td data-testid="columnchart2-row0-year">{configTable.dataColumnChart2.data[0].year}</td>
          <td data-testid="columnchart2-row0-type">{configTable.dataColumnChart2.data[0].type}</td>
          <td data-testid="columnchart2-row0-value">{configTable.dataColumnChart2.data[0].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart2-row1-year">{configTable.dataColumnChart2.data[1].year}</td>
          <td data-testid="columnchart2-row1-type">{configTable.dataColumnChart2.data[1].type}</td>
          <td data-testid="columnchart2-row1-value">{configTable.dataColumnChart2.data[1].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart2-row2-year">{configTable.dataColumnChart2.data[2].year}</td>
          <td data-testid="columnchart2-row2-type">{configTable.dataColumnChart2.data[2].type}</td>
          <td data-testid="columnchart2-row2-value">{configTable.dataColumnChart2.data[2].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart2-row3-year">{configTable.dataColumnChart2.data[3].year}</td>
          <td data-testid="columnchart2-row3-type">{configTable.dataColumnChart2.data[3].type}</td>
          <td data-testid="columnchart2-row3-value">{configTable.dataColumnChart2.data[3].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart2-row4-year">{configTable.dataColumnChart2.data[4].year}</td>
          <td data-testid="columnchart2-row4-type">{configTable.dataColumnChart2.data[4].type}</td>
          <td data-testid="columnchart2-row4-value">{configTable.dataColumnChart2.data[4].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart2-row5-year">{configTable.dataColumnChart2.data[5].year}</td>
          <td data-testid="columnchart2-row5-type">{configTable.dataColumnChart2.data[5].type}</td>
          <td data-testid="columnchart2-row5-value">{configTable.dataColumnChart2.data[5].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart2-row6-year">{configTable.dataColumnChart2.data[6].year}</td>
          <td data-testid="columnchart2-row6-type">{configTable.dataColumnChart2.data[6].type}</td>
          <td data-testid="columnchart2-row6-value">{configTable.dataColumnChart2.data[6].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart2-row7-year">{configTable.dataColumnChart2.data[7].year}</td>
          <td data-testid="columnchart2-row7-type">{configTable.dataColumnChart2.data[7].type}</td>
          <td data-testid="columnchart2-row7-value">{configTable.dataColumnChart2.data[7].value}</td>
        </tr>
        <tr>
          <td data-testid="columnchart2-row8-year">{configTable.dataColumnChart2.data[8].year}</td>
          <td data-testid="columnchart2-row8-type">{configTable.dataColumnChart2.data[8].type}</td>
          <td data-testid="columnchart2-row8-value">{configTable.dataColumnChart2.data[8].value}</td>
        </tr>
      </table>
    )}
    {configTable?.dataTableDiagram1 && (
      <table className="for-test" hidden>
        <tr>
          <td data-testid="tablediagram1-row0-pl">{configTable.dataTableDiagram1.data[0].PL}</td>
          <td data-testid="tablediagram1-row0-price">{configTable.dataTableDiagram1.data[0].price}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram1-row1-pl">{configTable.dataTableDiagram1.data[1].PL}</td>
          <td data-testid="tablediagram1-row1-price">{configTable.dataTableDiagram1.data[1].price}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram1-row2-pl">{configTable.dataTableDiagram1.data[2].PL}</td>
          <td data-testid="tablediagram1-row2-price">{configTable.dataTableDiagram1.data[2].price}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram1-row3-pl">{configTable.dataTableDiagram1.data[3].PL}</td>
          <td data-testid="tablediagram1-row3-price">{configTable.dataTableDiagram1.data[3].price}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram1-row4-pl">{configTable.dataTableDiagram1.data[4].PL}</td>
          <td data-testid="tablediagram1-row4-price">{configTable.dataTableDiagram1.data[4].price}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram1-row5-pl">{configTable.dataTableDiagram1.data[5].PL}</td>
          <td data-testid="tablediagram1-row5-price">{configTable.dataTableDiagram1.data[5].price}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram1-row6-pl">{configTable.dataTableDiagram1.data[6].PL}</td>
          <td data-testid="tablediagram1-row6-price">{configTable.dataTableDiagram1.data[6].price}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram1-row7-pl">{configTable.dataTableDiagram1.data[7].PL}</td>
          <td data-testid="tablediagram1-row7-price">{configTable.dataTableDiagram1.data[7].price}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram1-row8-pl">{configTable.dataTableDiagram1.data[8].PL}</td>
          <td data-testid="tablediagram1-row8-price">{configTable.dataTableDiagram1.data[8].price}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram1-row9-pl">{configTable.dataTableDiagram1.data[9].PL}</td>
          <td data-testid="tablediagram1-row9-price">{configTable.dataTableDiagram1.data[9].price}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram1-row10-pl">{configTable.dataTableDiagram1.data[10].PL}</td>
          <td data-testid="tablediagram1-row10-value">{configTable.dataTableDiagram1.data[10].value}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram1-row11-pl">{configTable.dataTableDiagram1.data[11].PL}</td>
          <td data-testid="tablediagram1-row11-price">{configTable.dataTableDiagram1.data[11].price}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram1-row12-pl">{configTable.dataTableDiagram1.data[12].PL}</td>
          <td data-testid="tablediagram1-row12-price">{configTable.dataTableDiagram1.data[12].price}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram1-row13-pl">{configTable.dataTableDiagram1.data[13].PL}</td>
          <td data-testid="tablediagram1-row13-price">{configTable.dataTableDiagram1.data[13].price}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram1-row14-pl">{configTable.dataTableDiagram1.data[14].PL}</td>
          <td data-testid="tablediagram1-row14-price">{configTable.dataTableDiagram1.data[14].price}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram1-row15-pl">{configTable.dataTableDiagram1.data[15].PL}</td>
          <td data-testid="tablediagram1-row15-price">{configTable.dataTableDiagram1.data[15].price}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram1-row16-pl">{configTable.dataTableDiagram1.data[16].PL}</td>
          <td data-testid="tablediagram1-row16-price">{configTable.dataTableDiagram1.data[16].price}</td>
        </tr>
      </table>
    )}
  </>
);
export default RowThree;
