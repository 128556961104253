/* eslint-disable complexity */
import { ThemeName, generatePalette, getPalette } from '@antv/s2';
import { TTemplate } from '../../../types/template';
import { TTrainingResult } from '../../../types/trainingResult';
import { Calculator } from '../../../libs/calculators';
import { addCommas, numberWithCommas, removeCommas } from '../../../libs/helpers/functions';

const months = ['10', '11', '12', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export const THEME_TABLE = {
  name: 'colorful' as ThemeName,
  palette: generatePalette({
    ...getPalette('colorful'),
    brandColor: '#1D81BA',
  }),
};

export const OPTIONS_TABLE = {
  height: 200,
};

export const OPTIONS_TABLE4 = {
  height: 170,
};

export const OPTIONS_TABLE3 = {
  height: 80,
  conditions: {
    background: [
      {
        field: 'col1',
        mapping() {
          return {
            fill: '#1D81BA',
          };
        },
      },
    ],
    text: [
      {
        field: 'col1',
        mapping() {
          return {
            fill: '#fff',
          };
        },
      },
    ],
  },
};

export const OPTIONS_TABLE7 = {
  height: 200,
  style: {
    colCfg: {
      widthByFieldValue: {
        col0: 80,
        col1: 125,
        col2: 125,
        col3: 125,
        col4: 125,
        col5: 125,
      },
    },
  },
};

const getOptions = (type?: string) =>
  months.map((month) => ({
    field: month,
    mapping(field: string | number) {
      if (Number(removeCommas(field)) < 0) {
        return type === 'text' ? { fill: '#ffffff' } : { fill: '#FF4D4F' };
      }
      return {
        fill: type === 'text' ? '#000000' : '',
      };
    },
  }));

export const OPTIONS_TABLE2 = {
  height: 250,
  conditions: {
    background: getOptions(),
    text: getOptions('text'),
  },
  style: {
    colCfg: {
      widthByFieldValue: {
        1: 125,
        2: 125,
        3: 125,
        4: 125,
        5: 125,
        6: 125,
        7: 125,
        8: 125,
        9: 125,
        10: 125,
        11: 125,
        12: 125,
      },
    },
  },
};

const TTargetYear1 = 1;

export const configTable1 = (template: TTemplate, trainingResult: TTrainingResult) => {
  const cal = new Calculator(template, trainingResult);
  cal.M3();

  const configTable2 = {
    fields: {
      rows: ['title'],
      values: months,
    },
    meta: [
      {
        field: 'title',
        name: '資金繰計画表',
      },
      {
        field: '10',
        name: '10月',
      },
      {
        field: '11',
        name: '11月',
      },
      {
        field: '12',
        name: '12月',
      },
      {
        field: '1',
        name: '1月',
      },
      {
        field: '2',
        name: '2月',
      },
      {
        field: '3',
        name: '3月',
      },
      {
        field: '4',
        name: '4月',
      },
      {
        field: '5',
        name: '5月',
      },
      {
        field: '6',
        name: '6月',
      },
      {
        field: '7',
        name: '7月',
      },
      {
        field: '8',
        name: '8月',
      },
      {
        field: '9',
        name: '9月',
      },
    ],
    data: [
      {
        title: '1年目残額',
        10: numberWithCommas(cal.M301(1, 1)),
        11: numberWithCommas(cal.M301(2, 1)),
        12: numberWithCommas(cal.M301(3, 1)),
        1: numberWithCommas(cal.M301(4, 1)),
        2: numberWithCommas(cal.M301(5, 1)),
        3: numberWithCommas(cal.M301(6, 1)),
        4: numberWithCommas(cal.M301(7, 1)),
        5: numberWithCommas(cal.M301(8, 1)),
        6: numberWithCommas(cal.M301(9, 1)),
        7: numberWithCommas(cal.M301(10, 1)),
        8: numberWithCommas(cal.M301(11, 1)),
        9: numberWithCommas(cal.M301(12, 1)),
      },
      {
        title: '2年目残額',
        10: numberWithCommas(cal.M301(1, 2)),
        11: numberWithCommas(cal.M301(2, 2)),
        12: numberWithCommas(cal.M301(3, 2)),
        1: numberWithCommas(cal.M301(4, 2)),
        2: numberWithCommas(cal.M301(5, 2)),
        3: numberWithCommas(cal.M301(6, 2)),
        4: numberWithCommas(cal.M301(7, 2)),
        5: numberWithCommas(cal.M301(8, 2)),
        6: numberWithCommas(cal.M301(9, 2)),
        7: numberWithCommas(cal.M301(10, 2)),
        8: numberWithCommas(cal.M301(11, 2)),
        9: numberWithCommas(cal.M301(12, 2)),
      },
      {
        title: '3年目残額',
        10: numberWithCommas(cal.M301(1, 3)),
        11: numberWithCommas(cal.M301(2, 3)),
        12: numberWithCommas(cal.M301(3, 3)),
        1: numberWithCommas(cal.M301(4, 3)),
        2: numberWithCommas(cal.M301(5, 3)),
        3: numberWithCommas(cal.M301(6, 3)),
        4: numberWithCommas(cal.M301(7, 3)),
        5: numberWithCommas(cal.M301(8, 3)),
        6: numberWithCommas(cal.M301(9, 3)),
        7: numberWithCommas(cal.M301(10, 3)),
        8: numberWithCommas(cal.M301(11, 3)),
        9: numberWithCommas(cal.M301(12, 3)),
      },
      {
        title: '4年目残額',
        10: numberWithCommas(cal.M301(1, 4)),
        11: numberWithCommas(cal.M301(2, 4)),
        12: numberWithCommas(cal.M301(3, 4)),
        1: numberWithCommas(cal.M301(4, 4)),
        2: numberWithCommas(cal.M301(5, 4)),
        3: numberWithCommas(cal.M301(6, 4)),
        4: numberWithCommas(cal.M301(7, 4)),
        5: numberWithCommas(cal.M301(8, 4)),
        6: numberWithCommas(cal.M301(9, 4)),
        7: numberWithCommas(cal.M301(10, 4)),
        8: numberWithCommas(cal.M301(11, 4)),
        9: numberWithCommas(cal.M301(12, 4)),
      },
      {
        title: '5年目残額',
        10: numberWithCommas(cal.M301(1, 5)),
        11: numberWithCommas(cal.M301(2, 5)),
        12: numberWithCommas(cal.M301(3, 5)),
        1: numberWithCommas(cal.M301(4, 5)),
        2: numberWithCommas(cal.M301(5, 5)),
        3: numberWithCommas(cal.M301(6, 5)),
        4: numberWithCommas(cal.M301(7, 5)),
        5: numberWithCommas(cal.M301(8, 5)),
        6: numberWithCommas(cal.M301(9, 5)),
        7: numberWithCommas(cal.M301(10, 5)),
        8: numberWithCommas(cal.M301(11, 5)),
        9: numberWithCommas(cal.M301(12, 5)),
      },
    ],
  };

  const configTable4 = {
    fields: {
      rows: ['title'],
      values: ['value'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value',
        name: '金額',
      },
    ],
    data: [
      {
        title: '資本金',
        value: numberWithCommas(cal.Q0701001()),
      },
      {
        title: '補助金合計',
        value: numberWithCommas(cal.Q0701004() * 3),
      },
      {
        title: '銀行借入額合計',
        value: numberWithCommas(
          Number(cal.Q0701007() + cal.Q0701008() + cal.Q0701009() + cal.Q0701010() + cal.Q0701011()),
        ),
      },
      {
        title: 'その他借入額合計',
        value: numberWithCommas(cal.Q0701003() + cal.Q0701002()),
      },
    ],
  };
  const configTable3 = {
    fields: {
      columns: ['col', 'col1'],
    },
    meta: [
      {
        field: 'col',
        name: '初期投資費用合計',
      },
    ],
    data: [
      {
        col: '初期投資費用合計',
        col1: numberWithCommas(cal.Y201(TTargetYear1)),
      },
    ],
  };
  const configTable5 = {
    fields: {
      columns: ['title', 'value'],
    },
    data: [
      {
        title: '返済期間',
        value: addCommas(cal.Q0701005(), '年', 0),
      },
      {
        title: '利率（年）',
        value: addCommas(cal.Q0701006(), '%', 1),
      },
    ],
  };
  const configTable7 = {
    fields: {
      rows: ['row1'],
      values: ['col0', 'col1', 'col2', 'col3', 'col4', 'col5'],
    },
    meta: [
      {
        field: 'row1',
        name: ' 回数',
      },
      {
        field: 'col0',
        name: ' 返済年月',
      },
      {
        field: 'col1',
        name: '返済額',
      },
      {
        field: 'col2',
        name: 'うち元金',
      },
      {
        field: 'col3',
        name: 'うち利息',
      },
      {
        field: 'col4',
        name: '借入金残高（期初）',
      },
      {
        field: 'col5',
        name: '借入金残高（期末)',
      },
    ],
    data: [
      {
        row1: '1年目',
        col0: '1年目期末',
        col1: numberWithCommas(cal.Y1300()),
        col2: numberWithCommas(cal.Y1301()),
        col3: numberWithCommas(cal.Y1302()),
        col4: numberWithCommas(cal.Y1303()),
        col5: numberWithCommas(cal.Y1304()),
      },
      {
        row1: '2年目',
        col0: '2年目期末',
        col1: numberWithCommas(cal.Y1310()),
        col2: numberWithCommas(cal.Y1311()),
        col3: numberWithCommas(cal.Y1312()),
        col4: numberWithCommas(cal.Y1313()),
        col5: numberWithCommas(cal.Y1314()),
      },
      {
        row1: '3年目',
        col0: '3年目期末',
        col1: numberWithCommas(cal.Y1320()),
        col2: numberWithCommas(cal.Y1321()),
        col3: numberWithCommas(cal.Y1322()),
        col4: numberWithCommas(cal.Y1323()),
        col5: numberWithCommas(cal.Y1324()),
      },
      {
        row1: '4年目',
        col0: '4年目期末',
        col1: numberWithCommas(cal.Y1330()),
        col2: numberWithCommas(cal.Y1331()),
        col3: numberWithCommas(cal.Y1332()),
        col4: numberWithCommas(cal.Y1333()),
        col5: numberWithCommas(cal.Y1334()),
      },
      {
        row1: '5年目',
        col0: '5年目期末',
        col1: numberWithCommas(cal.Y1340()),
        col2: numberWithCommas(cal.Y1341()),
        col3: numberWithCommas(cal.Y1342()),
        col4: numberWithCommas(cal.Y1343()),
        col5: numberWithCommas(cal.Y1344()),
      },
    ],
  };
  const configTable6 = {
    fields: {
      rows: ['title'],
      values: ['value'],
    },
    meta: [
      {
        field: 'title',
        name: '年',
      },
      {
        field: 'value',
        name: '金額',
      },
    ],
    data: [
      {
        title: '1年目',
        value: numberWithCommas(cal.Q0701008()),
      },
      {
        title: '2年目',
        value: numberWithCommas(cal.Q0701009()),
      },
      {
        title: '3年目',
        value: numberWithCommas(cal.Q0701010()),
      },
      {
        title: '4年目',
        value: numberWithCommas(cal.Q0701011()),
      },
      {
        title: '5年目',
        value: numberWithCommas(cal.Q0701012()),
      },
    ],
  };
  return {
    configTable2,
    configTable3,
    configTable4,
    configTable5,
    configTable6,
    configTable7,
  };
};
