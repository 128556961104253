import React from 'react';
import AchievementGoal from 'components/AchievementGoal/achievementGoal';
import { Col, Row } from 'antd';
import ChartTitle from 'components/ChartTitle/ChartTitle';
import SheetTable from 'components/SheetTable/SheetTable';
import ColumnChart from 'components/ColumnChart/ColumnChart';
import { OPTIONS_TABLE, TConfigTab3Fn } from './assignmentsResultsTab3';
import '@antv/s2-react/dist/style.min.css';
import styles from '../style.module.scss';

const Tab3: React.FC<{ configTable: TConfigTab3Fn }> = ({ configTable }) => (
  <>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="configtable-row0-10">{configTable.CONFIG_TABLE_DATA[0]['10']}</td>
        <td data-testid="configtable-row0-11">{configTable.CONFIG_TABLE_DATA[0]['11']}</td>
        <td data-testid="configtable-row0-12">{configTable.CONFIG_TABLE_DATA[0]['12']}</td>
        <td data-testid="configtable-row0-1">{configTable.CONFIG_TABLE_DATA[0]['1']}</td>
        <td data-testid="configtable-row0-2">{configTable.CONFIG_TABLE_DATA[0]['2']}</td>
        <td data-testid="configtable-row0-3">{configTable.CONFIG_TABLE_DATA[0]['3']}</td>
        <td data-testid="configtable-row0-4">{configTable.CONFIG_TABLE_DATA[0]['4']}</td>
        <td data-testid="configtable-row0-5">{configTable.CONFIG_TABLE_DATA[0]['5']}</td>
        <td data-testid="configtable-row0-6">{configTable.CONFIG_TABLE_DATA[0]['6']}</td>
        <td data-testid="configtable-row0-7">{configTable.CONFIG_TABLE_DATA[0]['7']}</td>
        <td data-testid="configtable-row0-8">{configTable.CONFIG_TABLE_DATA[0]['8']}</td>
        <td data-testid="configtable-row0-9">{configTable.CONFIG_TABLE_DATA[0]['9']}</td>
      </tr>
      <tr>
        <td data-testid="configtable-row1-10">{configTable.CONFIG_TABLE_DATA[1]['10']}</td>
        <td data-testid="configtable-row1-11">{configTable.CONFIG_TABLE_DATA[1]['11']}</td>
        <td data-testid="configtable-row1-12">{configTable.CONFIG_TABLE_DATA[1]['12']}</td>
        <td data-testid="configtable-row1-1">{configTable.CONFIG_TABLE_DATA[1]['1']}</td>
        <td data-testid="configtable-row1-2">{configTable.CONFIG_TABLE_DATA[1]['2']}</td>
        <td data-testid="configtable-row1-3">{configTable.CONFIG_TABLE_DATA[1]['3']}</td>
        <td data-testid="configtable-row1-4">{configTable.CONFIG_TABLE_DATA[1]['4']}</td>
        <td data-testid="configtable-row1-5">{configTable.CONFIG_TABLE_DATA[1]['5']}</td>
        <td data-testid="configtable-row1-6">{configTable.CONFIG_TABLE_DATA[1]['6']}</td>
        <td data-testid="configtable-row1-7">{configTable.CONFIG_TABLE_DATA[1]['7']}</td>
        <td data-testid="configtable-row1-8">{configTable.CONFIG_TABLE_DATA[1]['8']}</td>
        <td data-testid="configtable-row1-9">{configTable.CONFIG_TABLE_DATA[1]['9']}</td>
      </tr>
      <tr>
        <td data-testid="configtable-row2-10">{configTable.CONFIG_TABLE_DATA[2]['10']}</td>
        <td data-testid="configtable-row2-11">{configTable.CONFIG_TABLE_DATA[2]['11']}</td>
        <td data-testid="configtable-row2-12">{configTable.CONFIG_TABLE_DATA[2]['12']}</td>
        <td data-testid="configtable-row2-1">{configTable.CONFIG_TABLE_DATA[2]['1']}</td>
        <td data-testid="configtable-row2-2">{configTable.CONFIG_TABLE_DATA[2]['2']}</td>
        <td data-testid="configtable-row2-3">{configTable.CONFIG_TABLE_DATA[2]['3']}</td>
        <td data-testid="configtable-row2-4">{configTable.CONFIG_TABLE_DATA[2]['4']}</td>
        <td data-testid="configtable-row2-5">{configTable.CONFIG_TABLE_DATA[2]['5']}</td>
        <td data-testid="configtable-row2-6">{configTable.CONFIG_TABLE_DATA[2]['6']}</td>
        <td data-testid="configtable-row2-7">{configTable.CONFIG_TABLE_DATA[2]['7']}</td>
        <td data-testid="configtable-row2-8">{configTable.CONFIG_TABLE_DATA[2]['8']}</td>
        <td data-testid="configtable-row2-9">{configTable.CONFIG_TABLE_DATA[2]['9']}</td>
      </tr>
      <tr>
        <td data-testid="configtable-row3-10">{configTable.CONFIG_TABLE_DATA[3]['10']}</td>
        <td data-testid="configtable-row3-11">{configTable.CONFIG_TABLE_DATA[3]['11']}</td>
        <td data-testid="configtable-row3-12">{configTable.CONFIG_TABLE_DATA[3]['12']}</td>
        <td data-testid="configtable-row3-1">{configTable.CONFIG_TABLE_DATA[3]['1']}</td>
        <td data-testid="configtable-row3-2">{configTable.CONFIG_TABLE_DATA[3]['2']}</td>
        <td data-testid="configtable-row3-3">{configTable.CONFIG_TABLE_DATA[3]['3']}</td>
        <td data-testid="configtable-row3-4">{configTable.CONFIG_TABLE_DATA[3]['4']}</td>
        <td data-testid="configtable-row3-5">{configTable.CONFIG_TABLE_DATA[3]['5']}</td>
        <td data-testid="configtable-row3-6">{configTable.CONFIG_TABLE_DATA[3]['6']}</td>
        <td data-testid="configtable-row3-7">{configTable.CONFIG_TABLE_DATA[3]['7']}</td>
        <td data-testid="configtable-row3-8">{configTable.CONFIG_TABLE_DATA[3]['8']}</td>
        <td data-testid="configtable-row3-9">{configTable.CONFIG_TABLE_DATA[3]['9']}</td>
      </tr>
      <tr>
        <td data-testid="configtable-row4-10">{configTable.CONFIG_TABLE_DATA[4]['10']}</td>
        <td data-testid="configtable-row4-11">{configTable.CONFIG_TABLE_DATA[4]['11']}</td>
        <td data-testid="configtable-row4-12">{configTable.CONFIG_TABLE_DATA[4]['12']}</td>
        <td data-testid="configtable-row4-1">{configTable.CONFIG_TABLE_DATA[4]['1']}</td>
        <td data-testid="configtable-row4-2">{configTable.CONFIG_TABLE_DATA[4]['2']}</td>
        <td data-testid="configtable-row4-3">{configTable.CONFIG_TABLE_DATA[4]['3']}</td>
        <td data-testid="configtable-row4-4">{configTable.CONFIG_TABLE_DATA[4]['4']}</td>
        <td data-testid="configtable-row4-5">{configTable.CONFIG_TABLE_DATA[4]['5']}</td>
        <td data-testid="configtable-row4-6">{configTable.CONFIG_TABLE_DATA[4]['6']}</td>
        <td data-testid="configtable-row4-7">{configTable.CONFIG_TABLE_DATA[4]['7']}</td>
        <td data-testid="configtable-row4-8">{configTable.CONFIG_TABLE_DATA[4]['8']}</td>
        <td data-testid="configtable-row4-9">{configTable.CONFIG_TABLE_DATA[4]['9']}</td>
      </tr>
    </table>

    <table className="for-test" hidden>
      <tr>
        <td data-testid="columnchartslider-row0-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[0].月}</td>
        <td data-testid="columnchartslider-row0-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[0].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row1-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[1].月}</td>
        <td data-testid="columnchartslider-row1-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[1].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row2-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[2].月}</td>
        <td data-testid="columnchartslider-row2-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[2].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row3-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[3].月}</td>
        <td data-testid="columnchartslider-row3-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[3].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row4-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[4].月}</td>
        <td data-testid="columnchartslider-row4-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[4].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row5-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[5].月}</td>
        <td data-testid="columnchartslider-row5-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[5].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row6-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[6].月}</td>
        <td data-testid="columnchartslider-row6-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[6].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row7-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[7].月}</td>
        <td data-testid="columnchartslider-row7-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[7].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row8-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[8].月}</td>
        <td data-testid="columnchartslider-row8-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[8].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row9-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[9].月}</td>
        <td data-testid="columnchartslider-row9-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[9].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row10-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[10].月}</td>
        <td data-testid="columnchartslider-row10-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[10].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row11-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[11].月}</td>
        <td data-testid="columnchartslider-row11-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[11].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row12-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[12].月}</td>
        <td data-testid="columnchartslider-row12-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[12].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row13-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[13].月}</td>
        <td data-testid="columnchartslider-row13-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[13].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row14-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[14].月}</td>
        <td data-testid="columnchartslider-row14-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[14].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row15-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[15].月}</td>
        <td data-testid="columnchartslider-row15-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[15].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row16-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[16].月}</td>
        <td data-testid="columnchartslider-row16-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[16].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row17-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[17].月}</td>
        <td data-testid="columnchartslider-row17-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[17].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row18-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[18].月}</td>
        <td data-testid="columnchartslider-row18-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[18].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row19-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[19].月}</td>
        <td data-testid="columnchartslider-row19-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[19].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row20-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[20].月}</td>
        <td data-testid="columnchartslider-row20-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[20].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row21-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[21].月}</td>
        <td data-testid="columnchartslider-row21-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[21].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row22-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[22].月}</td>
        <td data-testid="columnchartslider-row22-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[22].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row23-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[23].月}</td>
        <td data-testid="columnchartslider-row23-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[23].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row24-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[24].月}</td>
        <td data-testid="columnchartslider-row24-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[24].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row25-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[25].月}</td>
        <td data-testid="columnchartslider-row25-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[25].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row26-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[26].月}</td>
        <td data-testid="columnchartslider-row26-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[26].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row27-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[27].月}</td>
        <td data-testid="columnchartslider-row27-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[27].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row28-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[28].月}</td>
        <td data-testid="columnchartslider-row28-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[28].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row29-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[29].月}</td>
        <td data-testid="columnchartslider-row29-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[29].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row30-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[30].月}</td>
        <td data-testid="columnchartslider-row30-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[30].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row31-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[31].月}</td>
        <td data-testid="columnchartslider-row31-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[31].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row32-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[32].月}</td>
        <td data-testid="columnchartslider-row32-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[32].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row33-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[33].月}</td>
        <td data-testid="columnchartslider-row33-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[33].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row34-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[34].月}</td>
        <td data-testid="columnchartslider-row34-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[34].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row35-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[35].月}</td>
        <td data-testid="columnchartslider-row35-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[35].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row36-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[36].月}</td>
        <td data-testid="columnchartslider-row36-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[36].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row37-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[37].月}</td>
        <td data-testid="columnchartslider-row37-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[37].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row38-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[38].月}</td>
        <td data-testid="columnchartslider-row38-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[38].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row39-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[39].月}</td>
        <td data-testid="columnchartslider-row39-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[39].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row40-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[40].月}</td>
        <td data-testid="columnchartslider-row40-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[40].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row41-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[41].月}</td>
        <td data-testid="columnchartslider-row41-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[41].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row42-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[42].月}</td>
        <td data-testid="columnchartslider-row42-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[42].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row43-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[43].月}</td>
        <td data-testid="columnchartslider-row43-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[43].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row44-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[44].月}</td>
        <td data-testid="columnchartslider-row44-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[44].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row45-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[45].月}</td>
        <td data-testid="columnchartslider-row45-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[45].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row46-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[46].月}</td>
        <td data-testid="columnchartslider-row46-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[46].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row47-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[47].月}</td>
        <td data-testid="columnchartslider-row47-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[47].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row48-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[48].月}</td>
        <td data-testid="columnchartslider-row48-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[48].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row49-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[49].月}</td>
        <td data-testid="columnchartslider-row49-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[49].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row50-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[50].月}</td>
        <td data-testid="columnchartslider-row50-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[50].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row51-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[51].月}</td>
        <td data-testid="columnchartslider-row51-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[51].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row52-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[52].月}</td>
        <td data-testid="columnchartslider-row52-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[52].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row53-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[53].月}</td>
        <td data-testid="columnchartslider-row53-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[53].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row54-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[54].月}</td>
        <td data-testid="columnchartslider-row54-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[54].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row55-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[55].月}</td>
        <td data-testid="columnchartslider-row55-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[55].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row56-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[56].月}</td>
        <td data-testid="columnchartslider-row56-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[56].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row57-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[57].月}</td>
        <td data-testid="columnchartslider-row57-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[57].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row58-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[58].月}</td>
        <td data-testid="columnchartslider-row58-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[58].現金残高}
        </td>
      </tr>
      <tr>
        <td data-testid="columnchartslider-row59-month">{configTable.DATA_COLUMN_CHART_SLIDER_DATA[59].月}</td>
        <td data-testid="columnchartslider-row59-cashbalance">
          {configTable.DATA_COLUMN_CHART_SLIDER_DATA[59].現金残高}
        </td>
      </tr>
    </table>

    {/* Row1 */}
    <Row
      gutter={[
        { xs: 0, sm: 20 },
        { xs: 20, sm: 0 },
      ]}
    >
      <ChartTitle title="サマリー" />
      <Col xs={24} sm={18}>
        <div>
          <div className={styles.heightText}>
            <p>現金残高の推移です。</p>
            <p>
              下図の資金繰り計画表で赤色の月に現金が足らなくなり倒産します。赤色表示がある場合は、設定を見直しましょう。
            </p>
            <p>対応として以下のことを考えましょう。</p>
          </div>
          <div className={styles.heightText}>
            <p>・銀行からの借り入れを増やす。ただし利息があるため営業外費用が増加します。</p>
            <p>・費用を抑えるため、初期投資内容、人件費を見直す。</p>
            <p>・売上をあげるため、販売価格等を見直す。</p>
          </div>
        </div>
        <div className={styles.subTitle}>
          <div className="left">資金繰計画表</div>
        </div>
        <div style={{ height: '250px' }}>
          {configTable.CONFIG_TABLE && <SheetTable dataCfg={configTable.CONFIG_TABLE} options={OPTIONS_TABLE} />}
        </div>
      </Col>
      <Col xs={24} sm={6}>
        <AchievementGoal />
      </Col>
      <Col span={24}>
        <div className={styles.subTitle}>
          <div className="left">現金残高 (円）</div>
        </div>
        <ColumnChart configChart={configTable.DATA_COLUMN_CHART_SLIDER} />
      </Col>
    </Row>
  </>
);

export default Tab3;
