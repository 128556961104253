import React from 'react';
import { Col, Row } from 'antd';
import ChartTitle from 'components/ChartTitle/ChartTitle';
import ColumnChart from 'components/ColumnChart/ColumnChart';
import SheetTable from 'components/SheetTable/SheetTable';
import AchievementGoal from 'components/AchievementGoal/achievementGoal';
import styles from '../style.module.scss';

const FirstRow: React.FC<{ configTable: any }> = ({ configTable }) => (
  <>
    <Row
      gutter={[
        { xs: 0, sm: 20 },
        { xs: 20, sm: 0 },
      ]}
    >
      <ChartTitle title="販売計画表" />
      <Col xs={24} sm={18}>
        <div className={`${styles.subTitle} ${styles.borderSubTitleB}`}>
          <div className="left">価格と販路の一覧</div>
        </div>
        <div style={{ height: '200px' }}>
          {configTable?.configTableTab4 && (
            <SheetTable dataCfg={configTable?.configTableTab4} options={configTable?.OPTIONS_TABLE_TAB4} />
          )}
        </div>
        <div className={`${styles.subTitle} ${styles.borderSubTitleB}`}>
          <div className="left">年別売上推移グラフ</div>
        </div>
        <Col xs={24} sm={24}>
          <div style={{ height: '350px' }}>
            {configTable?.dataColumnChartTab4 && <ColumnChart configChart={configTable?.dataColumnChartTab4} />}
          </div>
        </Col>
      </Col>
      <Col xs={24} sm={6}>
        <AchievementGoal />
      </Col>
    </Row>
    {configTable?.configTableTab4 && (
      <table className="for-test" hidden>
        <tr>
          <td data-testid="optionstabletab4-row0--row">{configTable?.configTableTab4.data[0].row}</td>
          <td data-testid="optionstabletab4-row0-col1">{configTable?.configTableTab4.data[0].col1}</td>
          <td data-testid="optionstabletab4-row0-col2">{configTable?.configTableTab4.data[0].col2}</td>
        </tr>
        <tr>
          <td data-testid="optionstabletab4-row1--row">{configTable?.configTableTab4.data[1].row}</td>
          <td data-testid="optionstabletab4-row1-col1">{configTable?.configTableTab4.data[1].col1}</td>
          <td data-testid="optionstabletab4-row1-col2">{configTable?.configTableTab4.data[1].col2}</td>
        </tr>
        <tr>
          <td data-testid="optionstabletab4-row2--row">{configTable?.configTableTab4.data[2].row}</td>
          <td data-testid="optionstabletab4-row2-col1">{configTable?.configTableTab4.data[2].col1}</td>
          <td data-testid="optionstabletab4-row2-col2">{configTable?.configTableTab4.data[2].col2}</td>
        </tr>
        <tr>
          <td data-testid="optionstabletab4-row3--row">{configTable?.configTableTab4.data[3].row}</td>
          <td data-testid="optionstabletab4-row3-col1">{configTable?.configTableTab4.data[3].col1}</td>
          <td data-testid="optionstabletab4-row3-col2">{configTable?.configTableTab4.data[3].col2}</td>
        </tr>
      </table>
    )}
    {configTable?.dataColumnChartTab4 && (
      <table className="for-test" hidden>
        <tr>
          <td data-testid="columncharttab4-row0-month">{configTable?.dataColumnChartTab4.data[0].month}</td>
          <td data-testid="columncharttab4-row0-value">{configTable?.dataColumnChartTab4.data[0].value}</td>
          <td data-testid="columncharttab4-row0--type">{configTable?.dataColumnChartTab4.data[0].type}</td>
        </tr>
        <tr>
          <td data-testid="columncharttab4-row1-month">{configTable?.dataColumnChartTab4.data[1].month}</td>
          <td data-testid="columncharttab4-row1-value">{configTable?.dataColumnChartTab4.data[1].value}</td>
          <td data-testid="columncharttab4-row1--type">{configTable?.dataColumnChartTab4.data[1].type}</td>
        </tr>
        <tr>
          <td data-testid="columncharttab4-row2-month">{configTable?.dataColumnChartTab4.data[2].month}</td>
          <td data-testid="columncharttab4-row2-value">{configTable?.dataColumnChartTab4.data[2].value}</td>
          <td data-testid="columncharttab4-row2--type">{configTable?.dataColumnChartTab4.data[2].type}</td>
        </tr>
        <tr>
          <td data-testid="columncharttab4-row3-month">{configTable?.dataColumnChartTab4.data[3].month}</td>
          <td data-testid="columncharttab4-row3-value">{configTable?.dataColumnChartTab4.data[3].value}</td>
          <td data-testid="columncharttab4-row3--type">{configTable?.dataColumnChartTab4.data[3].type}</td>
        </tr>
        <tr>
          <td data-testid="columncharttab4-row4-month">{configTable?.dataColumnChartTab4.data[4].month}</td>
          <td data-testid="columncharttab4-row4-value">{configTable?.dataColumnChartTab4.data[4].value}</td>
          <td data-testid="columncharttab4-row4--type">{configTable?.dataColumnChartTab4.data[4].type}</td>
        </tr>
        <tr>
          <td data-testid="columncharttab4-row5-month">{configTable?.dataColumnChartTab4.data[5].month}</td>
          <td data-testid="columncharttab4-row5-value">{configTable?.dataColumnChartTab4.data[5].value}</td>
          <td data-testid="columncharttab4-row5--type">{configTable?.dataColumnChartTab4.data[5].type}</td>
        </tr>
        <tr>
          <td data-testid="columncharttab4-row6-month">{configTable?.dataColumnChartTab4.data[6].month}</td>
          <td data-testid="columncharttab4-row6-value">{configTable?.dataColumnChartTab4.data[6].value}</td>
          <td data-testid="columncharttab4-row6--type">{configTable?.dataColumnChartTab4.data[6].type}</td>
        </tr>
        <tr>
          <td data-testid="columncharttab4-row7-month">{configTable?.dataColumnChartTab4.data[7].month}</td>
          <td data-testid="columncharttab4-row7-value">{configTable?.dataColumnChartTab4.data[7].value}</td>
          <td data-testid="columncharttab4-row7--type">{configTable?.dataColumnChartTab4.data[7].type}</td>
        </tr>
        <tr>
          <td data-testid="columncharttab4-row8-month">{configTable?.dataColumnChartTab4.data[8].month}</td>
          <td data-testid="columncharttab4-row8-value">{configTable?.dataColumnChartTab4.data[8].value}</td>
          <td data-testid="columncharttab4-row8--type">{configTable?.dataColumnChartTab4.data[8].type}</td>
        </tr>
        <tr>
          <td data-testid="columncharttab4-row9-month">{configTable?.dataColumnChartTab4.data[9].month}</td>
          <td data-testid="columncharttab4-row9-value">{configTable?.dataColumnChartTab4.data[9].value}</td>
          <td data-testid="columncharttab4-row9--type">{configTable?.dataColumnChartTab4.data[9].type}</td>
        </tr>
      </table>
    )}
  </>
);

export default FirstRow;
