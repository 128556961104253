import '@antv/s2-react/dist/style.min.css';
import { Col, Row } from 'antd';
import AchievementGoal from 'components/AchievementGoal/achievementGoal';
import ChartTitle from 'components/ChartTitle/ChartTitle';
import ColumnChart from 'components/ColumnChart/ColumnChart';
import SheetTable from 'components/SheetTable/SheetTable';
import {
  OPTIONS_TABLE_INVEST,
  OPTIONS_TABLE_VALUE,
  TConfigTab2Fn,
} from 'pages/Client/AssignmentsResults/Tabs/Tab2/assignmentResult2';
import React from 'react';

const Tab2: React.FC<{ configTable: TConfigTab2Fn }> = ({ configTable }) => (
  <>
    {/* Row1 */}
    <Row
      gutter={[
        { xs: 0, sm: 20 },
        { xs: 20, sm: 0 },
      ]}
    >
      <ChartTitle title="サマリー" />
      <Col xs={24} sm={18}>
        <SheetTable dataCfg={configTable.CONFIG_TABLE_VALUE} options={OPTIONS_TABLE_VALUE} />
      </Col>
      <Col xs={24} sm={6}>
        <AchievementGoal />
      </Col>
    </Row>

    <table className="for-test" hidden>
      <tr>
        <td data-testid="configtablevalue-row0-col1">{configTable.CONFIG_TABLE_VALUE_DATA[0].col1}</td>
        <td data-testid="configtablevalue-row0-col2">{configTable.CONFIG_TABLE_VALUE_DATA[0].col2}</td>
        <td data-testid="configtablevalue-row0-col3">{configTable.CONFIG_TABLE_VALUE_DATA[0].col3}</td>
        <td data-testid="configtablevalue-row0-col4">{configTable.CONFIG_TABLE_VALUE_DATA[0].col4}</td>
        <td data-testid="configtablevalue-row0-col5">{configTable.CONFIG_TABLE_VALUE_DATA[0].col5}</td>
        <td data-testid="configtablevalue-row0-col6">{configTable.CONFIG_TABLE_VALUE_DATA[0].col6}</td>
        <td data-testid="configtablevalue-row0-col7">{configTable.CONFIG_TABLE_VALUE_DATA[0].col7}</td>
      </tr>
    </table>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="configtablevalue-row1-col1">{configTable.CONFIG_TABLE_VALUE_DATA[1].col1}</td>
        <td data-testid="configtablevalue-row1-col2">{configTable.CONFIG_TABLE_VALUE_DATA[1].col2}</td>
        <td data-testid="configtablevalue-row1-col3">{configTable.CONFIG_TABLE_VALUE_DATA[1].col3}</td>
        <td data-testid="configtablevalue-row1-col4">{configTable.CONFIG_TABLE_VALUE_DATA[1].col4}</td>
        <td data-testid="configtablevalue-row1-col5">{configTable.CONFIG_TABLE_VALUE_DATA[1].col5}</td>
        <td data-testid="configtablevalue-row1-col6">{configTable.CONFIG_TABLE_VALUE_DATA[1].col6}</td>
        <td data-testid="configtablevalue-row1-col7">{configTable.CONFIG_TABLE_VALUE_DATA[1].col7}</td>
      </tr>
      <tr>
        <td data-testid="configtablevalue-row2-col1">{configTable.CONFIG_TABLE_VALUE_DATA[2].col1}</td>
        <td data-testid="configtablevalue-row2-col2">{configTable.CONFIG_TABLE_VALUE_DATA[2].col2}</td>
        <td data-testid="configtablevalue-row2-col3">{configTable.CONFIG_TABLE_VALUE_DATA[2].col3}</td>
        <td data-testid="configtablevalue-row2-col4">{configTable.CONFIG_TABLE_VALUE_DATA[2].col4}</td>
        <td data-testid="configtablevalue-row2-col5">{configTable.CONFIG_TABLE_VALUE_DATA[2].col5}</td>
        <td data-testid="configtablevalue-row2-col6">{configTable.CONFIG_TABLE_VALUE_DATA[2].col6}</td>
        <td data-testid="configtablevalue-row2-col7">{configTable.CONFIG_TABLE_VALUE_DATA[2].col7}</td>
      </tr>
      <tr>
        <td data-testid="configtablevalue-row3-col1">{configTable.CONFIG_TABLE_VALUE_DATA[3].col1}</td>
        <td data-testid="configtablevalue-row3-col2">{configTable.CONFIG_TABLE_VALUE_DATA[3].col2}</td>
        <td data-testid="configtablevalue-row3-col3">{configTable.CONFIG_TABLE_VALUE_DATA[3].col3}</td>
        <td data-testid="configtablevalue-row3-col4">{configTable.CONFIG_TABLE_VALUE_DATA[3].col4}</td>
        <td data-testid="configtablevalue-row3-col5">{configTable.CONFIG_TABLE_VALUE_DATA[3].col5}</td>
        <td data-testid="configtablevalue-row3-col6">{configTable.CONFIG_TABLE_VALUE_DATA[3].col6}</td>
        <td data-testid="configtablevalue-row3-col7">{configTable.CONFIG_TABLE_VALUE_DATA[3].col7}</td>
      </tr>
      <tr>
        <td data-testid="configtablevalue-row4-col1">{configTable.CONFIG_TABLE_VALUE_DATA[4].col1}</td>
        <td data-testid="configtablevalue-row4-col2">{configTable.CONFIG_TABLE_VALUE_DATA[4].col2}</td>
        <td data-testid="configtablevalue-row4-col3">{configTable.CONFIG_TABLE_VALUE_DATA[4].col3}</td>
        <td data-testid="configtablevalue-row4-col4">{configTable.CONFIG_TABLE_VALUE_DATA[4].col4}</td>
        <td data-testid="configtablevalue-row4-col5">{configTable.CONFIG_TABLE_VALUE_DATA[4].col5}</td>
        <td data-testid="configtablevalue-row4-col6">{configTable.CONFIG_TABLE_VALUE_DATA[4].col6}</td>
        <td data-testid="configtablevalue-row4-col7">{configTable.CONFIG_TABLE_VALUE_DATA[4].col7}</td>
      </tr>
    </table>

    {/* Row2 */}
    <Row
      gutter={[
        { xs: 0, sm: 30 },
        { xs: 20, sm: 0 },
      ]}
    >
      <ChartTitle
        title="１年目投資内容"
        subTitle="年ごとの黒字化や営業利益率、累計利益率に関する達成目標が未達の場合、下のグラフから問題となる年を判断しましょう。 ​"
      />
      <Col xs={24} sm={12}>
        <div>
          <p>投資額グラフ</p>
          <ColumnChart configChart={configTable.DATA_COLUMN_CHART_TAB2} />
        </div>
      </Col>
      <Col xs={24} sm={12}>
        <div style={{ height: '400px' }}>
          <p>投資内容</p>
          <SheetTable dataCfg={configTable.CONFIG_TABLE_INVEST} options={OPTIONS_TABLE_INVEST} />
        </div>
      </Col>
    </Row>

    <table className="for-test" hidden>
      <tr>
        <td data-testid="datacolumncharttab2-row0-type">{configTable.DATA_COLUMN_CHART_TAB2_DATA[0].type}</td>
        <td data-testid="datacolumncharttab2-row0-value">{configTable.DATA_COLUMN_CHART_TAB2_DATA[0].value}</td>
      </tr>
      <tr>
        <td data-testid="datacolumncharttab2-row1-type">{configTable.DATA_COLUMN_CHART_TAB2_DATA[1].type}</td>
        <td data-testid="datacolumncharttab2-row1-value">{configTable.DATA_COLUMN_CHART_TAB2_DATA[1].value}</td>
      </tr>
      <tr>
        <td data-testid="datacolumncharttab2-row2-type">{configTable.DATA_COLUMN_CHART_TAB2_DATA[2].type}</td>
        <td data-testid="datacolumncharttab2-row2-value">{configTable.DATA_COLUMN_CHART_TAB2_DATA[2].value}</td>
      </tr>
      <tr>
        <td data-testid="datacolumncharttab2-row3-type">{configTable.DATA_COLUMN_CHART_TAB2_DATA[3].type}</td>
        <td data-testid="datacolumncharttab2-row3-value">{configTable.DATA_COLUMN_CHART_TAB2_DATA[3].value}</td>
      </tr>
      <tr>
        <td data-testid="datacolumncharttab2-row4-type">{configTable.DATA_COLUMN_CHART_TAB2_DATA[4].type}</td>
        <td data-testid="datacolumncharttab2-row4-value">{configTable.DATA_COLUMN_CHART_TAB2_DATA[4].value}</td>
      </tr>
      <tr>
        <td data-testid="datacolumncharttab2-row5-type">{configTable.DATA_COLUMN_CHART_TAB2_DATA[5].type}</td>
        <td data-testid="datacolumncharttab2-row5-value">{configTable.DATA_COLUMN_CHART_TAB2_DATA[5].value}</td>
      </tr>
      <tr>
        <td data-testid="datacolumncharttab2-row6-type">{configTable.DATA_COLUMN_CHART_TAB2_DATA[6].type}</td>
        <td data-testid="datacolumncharttab2-row6-value">{configTable.DATA_COLUMN_CHART_TAB2_DATA[6].value}</td>
      </tr>
    </table>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="configtableinvest-row0-label">{configTable.CONFIG_TABLE_INVEST_DATA[0].投資対象}</td>
        <td data-testid="configtableinvest-row0-value">{configTable.CONFIG_TABLE_INVEST_DATA[0].投資額}</td>
      </tr>
      <tr>
        <td data-testid="configtableinvest-row1-label">{configTable.CONFIG_TABLE_INVEST_DATA[1].投資対象}</td>
        <td data-testid="configtableinvest-row1-value">{configTable.CONFIG_TABLE_INVEST_DATA[1].投資額}</td>
      </tr>
      <tr>
        <td data-testid="configtableinvest-row2-label">{configTable.CONFIG_TABLE_INVEST_DATA[2].投資対象}</td>
        <td data-testid="configtableinvest-row2-value">{configTable.CONFIG_TABLE_INVEST_DATA[2].投資額}</td>
      </tr>
      <tr>
        <td data-testid="configtableinvest-row3-label">{configTable.CONFIG_TABLE_INVEST_DATA[3].投資対象}</td>
        <td data-testid="configtableinvest-row3-value">{configTable.CONFIG_TABLE_INVEST_DATA[3].投資額}</td>
      </tr>
      <tr>
        <td data-testid="configtableinvest-row4-label">{configTable.CONFIG_TABLE_INVEST_DATA[4].投資対象}</td>
        <td data-testid="configtableinvest-row4-value">{configTable.CONFIG_TABLE_INVEST_DATA[4].投資額}</td>
      </tr>
      <tr>
        <td data-testid="configtableinvest-row5-label">{configTable.CONFIG_TABLE_INVEST_DATA[5].投資対象}</td>
        <td data-testid="configtableinvest-row5-value">{configTable.CONFIG_TABLE_INVEST_DATA[5].投資額}</td>
      </tr>
      <tr>
        <td data-testid="configtableinvest-row6-label">{configTable.CONFIG_TABLE_INVEST_DATA[6].投資対象}</td>
        <td data-testid="configtableinvest-row6-value">{configTable.CONFIG_TABLE_INVEST_DATA[6].投資額}</td>
      </tr>
    </table>
  </>
);

export default Tab2;
