/* eslint-disable complexity */
import { IndexedObject } from 'constants/types';
import { Calculator, ETrainingStep } from 'libs/calculators';
import { numberWithCommas, getValueOrDefault, removeCommas, addCommas } from 'libs/helpers/functions';
import { each, groupBy } from 'lodash';
import { TTemplate } from 'types/template';
import { TTrainingResult } from 'types/trainingResult';

export const configTableFnTab4 = (template: TTemplate, trainingResult: TTrainingResult) => {
  const cal = new Calculator(template, trainingResult);

  const combineCalFn = (questionId: string, productivity: number) =>
    getValueOrDefault(productivity * Number(cal.getValueOfQuestionId(questionId, ETrainingStep.step5)) * 1000);

  const configTableTab4 = {
    fields: {
      rows: ['row'],
      values: ['col1', 'col2'],
    },
    meta: [
      {
        field: 'row',
        name: '価格と販路',
      },
      {
        field: 'col1',
        name: 'A品',
      },
      {
        field: 'col2',
        name: 'その他等級',
      },
    ],
    data: [
      {
        row: '価格[円/kg]',
        col1: numberWithCommas(cal.getValueOfQuestionId('Q0501002', ETrainingStep.step5)),
        col2: numberWithCommas(cal.getValueOfQuestionId('Q0501003', ETrainingStep.step5)),
      },
      {
        row: '販路',
        col1: cal.getLabelQuestionByValue(
          cal.getValueOfQuestionId('Q0501001', 'step5'),
          'Q0501001',
          ETrainingStep.step3,
        ),
        col2: cal.getLabelQuestionByValue(
          cal.getValueOfQuestionId('Q0501001', 'step5'),
          'Q0501001',
          ETrainingStep.step3,
        ),
      },
      {
        row: '販売促進活動',
        col1: cal.getLabelQuestionByValue(
          cal.getValueOfQuestionId('Q0502001', 'step5'),
          'Q0502001',
          ETrainingStep.step3,
        ),
        col2: cal.getLabelQuestionByValue(
          cal.getValueOfQuestionId('Q0502001', 'step5'),
          'Q0502001',
          ETrainingStep.step3,
        ),
      },
      {
        row: '販売促進規模',
        col1: cal.getLabelQuestionByValue(
          cal.getValueOfQuestionId('Q0502002', 'step5'),
          'Q0502002',
          ETrainingStep.step3,
        ),
        col2: cal.getLabelQuestionByValue(
          cal.getValueOfQuestionId('Q0502002', 'step5'),
          'Q0502002',
          ETrainingStep.step3,
        ),
      },
    ],
  };

  const configTableTab4Two = {
    fields: {
      rows: ['row1'],
      values: ['val1', 'val2', 'val3'],
    },
    meta: [
      {
        field: 'row1',
        name: '売上推移',
      },
      {
        field: 'val1',
        name: 'A品',
      },
      {
        field: 'val2',
        name: 'その他等級',
      },
      {
        field: 'val3',
        name: '合計',
      },
    ],
    data: [
      {
        row1: '1年目',
        val1: numberWithCommas(combineCalFn('Q0501002', cal.Y413(1))),
        val2: numberWithCommas(combineCalFn('Q0501003', cal.Y414(1))),
        val3: numberWithCommas(combineCalFn('Q0501002', cal.Y413(1)) + combineCalFn('Q0501003', cal.Y414(1))),
      },
      {
        row1: '2年目',
        val1: numberWithCommas(combineCalFn('Q0501002', cal.Y413(2))),
        val2: numberWithCommas(combineCalFn('Q0501003', cal.Y414(2))),
        val3: numberWithCommas(combineCalFn('Q0501002', cal.Y413(2)) + combineCalFn('Q0501003', cal.Y414(2))),
      },
      {
        row1: '3年目',
        val1: numberWithCommas(combineCalFn('Q0501002', cal.Y413(3))),
        val2: numberWithCommas(combineCalFn('Q0501003', cal.Y414(3))),
        val3: numberWithCommas(combineCalFn('Q0501002', cal.Y413(3)) + combineCalFn('Q0501003', cal.Y414(3))),
      },
      {
        row1: '4年目',
        val1: numberWithCommas(combineCalFn('Q0501002', cal.Y413(4))),
        val2: numberWithCommas(combineCalFn('Q0501003', cal.Y414(4))),
        val3: numberWithCommas(combineCalFn('Q0501002', cal.Y413(4)) + combineCalFn('Q0501003', cal.Y414(4))),
      },
      {
        row1: '5年目',
        val1: numberWithCommas(combineCalFn('Q0501002', cal.Y413(5))),
        val2: numberWithCommas(combineCalFn('Q0501003', cal.Y414(5))),
        val3: numberWithCommas(combineCalFn('Q0501002', cal.Y413(5)) + combineCalFn('Q0501003', cal.Y414(5))),
      },
    ],
  };

  const configTableTab4Three = {
    fields: {
      rows: ['row1'],
      values: ['val1', 'val2', 'val3'],
    },
    meta: [
      {
        field: 'row1',
        name: '収量推移',
      },
      {
        field: 'val1',
        name: 'A品',
      },
      {
        field: 'val2',
        name: 'その他等級',
      },
      {
        field: 'val3',
        name: '合計',
      },
    ],
    data: [
      {
        row1: '1年目',
        val1: addCommas(cal.Y413(1), 't', 2),
        val2: addCommas(cal.Y414(1), 't', 2),
        val3: addCommas(cal.Y413(1) + cal.Y414(1), 't', 2),
      },
      {
        row1: '2年目',
        val1: addCommas(cal.Y413(2), 't', 2),
        val2: addCommas(cal.Y414(2), 't', 2),
        val3: addCommas(cal.Y413(2) + cal.Y414(2), 't', 2),
      },
      {
        row1: '3年目',
        val1: addCommas(cal.Y413(3), 't', 2),
        val2: addCommas(cal.Y414(3), 't', 2),
        val3: addCommas(cal.Y413(3) + cal.Y414(3), 't', 2),
      },
      {
        row1: '4年目',
        val1: addCommas(cal.Y413(4), 't', 2),
        val2: addCommas(cal.Y414(4), 't', 2),
        val3: addCommas(cal.Y413(4) + cal.Y414(4), 't', 2),
      },
      {
        row1: '5年目',
        val1: addCommas(cal.Y413(5), 't', 2),
        val2: addCommas(cal.Y414(5), 't', 2),
        val3: addCommas(cal.Y413(5) + cal.Y414(5), 't', 2),
      },
    ],
  };

  const DATA_CHART_1 = [
    {
      month: '1年目',
      value: combineCalFn('Q0501002', cal.Y413(1)),
      type: 'A品',
    },
    {
      month: '1年目',
      value: combineCalFn('Q0501003', cal.Y414(1)),
      type: 'その他等級',
    },
    {
      month: '2年目',
      value: combineCalFn('Q0501002', cal.Y413(2)),
      type: 'A品',
    },
    {
      month: '2年目',
      value: combineCalFn('Q0501003', cal.Y414(2)),
      type: 'その他等級',
    },

    {
      month: '3年目',
      value: combineCalFn('Q0501002', cal.Y413(3)),
      type: 'A品',
    },
    {
      month: '3年目',
      value: combineCalFn('Q0501003', cal.Y414(3)),
      type: 'その他等級',
    },
    {
      month: '4年目',
      value: combineCalFn('Q0501002', cal.Y413(4)),
      type: 'A品',
    },
    {
      month: '4年目',
      value: combineCalFn('Q0501003', cal.Y414(4)),
      type: 'その他等級',
    },
    {
      month: '5年目',
      value: combineCalFn('Q0501002', cal.Y413(5)),
      type: 'A品',
    },
    {
      month: '5年目',
      value: combineCalFn('Q0501003', cal.Y414(5)),
      type: 'その他等級',
    },
  ];

  type Anotation = {
    type: string;
    position: [string, number];
    content: string;
    style: {};
    offsetY: number;
  };

  const getAnotation1 = () => {
    const annotations = [] as Anotation[];
    const group = groupBy(DATA_CHART_1, 'month');
    each(group, (values, k) => {
      const value = values.reduce((a, b) => a + b.value, 0);
      annotations.push({
        type: 'text',
        position: [k, value],
        content: `${numberWithCommas(value)}`,
        style: {
          textAlign: 'center',
          fontSize: 14,
          fill: 'rgba(0,0,0,0.85)',
        },
        offsetY: -5,
      });
    });
    return annotations;
  };

  const dataColumnChartTab4 = {
    data: DATA_CHART_1,
    xField: 'month',
    yField: 'value',
    isStack: true,
    seriesField: 'type',
    annotations: getAnotation1(),
    yAxis: {
      label: {
        formatter: (datum: string) => numberWithCommas(Number(datum)),
      },
    },
    tooltip: {
      formatter: (datum: IndexedObject) => ({ name: datum.month, value: numberWithCommas(datum.value) }),
    },
  };

  const DATA_CHART_2 = [
    {
      month: '1年目',
      value: getValueOrDefault(cal.Y413(1)),
      type: 'A品',
    },
    {
      month: '1年目',
      value: getValueOrDefault(cal.Y414(1)),
      type: 'その他等級',
    },
    {
      month: '2年目',
      value: getValueOrDefault(cal.Y413(2)),
      type: 'A品',
    },
    {
      month: '2年目',
      value: getValueOrDefault(cal.Y414(2)),
      type: 'その他等級',
    },
    {
      month: '3年目',
      value: getValueOrDefault(cal.Y413(3)),
      type: 'A品',
    },
    {
      month: '3年目',
      value: getValueOrDefault(cal.Y414(3)),
      type: 'その他等級',
    },
    {
      month: '4年目',
      value: getValueOrDefault(cal.Y413(4)),
      type: 'A品',
    },
    {
      month: '4年目',
      value: getValueOrDefault(cal.Y414(4)),
      type: 'その他等級',
    },
    {
      month: '5年目',
      value: getValueOrDefault(cal.Y413(5)),
      type: 'A品',
    },
    {
      month: '5年目',
      value: getValueOrDefault(cal.Y414(5)),
      type: 'その他等級',
    },
  ];
  const getAnotation2 = () => {
    const annotations = [] as Anotation[];
    const group = groupBy(DATA_CHART_2, 'month');
    each(group, (values, k) => {
      const value = values.reduce((a, b) => a + b.value, 0);
      annotations.push({
        type: 'text',
        position: [k, value],
        content: `${addCommas(value, 't')}`,
        style: {
          textAlign: 'center',
          fontSize: 14,
          fill: 'rgba(0,0,0,0.85)',
        },
        offsetY: -5,
      });
    });
    return annotations;
  };
  const dataColumnChartTab4Two = {
    data: DATA_CHART_2,
    xField: 'month',
    yField: 'value',
    isStack: true,
    seriesField: 'type',
    annotations: getAnotation2(),
    yAxis: {
      label: {
        formatter: (datum: string) => addCommas(Number(datum), 't', 0),
      },
    },
    tooltip: {
      formatter: (datum: IndexedObject) => ({ name: datum.month, value: addCommas(datum.value, 't') }),
    },
  };

  type Percent = {
    type: string;
    value: number;
    percent: number;
  };

  const dataPieChartTab4 = {
    data: [
      {
        type: 'A品',
        value: getValueOrDefault(cal.Y411(1) * 100),
      },
      {
        type: 'その他等級',
        value: getValueOrDefault(cal.Y412(1) * 100),
      },
    ],
    appendPadding: 10,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: (value: Percent) => `${(value.percent * 100).toFixed(2)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
    tooltip: {
      formatter: (datum: IndexedObject) => ({ name: datum.type, value: addCommas(datum.value, '%') }),
    },
  };
  const OPTIONS_TABLE_TAB4 = {
    conditions: {
      text: [
        {
          field: 'col1',
          mapping(index: any, value: any) {
            if (Number(removeCommas(value.val1)) < 0) {
              return {
                fill: '#FF4D4F',
              };
            }
            return { fill: '#00000' };
          },
        },
        {
          field: 'col2',
          mapping(index: any, value: any) {
            if (Number(removeCommas(value.val1)) < 0) {
              return {
                fill: '#FF4D4F',
              };
            }
            return { fill: '#00000' };
          },
        },
      ],
    },
  };
  const OPTIONS_TABLE_TAB4_2 = {
    height: 280,
    conditions: {
      text: [
        {
          field: 'val1',
          mapping(index: any, value: any) {
            if (Number(removeCommas(value.val1)) < 0) {
              return {
                fill: '#FF4D4F',
              };
            }
            return { fill: '#00000' };
          },
        },
        {
          field: 'col2',
          mapping(index: any, value: any) {
            if (Number(removeCommas(value.col2)) < 0) {
              return {
                fill: '#FF4D4F',
              };
            }
            return { fill: '#00000' };
          },
        },
        {
          field: 'col3',
          mapping(index: any, value: any) {
            if (Number(removeCommas(value.col2)) < 0) {
              return {
                fill: '#FF4D4F',
              };
            }
            return { fill: '#00000' };
          },
        },
      ],
    },
  };
  return {
    configTableTab4,
    configTableTab4Two,
    configTableTab4Three,
    dataColumnChartTab4,
    dataColumnChartTab4Two,
    dataPieChartTab4,
    OPTIONS_TABLE_TAB4,
    OPTIONS_TABLE_TAB4_2,
  };
};
