/* eslint-disable complexity */
import { ThemeName, generatePalette, getPalette } from '@antv/s2';
import { addCommas, numberWithCommas } from 'libs/helpers/functions';
import { Calculator } from 'libs/calculators';
import { TTemplate } from 'types/template';
import { TTrainingResult } from 'types/trainingResult';

export const configTable1 = (template: TTemplate, trainingResult: TTrainingResult) => {
  const cal = new Calculator(template, trainingResult);

  const configTable1 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2', 'value3'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: '１人あたりの費用',
      },
      {
        field: 'value2',
        name: '人数',
      },
      {
        field: 'value3',
        name: '費用合計',
      },
    ],
    data: [
      {
        title: '昼食代',
        value1: numberWithCommas(cal.Q0607001()),
        value2: 1 + cal.numPersonWelfare(),
        value3: numberWithCommas(cal.Y812()),
      },
      {
        title: '社員旅行',
        value1: numberWithCommas(cal.Q0607008()),
        value2: 1 + cal.Q0401002() + cal.Q0401004(),
        value3: numberWithCommas(cal.Y814()),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0607002', 'step6') || 'その他制度1',
        value1: numberWithCommas(cal.Q0607003()),
        value2: 1 + cal.numPersonWelfare(),
        value3: numberWithCommas(cal.totalCostOtherSys1()),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0607004', 'step6') || 'その他制度2',
        value1: numberWithCommas(cal.Q0607005()),
        value2: 1 + cal.numPersonWelfare(),
        value3: numberWithCommas(cal.totalCostOtherSys2()),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0607006', 'step6') || 'その他制度3',
        value1: numberWithCommas(cal.Q0607007()),
        value2: 1 + cal.numPersonWelfare(),
        value3: numberWithCommas(cal.totalCostOtherSys3()),
      },
    ],
  };

  const configTable2 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2', 'value3'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: '１人あたりの費用',
      },
      {
        field: 'value2',
        name: '人数',
      },
      {
        field: 'value3',
        name: '費用合計',
      },
    ],
    data: [
      {
        title: 'パート社外教育',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0608001', 'step6')),
        value2: addCommas(cal.Q0403001(), '', 0),
        value3: numberWithCommas(cal.Y822()),
      },
      {
        title: '正社員社外教育',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0608002', 'step6')),
        value2: addCommas(1 + cal.Q0401002() + cal.Q0401004(), '', 0),
        value3: numberWithCommas(cal.Y823()),
      },
      {
        title: '国内視察',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0608003', 'step6')),
        value2: addCommas(1 + cal.Q0401002() + cal.Q0401004(), '', 0),
        value3: numberWithCommas(cal.Y824()),
      },
      {
        title: '国外視察',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0608004', 'step6')),
        value2: addCommas(1 + cal.Q0401002() + cal.Q0401004(), '', 0),
        value3: numberWithCommas(cal.Y825()),
      },
    ],
  };

  const configTable4 = {
    fields: {
      columns: ['col1', 'col2'],
    },
    data: [
      {
        col1: '管理経費合計（年）',
        col2: numberWithCommas(cal.Y800()),
      },
    ],
  };

  const configTable3 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2', 'value3', 'value4', 'value5'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: '単価',
      },
      {
        field: 'value2',
        name: '個数',
      },
      {
        field: 'value3',
        name: '費用合計',
      },
      {
        field: 'value4',
        name: '減価償却期間',
      },
      {
        field: 'value5',
        name: '減価償却費（年）',
      },
    ],
    data: [
      {
        title: '軽トラック',
        value1: numberWithCommas(cal.Q0608005()),
        value2: 1,
        value3: numberWithCommas(cal.Q0608005()),
        value4: 6,
        value5: numberWithCommas(cal.Y838()),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609005', 'step6') || '正社員支給物1',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609006', 'step6')),
        value2: addCommas(1 + cal.Q0401002() + cal.Q0401004(), '', 0),
        value3: numberWithCommas(
          Number(cal.getValueOfQuestionId('Q0609006', 'step6')) * (1 + cal.Q0401002() + cal.Q0401004()),
        ),
        value4: 2,
        value5: numberWithCommas(
          (Number(cal.getValueOfQuestionId('Q0609006', 'step6')) * (1 + cal.Q0401002() + cal.Q0401004())) / 2,
        ),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609007', 'step6') || '正社員支給物2',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609008', 'step6')),
        value2: addCommas(1 + cal.Q0401002() + cal.Q0401004(), '', 0),
        value3: numberWithCommas(
          Number(cal.getValueOfQuestionId('Q0609008', 'step6')) * (1 + cal.Q0401002() + cal.Q0401004()),
        ),
        value4: 2,
        value5: numberWithCommas(
          (Number(cal.getValueOfQuestionId('Q0609008', 'step6')) * (1 + cal.Q0401002() + cal.Q0401004())) / 2,
        ),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609009', 'step6') || '正社員支給物3',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609010', 'step6')),
        value2: addCommas(1 + cal.Q0401002() + cal.Q0401004(), '', 0),
        value3: numberWithCommas(
          Number(cal.getValueOfQuestionId('Q0609010', 'step6')) * (1 + cal.Q0401002() + cal.Q0401004()),
        ),
        value4: 2,
        value5: numberWithCommas(
          (Number(cal.getValueOfQuestionId('Q0609010', 'step6')) * (1 + cal.Q0401002() + cal.Q0401004())) / 2,
        ),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609011', 'step6') || '正社員支給物4',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609012', 'step6')),
        value2: addCommas(1 + cal.Q0401002() + cal.Q0401004(), '', 0),
        value3: numberWithCommas(
          Number(cal.getValueOfQuestionId('Q0609012', 'step6')) * (1 + cal.Q0401002() + cal.Q0401004()),
        ),
        value4: 2,
        value5: numberWithCommas(
          (Number(cal.getValueOfQuestionId('Q0609012', 'step6')) * (1 + cal.Q0401002() + cal.Q0401004())) / 2,
        ),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609013', 'step6') || '正社員支給物5',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609014', 'step6')),
        value2: addCommas(1 + cal.Q0401002() + cal.Q0401004(), '', 0),
        value3: numberWithCommas(
          Number(cal.getValueOfQuestionId('Q0609014', 'step6')) * (1 + cal.Q0401002() + cal.Q0401004()),
        ),
        value4: 2,
        value5: numberWithCommas(
          (Number(cal.getValueOfQuestionId('Q0609014', 'step6')) * (1 + cal.Q0401002() + cal.Q0401004())) / 2,
        ),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609015', 'step6') || 'パート支給物1',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609016', 'step6')),
        value2: cal.Q0403001(),
        value3: numberWithCommas(Number(cal.getValueOfQuestionId('Q0609016', 'step6')) * cal.Q0403001()),
        value4: 2,
        value5: numberWithCommas((Number(cal.getValueOfQuestionId('Q0609016', 'step6')) * cal.Q0403001()) / 2),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609017', 'step6') || 'パート支給物2',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609018', 'step6')),
        value2: cal.Q0403001(),
        value3: numberWithCommas(Number(cal.getValueOfQuestionId('Q0609018', 'step6')) * cal.Q0403001()),
        value4: 2,
        value5: numberWithCommas((Number(cal.getValueOfQuestionId('Q0609018', 'step6')) * cal.Q0403001()) / 2),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609019', 'step6') || 'パート支給物3',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609020', 'step6')),
        value2: cal.Q0403001(),
        value3: numberWithCommas(Number(cal.getValueOfQuestionId('Q0609020', 'step6')) * cal.Q0403001()),
        value4: 2,
        value5: numberWithCommas((Number(cal.getValueOfQuestionId('Q0609020', 'step6')) * cal.Q0403001()) / 2),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609021', 'step6') || 'パート支給物4',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609022', 'step6')),
        value2: cal.Q0403001(),
        value3: numberWithCommas(Number(cal.getValueOfQuestionId('Q0609022', 'step6')) * cal.Q0403001()),
        value4: 2,
        value5: numberWithCommas((Number(cal.getValueOfQuestionId('Q0609022', 'step6')) * cal.Q0403001()) / 2),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609023', 'step6') || 'パート支給物5',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609024', 'step6')),
        value2: cal.Q0403001(),
        value3: numberWithCommas(Number(cal.getValueOfQuestionId('Q0609024', 'step6')) * cal.Q0403001()),
        value4: 2,
        value5: numberWithCommas((Number(cal.getValueOfQuestionId('Q0609024', 'step6')) * cal.Q0403001()) / 2),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609025', 'step6') || '管理システム1',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609026', 'step6')),
        value2: 1,
        value3: numberWithCommas(cal.getValueOfQuestionId('Q0609026', 'step6')),
        value4: 4,
        value5: numberWithCommas(cal.getValueOfQuestionId('Q0609026', 'step6') / 4),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609027', 'step6') || '管理システム2',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609028', 'step6')),
        value2: 1,
        value3: numberWithCommas(cal.getValueOfQuestionId('Q0609028', 'step6')),
        value4: 4,
        value5: numberWithCommas(cal.getValueOfQuestionId('Q0609028', 'step6') / 4),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609029', 'step6') || '管理システム3',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609030', 'step6')),
        value2: 1,
        value3: numberWithCommas(cal.getValueOfQuestionId('Q0609030', 'step6')),
        value4: 4,
        value5: numberWithCommas(cal.getValueOfQuestionId('Q0609030', 'step6') / 4),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609031', 'step6') || '管理システム4',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609032', 'step6')),
        value2: 1,
        value3: numberWithCommas(cal.getValueOfQuestionId('Q0609032', 'step6')),
        value4: 4,
        value5: numberWithCommas(cal.getValueOfQuestionId('Q0609032', 'step6') / 4),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609033', 'step6') || '管理システム5',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609034', 'step6')),
        value2: 1,
        value3: numberWithCommas(cal.getValueOfQuestionId('Q0609034', 'step6')),
        value4: 4,
        value5: numberWithCommas(cal.getValueOfQuestionId('Q0609034', 'step6') / 4),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609035', 'step6') || 'その他経費1',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609036', 'step6')),
        value2: 1,
        value3: numberWithCommas(cal.getValueOfQuestionId('Q0609036', 'step6')),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609037', 'step6') || 'その他経費2',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609038', 'step6')),
        value2: 1,
        value3: numberWithCommas(cal.getValueOfQuestionId('Q0609038', 'step6')),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609039', 'step6') || 'その他経費3',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609040', 'step6')),
        value2: 1,
        value3: numberWithCommas(cal.getValueOfQuestionId('Q0609040', 'step6')),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609041', 'step6') || 'その他経費4',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609042', 'step6')),
        value2: 1,
        value3: numberWithCommas(cal.getValueOfQuestionId('Q0609042', 'step6')),
      },
      {
        title: cal.getValueStringOfQuestionId('Q0609043', 'step6') || 'その他経費5',
        value1: numberWithCommas(cal.getValueOfQuestionId('Q0609044', 'step6')),
        value2: 1,
        value3: numberWithCommas(cal.getValueOfQuestionId('Q0609044', 'step6')),
      },
    ],
  };

  return {
    configTable1,
    configTable2,
    configTable3,
    configTable4,
  };
};

export const OPTIONS_TABLE1 = {
  width: 300,
  height: 200,
};

export const THEME_TABLE = {
  name: 'colorful' as ThemeName,
  palette: generatePalette({
    ...getPalette('colorful'),
    brandColor: '#1D81BA',
  }),
};

export const OPTIONS_TABLE2 = {
  height: 170,
};

export const OPTIONS_TABLE3 = {
  width: 300,
};

export const OPTIONS_TABLE4 = {
  width: 300,
  height: 80,
};
