import React from 'react';
import styles from './style.module.scss';
import SheetTable from '../../../components/SheetTable/SheetTable';
import { OPTIONS_TABLE1, THEME_TABLE, OPTIONS_TABLE2, OPTIONS_TABLE3, OPTIONS_TABLE4 } from './AssignmentScaleUp.state';

const AssignmentScaleUpForm: React.FC<{ configTable: any; tableDataForScaleUp: any }> = ({
  configTable,
  tableDataForScaleUp,
}) => (
  <div>
    {/* row 1 */}
    <div>
      <p className={styles.p_2}>ここまでで初年度の計画が決定しました。ここで一度PLを確認してみましょう！</p>
      {configTable.configTable1 && (
        <SheetTable dataCfg={configTable?.configTable1} options={OPTIONS_TABLE1} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table1-row0-value1">{configTable.configTable1.data[0].value1}</td>
        <td data-testid="table1-row0-value2">{configTable.configTable1.data[0].value2}</td>
        <td data-testid="table1-row0-value3">{configTable.configTable1.data[0].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row1-value1">{configTable.configTable1.data[1].value1}</td>
        <td data-testid="table1-row1-value2">{configTable.configTable1.data[1].value2}</td>
        <td data-testid="table1-row1-value3">{configTable.configTable1.data[1].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row2-value1">{configTable.configTable1.data[2].value1}</td>
        <td data-testid="table1-row2-value2">{configTable.configTable1.data[2].value2}</td>
        <td data-testid="table1-row2-value3">{configTable.configTable1.data[2].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row3-value1">{configTable.configTable1.data[3].value1}</td>
        <td data-testid="table1-row3-value2">{configTable.configTable1.data[3].value2}</td>
        <td data-testid="table1-row3-value3">{configTable.configTable1.data[3].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row4-value1">{configTable.configTable1.data[4].value1}</td>
        <td data-testid="table1-row4-value2">{configTable.configTable1.data[4].value2}</td>
        <td data-testid="table1-row4-value3">{configTable.configTable1.data[4].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row5-value1">{configTable.configTable1.data[5].value1}</td>
        <td data-testid="table1-row5-value2">{configTable.configTable1.data[5].value2}</td>
        <td data-testid="table1-row5-value3">{configTable.configTable1.data[5].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row6-value1">{configTable.configTable1.data[6].value1}</td>
        <td data-testid="table1-row6-value2">{configTable.configTable1.data[6].value2}</td>
        <td data-testid="table1-row6-value3">{configTable.configTable1.data[6].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row7-value1">{configTable.configTable1.data[7].value1}</td>
        <td data-testid="table1-row7-value2">{configTable.configTable1.data[7].value2}</td>
        <td data-testid="table1-row7-value3">{configTable.configTable1.data[7].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row8-value1">{configTable.configTable1.data[8].value1}</td>
        <td data-testid="table1-row8-value2">{configTable.configTable1.data[8].value2}</td>
        <td data-testid="table1-row8-value3">{configTable.configTable1.data[8].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row9-value1">{configTable.configTable1.data[9].value1}</td>
        <td data-testid="table1-row9-value2">{configTable.configTable1.data[9].value2}</td>
        <td data-testid="table1-row9-value3">{configTable.configTable1.data[9].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row10-value1">{configTable.configTable1.data[10].value1}</td>
        <td data-testid="table1-row10-value2">{configTable.configTable1.data[10].value2}</td>
        <td data-testid="table1-row10-value3">{configTable.configTable1.data[10].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row11-value1">{configTable.configTable1.data[11].value1}</td>
        <td data-testid="table1-row11-value2">{configTable.configTable1.data[11].value2}</td>
        <td data-testid="table1-row11-value3">{configTable.configTable1.data[11].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row12-value1">{configTable.configTable1.data[12].value1}</td>
        <td data-testid="table1-row12-value2">{configTable.configTable1.data[12].value2}</td>
        <td data-testid="table1-row12-value3">{configTable.configTable1.data[12].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row13-value1">{configTable.configTable1.data[13].value1}</td>
        <td data-testid="table1-row13-value2">{configTable.configTable1.data[13].value2}</td>
        <td data-testid="table1-row13-value3">{configTable.configTable1.data[13].value3}</td>
      </tr>
    </table>

    {/* row 2 */}
    <div className={styles.littleTab}>
      <p>年度ごとの規模拡大</p>
      {tableDataForScaleUp.configTable2 && (
        <SheetTable dataCfg={tableDataForScaleUp?.configTable2} options={OPTIONS_TABLE2} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table2-row0-value1">{tableDataForScaleUp.configTable2.data[0].value1}</td>
        <td data-testid="table2-row0-value2">{tableDataForScaleUp.configTable2.data[0].value2}</td>
        <td data-testid="table2-row0-value3">{tableDataForScaleUp.configTable2.data[0].value3}</td>
        <td data-testid="table2-row0-value4">{tableDataForScaleUp.configTable2.data[0].value4}</td>
        <td data-testid="table2-row0-value5">{tableDataForScaleUp.configTable2.data[0].value5}</td>
      </tr>
      <tr>
        <td data-testid="table2-row1-value1">{tableDataForScaleUp.configTable2.data[1].value1}</td>
        <td data-testid="table2-row1-value2">{tableDataForScaleUp.configTable2.data[1].value2}</td>
        <td data-testid="table2-row1-value3">{tableDataForScaleUp.configTable2.data[1].value3}</td>
        <td data-testid="table2-row1-value4">{tableDataForScaleUp.configTable2.data[1].value4}</td>
        <td data-testid="table2-row1-value5">{tableDataForScaleUp.configTable2.data[1].value5}</td>
      </tr>
    </table>

    {/* row 3 */}
    <div className={styles.littleTab}>
      <p>１圃場あたりの規模拡大に必要とする投資額です。</p>
      {tableDataForScaleUp.configTable3 && (
        <SheetTable dataCfg={tableDataForScaleUp?.configTable3} options={OPTIONS_TABLE3} themeCfg={THEME_TABLE} />
      )}
      <p>※規模拡大を行う場合には、１年目に建設した施設同様の費用が発生します。</p>
      <p>※規模拡大を行うと選択した年度から栽培、収穫、販売を行えるものとします。</p>
      <p className={styles.mb_50}> （前年度からハウス建設、準備を始めるものとします）</p>
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table3-row0-value1">{tableDataForScaleUp.configTable3.data[0].value1}</td>
        <td data-testid="table3-row0-value2">{tableDataForScaleUp.configTable3.data[0].value2}</td>
        <td data-testid="table3-row0-value3">{tableDataForScaleUp.configTable3.data[0].value3}</td>
      </tr>
    </table>

    {/* row 4 */}
    <div>
      <p>年度ごとの収量増加割合</p>
      {tableDataForScaleUp.configTable4 && (
        <SheetTable dataCfg={tableDataForScaleUp?.configTable4} options={OPTIONS_TABLE4} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table4-row0-value1">{tableDataForScaleUp.configTable4.data[0].value1}</td>
        <td data-testid="table4-row0-value2">{tableDataForScaleUp.configTable4.data[0].value2}</td>
        <td data-testid="table4-row0-value3">{tableDataForScaleUp.configTable4.data[0].value3}</td>
        <td data-testid="table4-row0-value4">{tableDataForScaleUp.configTable4.data[0].value4}</td>
        <td data-testid="table4-row0-value5">{tableDataForScaleUp.configTable4.data[0].value5}</td>
      </tr>
      <tr>
        <td data-testid="table4-row1-value1">{tableDataForScaleUp.configTable4.data[1].value1}</td>
        <td data-testid="table4-row1-value2">{tableDataForScaleUp.configTable4.data[1].value2}</td>
        <td data-testid="table4-row1-value3">{tableDataForScaleUp.configTable4.data[1].value3}</td>
        <td data-testid="table4-row1-value4">{tableDataForScaleUp.configTable4.data[1].value4}</td>
        <td data-testid="table4-row1-value5">{tableDataForScaleUp.configTable4.data[1].value5}</td>
      </tr>
      <tr>
        <td data-testid="table4-row2-value1">{tableDataForScaleUp.configTable4.data[2].value1}</td>
        <td data-testid="table4-row2-value2">{tableDataForScaleUp.configTable4.data[2].value2}</td>
        <td data-testid="table4-row2-value3">{tableDataForScaleUp.configTable4.data[2].value3}</td>
        <td data-testid="table4-row2-value4">{tableDataForScaleUp.configTable4.data[2].value4}</td>
        <td data-testid="table4-row2-value5">{tableDataForScaleUp.configTable4.data[2].value5}</td>
      </tr>
    </table>
  </div>
);

export default AssignmentScaleUpForm;
