import React from 'react';
import styles from './style.module.scss';
import SheetTable from '../../../components/SheetTable/SheetTable';
import {
  OPTIONS_TABLE1,
  THEME_TABLE,
  OPTIONS_TABLE2,
  OPTIONS_TABLE3,
  OPTIONS_TABLE4,
} from './AssignmentCalculationWorkTime.state';

const CalculationWorkTimeForm: React.FC<{ configTable: any }> = ({ configTable }) => (
  <div>
    <div className={styles.littleTab}>
      <p>福利厚生（年間）</p>
      {configTable?.configTable1 && (
        <SheetTable dataCfg={configTable.configTable1} options={OPTIONS_TABLE1} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table1-row0-value1">{configTable.configTable1.data[0].value1}</td>
        <td data-testid="table1-row0-value2">{configTable.configTable1.data[0].value2}</td>
        <td data-testid="table1-row0-value3">{configTable.configTable1.data[0].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row1-value1">{configTable.configTable1.data[1].value1}</td>
        <td data-testid="table1-row1-value2">{configTable.configTable1.data[1].value2}</td>
        <td data-testid="table1-row1-value3">{configTable.configTable1.data[1].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row2-value1">{configTable.configTable1.data[2].value1}</td>
        <td data-testid="table1-row2-value2">{configTable.configTable1.data[2].value2}</td>
        <td data-testid="table1-row2-value3">{configTable.configTable1.data[2].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row3-value1">{configTable.configTable1.data[3].value1}</td>
        <td data-testid="table1-row3-value2">{configTable.configTable1.data[3].value2}</td>
        <td data-testid="table1-row3-value3">{configTable.configTable1.data[3].value3}</td>
      </tr>
      <tr>
        <td data-testid="table1-row4-value1">{configTable.configTable1.data[4].value1}</td>
        <td data-testid="table1-row4-value2">{configTable.configTable1.data[4].value2}</td>
        <td data-testid="table1-row4-value3">{configTable.configTable1.data[4].value3}</td>
      </tr>
    </table>

    {/* row 2 */}
    <div className={styles.littleTab}>
      <p>教育費（年間）</p>
      {configTable?.configTable2 && (
        <SheetTable dataCfg={configTable.configTable2} options={OPTIONS_TABLE2} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table2-row0-value1">{configTable.configTable2.data[0].value1}</td>
        <td data-testid="table2-row0-value2">{configTable.configTable2.data[0].value2}</td>
        <td data-testid="table2-row0-value3">{configTable.configTable2.data[0].value3}</td>
      </tr>
      <tr>
        <td data-testid="table2-row1-value1">{configTable.configTable2.data[1].value1}</td>
        <td data-testid="table2-row1-value2">{configTable.configTable2.data[1].value2}</td>
        <td data-testid="table2-row1-value3">{configTable.configTable2.data[1].value3}</td>
      </tr>
      <tr>
        <td data-testid="table2-row2-value1">{configTable.configTable2.data[2].value1}</td>
        <td data-testid="table2-row2-value2">{configTable.configTable2.data[2].value2}</td>
        <td data-testid="table2-row2-value3">{configTable.configTable2.data[2].value3}</td>
      </tr>
      <tr>
        <td data-testid="table2-row3-value1">{configTable.configTable2.data[3].value1}</td>
        <td data-testid="table2-row3-value2">{configTable.configTable2.data[3].value2}</td>
        <td data-testid="table2-row3-value3">{configTable.configTable2.data[3].value3}</td>
      </tr>
    </table>

    {/* row 3 */}
    <div>
      <p>その他管理経費</p>
      {configTable?.configTable3 && (
        <SheetTable dataCfg={configTable.configTable3} options={OPTIONS_TABLE3} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table3-row0-value1">{configTable.configTable3.data[0].value1}</td>
        <td data-testid="table3-row0-value2">{configTable.configTable3.data[0].value2}</td>
        <td data-testid="table3-row0-value3">{configTable.configTable3.data[0].value3}</td>
        <td data-testid="table3-row0-value4">{configTable.configTable3.data[0].value4}</td>
        <td data-testid="table3-row0-value5">{configTable.configTable3.data[0].value5}</td>
      </tr>
      <tr>
        <td data-testid="table3-row1-value1">{configTable.configTable3.data[1].value1}</td>
        <td data-testid="table3-row1-value2">{configTable.configTable3.data[1].value2}</td>
        <td data-testid="table3-row1-value3">{configTable.configTable3.data[1].value3}</td>
        <td data-testid="table3-row1-value4">{configTable.configTable3.data[1].value4}</td>
        <td data-testid="table3-row1-value5">{configTable.configTable3.data[1].value5}</td>
      </tr>
      <tr>
        <td data-testid="table3-row2-value1">{configTable.configTable3.data[2].value1}</td>
        <td data-testid="table3-row2-value2">{configTable.configTable3.data[2].value2}</td>
        <td data-testid="table3-row2-value3">{configTable.configTable3.data[2].value3}</td>
        <td data-testid="table3-row2-value4">{configTable.configTable3.data[2].value4}</td>
        <td data-testid="table3-row2-value5">{configTable.configTable3.data[2].value5}</td>
      </tr>
      <tr>
        <td data-testid="table3-row3-value1">{configTable.configTable3.data[3].value1}</td>
        <td data-testid="table3-row3-value2">{configTable.configTable3.data[3].value2}</td>
        <td data-testid="table3-row3-value3">{configTable.configTable3.data[3].value3}</td>
        <td data-testid="table3-row3-value4">{configTable.configTable3.data[3].value4}</td>
        <td data-testid="table3-row3-value5">{configTable.configTable3.data[3].value5}</td>
      </tr>
      <tr>
        <td data-testid="table3-row4-value1">{configTable.configTable3.data[4].value1}</td>
        <td data-testid="table3-row4-value2">{configTable.configTable3.data[4].value2}</td>
        <td data-testid="table3-row4-value3">{configTable.configTable3.data[4].value3}</td>
        <td data-testid="table3-row4-value4">{configTable.configTable3.data[4].value4}</td>
        <td data-testid="table3-row4-value5">{configTable.configTable3.data[4].value5}</td>
      </tr>
      <tr>
        <td data-testid="table3-row5-value1">{configTable.configTable3.data[5].value1}</td>
        <td data-testid="table3-row5-value2">{configTable.configTable3.data[5].value2}</td>
        <td data-testid="table3-row5-value3">{configTable.configTable3.data[5].value3}</td>
        <td data-testid="table3-row5-value4">{configTable.configTable3.data[5].value4}</td>
        <td data-testid="table3-row5-value5">{configTable.configTable3.data[5].value5}</td>
      </tr>
      <tr>
        <td data-testid="table3-row6-value1">{configTable.configTable3.data[6].value1}</td>
        <td data-testid="table3-row6-value2">{configTable.configTable3.data[6].value2}</td>
        <td data-testid="table3-row6-value3">{configTable.configTable3.data[6].value3}</td>
        <td data-testid="table3-row6-value4">{configTable.configTable3.data[6].value4}</td>
        <td data-testid="table3-row6-value5">{configTable.configTable3.data[6].value5}</td>
      </tr>
      <tr>
        <td data-testid="table3-row7-value1">{configTable.configTable3.data[7].value1}</td>
        <td data-testid="table3-row7-value2">{configTable.configTable3.data[7].value2}</td>
        <td data-testid="table3-row7-value3">{configTable.configTable3.data[7].value3}</td>
        <td data-testid="table3-row7-value4">{configTable.configTable3.data[7].value4}</td>
        <td data-testid="table3-row7-value5">{configTable.configTable3.data[7].value5}</td>
      </tr>
      <tr>
        <td data-testid="table3-row8-value1">{configTable.configTable3.data[8].value1}</td>
        <td data-testid="table3-row8-value2">{configTable.configTable3.data[8].value2}</td>
        <td data-testid="table3-row8-value3">{configTable.configTable3.data[8].value3}</td>
        <td data-testid="table3-row8-value4">{configTable.configTable3.data[8].value4}</td>
        <td data-testid="table3-row8-value5">{configTable.configTable3.data[8].value5}</td>
      </tr>
      <tr>
        <td data-testid="table3-row9-value1">{configTable.configTable3.data[9].value1}</td>
        <td data-testid="table3-row9-value2">{configTable.configTable3.data[9].value2}</td>
        <td data-testid="table3-row9-value3">{configTable.configTable3.data[9].value3}</td>
        <td data-testid="table3-row9-value4">{configTable.configTable3.data[9].value4}</td>
        <td data-testid="table3-row9-value5">{configTable.configTable3.data[9].value5}</td>
      </tr>
      <tr>
        <td data-testid="table3-row10-value1">{configTable.configTable3.data[10].value1}</td>
        <td data-testid="table3-row10-value2">{configTable.configTable3.data[10].value2}</td>
        <td data-testid="table3-row10-value3">{configTable.configTable3.data[10].value3}</td>
        <td data-testid="table3-row10-value4">{configTable.configTable3.data[10].value4}</td>
        <td data-testid="table3-row10-value5">{configTable.configTable3.data[10].value5}</td>
      </tr>
      <tr>
        <td data-testid="table3-row11-value1">{configTable.configTable3.data[11].value1}</td>
        <td data-testid="table3-row11-value2">{configTable.configTable3.data[11].value2}</td>
        <td data-testid="table3-row11-value3">{configTable.configTable3.data[11].value3}</td>
        <td data-testid="table3-row11-value4">{configTable.configTable3.data[11].value4}</td>
        <td data-testid="table3-row11-value5">{configTable.configTable3.data[11].value5}</td>
      </tr>
      <tr>
        <td data-testid="table3-row12-value1">{configTable.configTable3.data[12].value1}</td>
        <td data-testid="table3-row12-value2">{configTable.configTable3.data[12].value2}</td>
        <td data-testid="table3-row12-value3">{configTable.configTable3.data[12].value3}</td>
        <td data-testid="table3-row12-value4">{configTable.configTable3.data[12].value4}</td>
        <td data-testid="table3-row12-value5">{configTable.configTable3.data[12].value5}</td>
      </tr>
      <tr>
        <td data-testid="table3-row13-value1">{configTable.configTable3.data[13].value1}</td>
        <td data-testid="table3-row13-value2">{configTable.configTable3.data[13].value2}</td>
        <td data-testid="table3-row13-value3">{configTable.configTable3.data[13].value3}</td>
        <td data-testid="table3-row13-value4">{configTable.configTable3.data[13].value4}</td>
        <td data-testid="table3-row13-value5">{configTable.configTable3.data[13].value5}</td>
      </tr>
      <tr>
        <td data-testid="table3-row14-value1">{configTable.configTable3.data[14].value1}</td>
        <td data-testid="table3-row14-value2">{configTable.configTable3.data[14].value2}</td>
        <td data-testid="table3-row14-value3">{configTable.configTable3.data[14].value3}</td>
        <td data-testid="table3-row14-value4">{configTable.configTable3.data[14].value4}</td>
        <td data-testid="table3-row14-value5">{configTable.configTable3.data[14].value5}</td>
      </tr>
      <tr>
        <td data-testid="table3-row15-value1">{configTable.configTable3.data[15].value1}</td>
        <td data-testid="table3-row15-value2">{configTable.configTable3.data[15].value2}</td>
        <td data-testid="table3-row15-value3">{configTable.configTable3.data[15].value3}</td>
        <td data-testid="table3-row15-value4">{configTable.configTable3.data[15].value4}</td>
        <td data-testid="table3-row15-value5">{configTable.configTable3.data[15].value5}</td>
      </tr>
      <tr>
        <td data-testid="table3-row16-value1">{configTable.configTable3.data[16].value1}</td>
        <td data-testid="table3-row16-value2">{configTable.configTable3.data[16].value2}</td>
        <td data-testid="table3-row16-value3">{configTable.configTable3.data[16].value3}</td>
      </tr>
      <tr>
        <td data-testid="table3-row17-value1">{configTable.configTable3.data[17].value1}</td>
        <td data-testid="table3-row17-value2">{configTable.configTable3.data[17].value2}</td>
        <td data-testid="table3-row17-value3">{configTable.configTable3.data[17].value3}</td>
      </tr>
      <tr>
        <td data-testid="table3-row18-value1">{configTable.configTable3.data[18].value1}</td>
        <td data-testid="table3-row18-value2">{configTable.configTable3.data[18].value2}</td>
        <td data-testid="table3-row18-value3">{configTable.configTable3.data[18].value3}</td>
      </tr>
      <tr>
        <td data-testid="table3-row19-value1">{configTable.configTable3.data[19].value1}</td>
        <td data-testid="table3-row19-value2">{configTable.configTable3.data[19].value2}</td>
        <td data-testid="table3-row19-value3">{configTable.configTable3.data[19].value3}</td>
      </tr>
      <tr>
        <td data-testid="table3-row20-value1">{configTable.configTable3.data[20].value1}</td>
        <td data-testid="table3-row20-value2">{configTable.configTable3.data[20].value2}</td>
        <td data-testid="table3-row20-value3">{configTable.configTable3.data[20].value3}</td>
      </tr>
    </table>

    {/* row 4 */}
    <div>
      {configTable?.configTable4 && (
        <SheetTable dataCfg={configTable.configTable4} options={OPTIONS_TABLE4} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table4-row0-col2">{configTable.configTable4.data[0].col2}</td>
      </tr>
    </table>
  </div>
);

export default CalculationWorkTimeForm;
