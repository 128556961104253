import { ThemeName, generatePalette, getPalette, IconPosition } from '@antv/s2';
import { TTemplate } from 'types/template';
import { TTrainingResult } from 'types/trainingResult';
import { Calculator } from 'libs/calculators';
import { addCommas, fixedNumber, numberWithCommas } from 'libs/helpers/functions';
import { IndexedObject } from 'constants/types';

const TTargetYear = 1;

export const configTable1 = (template: TTemplate, trainingResult: TTrainingResult) => {
  const cal = new Calculator(template, trainingResult);
  cal.updateTrainingResult(trainingResult);

  const getValueByFieldName = (fieldName: string): number => cal.getValueOfQuestionId(fieldName, 'step2');

  const configTable1 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: '10aあたり費用',
      },
      {
        field: 'value2',
        name: '費用合計',
      },
    ],
    data: [
      {
        title: '土地調達',
        value1: numberWithCommas(getValueByFieldName('Q0201001')),
        value2: numberWithCommas(cal.Y211(TTargetYear)),
      },
      {
        title: 'ハウス',
        value1: numberWithCommas(getValueByFieldName('Q0201005')),
        value2: numberWithCommas(cal.Y212(TTargetYear)),
      },
      {
        title: '農舎',
        value1: '-',
        value2: numberWithCommas(cal.Y213()),
      },
      {
        title: 'パート休憩用の事務所',
        value1: '-',
        value2: numberWithCommas(cal.Y214()),
      },
      {
        title: '合計',
        value1: '-',
        value2: numberWithCommas(cal.Y210(TTargetYear)),
      },
    ],
  };

  const configTable2 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: '10aあたり費用',
      },
      {
        field: 'value2',
        name: '費用合計',
      },
    ],
    data: [
      {
        title: '暖房機（重油）費用',
        value1: numberWithCommas(getValueByFieldName('Q0202002')),
        value2: numberWithCommas(cal.Y221(TTargetYear)),
      },
      {
        title: 'カーテン',
        value1: numberWithCommas(getValueByFieldName('Q0202003')),
        value2: numberWithCommas(cal.Y222(TTargetYear)),
      },
      {
        title: '循環扇',
        value1: numberWithCommas(getValueByFieldName('Q0202004')),
        value2: numberWithCommas(cal.Y223(TTargetYear)),
      },
      {
        title: 'かん水装置',
        value1: numberWithCommas(getValueByFieldName('Q0202005')),
        value2: numberWithCommas(cal.Y224(TTargetYear)),
      },
      {
        title: '炭酸ガス発生機',
        value1: numberWithCommas(getValueByFieldName('Q0202006')),
        value2: numberWithCommas(cal.Y225(TTargetYear)),
      },
      {
        title: '局所使用ダクトファン',
        value1: numberWithCommas(getValueByFieldName('Q0202007')),
        value2: numberWithCommas(cal.Y226(TTargetYear)),
      },
      {
        title: 'ミストシステム',
        value1: numberWithCommas(getValueByFieldName('Q0202008')),
        value2: numberWithCommas(cal.Y227(TTargetYear)),
      },
      {
        title: '合計',
        value1: '-',
        value2: numberWithCommas(cal.Y220(TTargetYear)),
      },
    ],
  };

  const configTable3 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: '10aあたり費用',
      },
      {
        field: 'value2',
        name: '費用合計',
      },
    ],
    data: [
      {
        title: '環境モニタリング機器',
        value1: numberWithCommas(getValueByFieldName('Q0203001')),
        value2: numberWithCommas(cal.Y231(TTargetYear)),
      },
      {
        title: '警報システム',
        value1: numberWithCommas(getValueByFieldName('Q0203002')),
        value2: numberWithCommas(cal.Y232(TTargetYear)),
      },
      {
        title: '合計',
        value1: '-',
        value2: numberWithCommas(cal.Y230(TTargetYear)),
      },
    ],
  };

  const configTable4 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: '10aあたり費用',
      },
      {
        field: 'value2',
        name: '費用合計',
      },
    ],
    data: [
      {
        title: '環境制御盤',
        value1: numberWithCommas(getValueByFieldName('Q0204001')),
        value2: numberWithCommas(cal.Y240(TTargetYear)),
      },
      {
        title: '合計',
        value1: '-',
        value2: numberWithCommas(cal.Y240(TTargetYear)),
      },
    ],
  };

  const configTable5 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value2',
        name: '費用合計',
      },
    ],
    data: [
      {
        title: 'トラクター',
        value2: numberWithCommas(cal.Y251()),
      },
      {
        title: '管理機',
        value2: numberWithCommas(cal.Y252()),
      },
      {
        title: '農薬散布機(動力噴霧器)',
        value2: numberWithCommas(cal.Y253()),
      },
      {
        title: '合計',
        value2: numberWithCommas(cal.Y250()),
      },
    ],
  };

  const configTable6 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: '10aあたり費用',
      },
      {
        field: 'value2',
        name: '費用合計',
      },
    ],
    data: [
      {
        title: '暖房機稼働費',
        value1: numberWithCommas(getValueByFieldName('Q0206001')),
        value2: numberWithCommas(cal.Y1101()),
      },
      {
        title: '炭酸ガス発生機稼働費',
        value1: numberWithCommas(getValueByFieldName('Q0206002')),
        value2: numberWithCommas(cal.Y1102()),
      },
      {
        title: '合計',
        value1: '-',
        value2: numberWithCommas(cal.Y1100()),
      },
    ],
  };

  const configTable7 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: '10aあたり費用',
      },
    ],
    data: [
      {
        title: '初期投資合計',
        value1: numberWithCommas(cal.Y201(TTargetYear)),
      },
      {
        title: '減価償却費（年）',
        value1: numberWithCommas(cal.Y501(TTargetYear)),
      },
      {
        title: '減価償却費（月）',
        value1: numberWithCommas(cal.M501(TTargetYear)),
      },
    ],
  };

  const configTable8 = {
    fields: {
      rows: ['title'],
      values: ['value1'],
    },
    meta: [
      {
        field: 'title',
        name: '等級',
      },
      {
        field: 'value1',
        name: '収量',
      },
    ],
    data: [
      {
        title: 'A品',
        value1: addCommas(cal.Y413(1), 't'),
      },
      {
        title: 'その他等級',
        value1: addCommas(cal.Y414(1), 't'),
      },
      {
        title: '合計',
        value1: addCommas(cal.Y416(1), 't'),
      },
      {
        title: '合計（10aあたり）',
        value1: addCommas(cal.Y415(1), 't'),
      },
    ],
  };

  const configTableRobotAndAI = {
    fields: {
      rows: ['title'],
      values: ['value1'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: '費用合計',
      },
    ],
    data: [
      {
        title: 'ロボット',
        value1: numberWithCommas(cal.Y280()),
      },
      {
        title: 'AI',
        value1: numberWithCommas(cal.Y290()),
      },
      {
        title: '合計',
        value1: numberWithCommas(cal.Y280() + cal.Y290()),
      },
    ],
  };

  const dataChart1Data = [
    {
      month: 'ハウス施設投資',
      value: cal.Y210(TTargetYear),
      type: '1',
    },
    {
      month: '機器投資',
      value: cal.Y220(TTargetYear),
      type: '1',
    },
    {
      month: 'モニタリング',
      value: cal.Y230(TTargetYear),
      type: '1',
    },
    {
      month: '制御盤',
      value: cal.Y240(TTargetYear),
      type: '1',
    },
    {
      month: 'その他大型機械',
      value: cal.Y250(),
      type: '1',
    },
    {
      month: 'ロボット・AI投資',
      value: cal.Y280() + cal.Y290(),
      type: '1',
    },
  ];

  const dataChart1 = {
    data: dataChart1Data,
    xField: 'month',
    yField: 'value',
    isStack: true,
    yAxis: {
      label: {
        formatter: (datum: string) => numberWithCommas(Number(datum)),
      },
    },
    customSVGIcons: [
      {
        name: 'TEST',
        svg: 'https://www.svgrepo.com/show/72589/yen-symbol.svg',
      },
    ],
    tooltip: {
      formatter: (datum: IndexedObject) => ({ name: datum.year, value: numberWithCommas(datum.value) }),
    },
    conditions: {
      icon: [
        {
          field: 'value',
          position: 'left' as IconPosition,
          mapping(field: any) {
            if (typeof field !== 'number') {
              return { icon: '', fill: '' };
            }
            return {
              icon: 'TEST',
              fill: '#ffff',
              marginRight: 50,
            };
          },
        },
      ],
    },
    label: {
      position: 'top',
      formatter: (datum: IndexedObject) => numberWithCommas(datum.value),
    },
  };

  type Percent = {
    type: string;
    value: number;
    percent: number;
  };

  const dataPieChart = {
    data: [
      {
        type: 'A品',
        value: cal.Y413(1),
      },
      {
        type: 'その他等級',
        value: cal.Y414(1),
      },
    ],
    appendPadding: 10,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: (value: Percent) => `${(value.percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };

  const DATA_CHART_2 = [
    {
      month: '10月',
      value: fixedNumber(cal.M413(10), 2),
      type: 'A品',
    },
    {
      month: '10月',
      value: fixedNumber(cal.M414(10), 2),
      type: 'その他等級',
    },
    {
      month: '11月',
      value: fixedNumber(cal.M413(11), 2),
      type: 'A品',
    },
    {
      month: '11月',
      value: fixedNumber(cal.M414(11), 2),
      type: 'その他等級',
    },
    {
      month: '12月',
      value: fixedNumber(cal.M413(12), 2),
      type: 'A品',
    },
    {
      month: '12月',
      value: fixedNumber(cal.M414(12), 2),
      type: 'その他等級',
    },
    {
      month: '1月',
      value: fixedNumber(cal.M413(1), 2),
      type: 'A品',
    },
    {
      month: '1月',
      value: fixedNumber(cal.M414(1), 2),
      type: 'その他等級',
    },
    {
      month: '2月',
      value: fixedNumber(cal.M413(2), 2),
      type: 'A品',
    },
    {
      month: '2月',
      value: fixedNumber(cal.M414(2), 2),
      type: 'その他等級',
    },
    {
      month: '3月',
      value: fixedNumber(cal.M413(3), 2),
      type: 'A品',
    },
    {
      month: '3月',
      value: fixedNumber(cal.M414(3), 2),
      type: 'その他等級',
    },
    {
      month: '4月',
      value: fixedNumber(cal.M413(4), 2),
      type: 'A品',
    },
    {
      month: '4月',
      value: fixedNumber(cal.M414(4), 2),
      type: 'その他等級',
    },
    {
      month: '5月',
      value: fixedNumber(cal.M413(5), 2),
      type: 'A品',
    },
    {
      month: '5月',
      value: fixedNumber(cal.M414(5), 2),
      type: 'その他等級',
    },
    {
      month: '6月',
      value: fixedNumber(cal.M413(6), 2),
      type: 'A品',
    },
    {
      month: '6月',
      value: fixedNumber(cal.M414(6), 2),
      type: 'その他等級',
    },
    {
      month: '7月',
      value: fixedNumber(cal.M413(7), 2),
      type: 'A品',
    },
    {
      month: '7月',
      value: fixedNumber(cal.M414(7), 2),
      type: 'その他等級',
    },
    {
      month: '8月',
      value: fixedNumber(cal.M413(8), 2),
      type: 'A品',
    },
    {
      month: '8月',
      value: fixedNumber(cal.M414(8), 2),
      type: 'その他等級',
    },
    {
      month: '9月',
      value: fixedNumber(cal.M413(9), 2),
      type: 'A品',
    },
    {
      month: '9月',
      value: fixedNumber(cal.M414(9), 2),
      type: 'その他等級',
    },
  ];

  const dataChart2 = {
    data: DATA_CHART_2,
    xField: 'month',
    yField: 'value',
    isStack: true,
    seriesField: 'type',
    yAxis: {
      label: {
        formatter: (datum: string) => addCommas(Number(datum), 't', 0),
      },
    },
    tooltip: {
      formatter: (datum: IndexedObject) => ({ name: datum.type, value: addCommas(datum.value, 't', 2) }),
    },
    label: {
      position: 'middle',
      content: (datum: IndexedObject) => addCommas(datum.value, 't', 2),
      style: {
        fontSize: 14,
        fill: 'rgba(0,0,0,0.85)',
      },
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
  };

  return {
    configTable1,
    configTable2,
    configTable3,
    configTable4,
    configTable5,
    configTable6,
    configTable7,
    configTable8,
    configTableRobotAndAI,
    dataChart1,
    dataPieChart,
    dataChart2,
  };
};

export const OPTIONS_TABLE1 = {
  height: 200,
  tooltip: {
    showTooltip: false,
  },
  showDefaultHeaderActionIcon: false,
};

export const OPTIONS_TABLE2 = {
  height: 300,
};

export const OPTIONS_TABLE3 = {
  height: 150,
};

export const OPTIONS_TABLE4 = {
  height: 100,
};

export const OPTIONS_TABLE5 = {
  height: 150,
};

export const OPTIONS_TABLE6 = {
  height: 150,
};

export const OPTIONS_TABLE7 = {
  height: 200,
};

export const OPTIONS_TABLE8 = {
  height: 250,
};

export const THEME_TABLE = {
  name: 'colorful' as ThemeName,
  palette: generatePalette({
    ...getPalette('colorful'),
    brandColor: '#1D81BA',
  }),
};
