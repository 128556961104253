import React from 'react';
import { Col, Row } from 'antd';
import SheetTable from 'components/SheetTable/SheetTable';
import styles from '../style.module.scss';

const RowTwo: React.FC<{ configTable: any }> = ({ configTable }) => (
  <>
    <Row
      className={styles.row2}
      gutter={[
        { xs: 0, sm: 20 },
        { xs: 20, sm: 0 },
      ]}
    >
      <Row className={`${styles.subTitle} ${styles.borderSubTitleB}`}>
        <div className="left">年別売上推移表</div>
      </Row>
      <Col xs={24} sm={18}>
        {configTable?.configTableTab4Two && (
          <SheetTable dataCfg={configTable?.configTableTab4Two} options={configTable?.OPTIONS_TABLE_TAB4_2} />
        )}
      </Col>
    </Row>
    {configTable?.configTableTab4Two && (
      <table className="for-test" hidden>
        <tr>
          <td data-testid="columncharttab42-row0-row1">{configTable?.configTableTab4Two.data[0].row1}</td>
          <td data-testid="columncharttab42-row0-val1">{configTable?.configTableTab4Two.data[0].val1}</td>
          <td data-testid="columncharttab42-row0-val2">{configTable?.configTableTab4Two.data[0].val2}</td>
          <td data-testid="columncharttab42-row0-val3">{configTable?.configTableTab4Two.data[0].val3}</td>
        </tr>
        <tr>
          <td data-testid="columncharttab42-row1-row1">{configTable?.configTableTab4Two.data[1].row1}</td>
          <td data-testid="columncharttab42-row1-val1">{configTable?.configTableTab4Two.data[1].val1}</td>
          <td data-testid="columncharttab42-row1-val2">{configTable?.configTableTab4Two.data[1].val2}</td>
          <td data-testid="columncharttab42-row1-val3">{configTable?.configTableTab4Two.data[1].val3}</td>
        </tr>
        <tr>
          <td data-testid="columncharttab42-row2-row1">{configTable?.configTableTab4Two.data[2].row1}</td>
          <td data-testid="columncharttab42-row2-val1">{configTable?.configTableTab4Two.data[2].val1}</td>
          <td data-testid="columncharttab42-row2-val2">{configTable?.configTableTab4Two.data[2].val2}</td>
          <td data-testid="columncharttab42-row2-val3">{configTable?.configTableTab4Two.data[2].val3}</td>
        </tr>
        <tr>
          <td data-testid="columncharttab42-row3-row1">{configTable?.configTableTab4Two.data[3].row1}</td>
          <td data-testid="columncharttab42-row3-val1">{configTable?.configTableTab4Two.data[3].val1}</td>
          <td data-testid="columncharttab42-row3-val2">{configTable?.configTableTab4Two.data[3].val2}</td>
          <td data-testid="columncharttab42-row3-val3">{configTable?.configTableTab4Two.data[3].val3}</td>
        </tr>
        <tr>
          <td data-testid="columncharttab42-row4-row1">{configTable?.configTableTab4Two.data[4].row1}</td>
          <td data-testid="columncharttab42-row4-val1">{configTable?.configTableTab4Two.data[4].val1}</td>
          <td data-testid="columncharttab42-row4-val2">{configTable?.configTableTab4Two.data[4].val2}</td>
          <td data-testid="columncharttab42-row4-val3">{configTable?.configTableTab4Two.data[4].val3}</td>
        </tr>
      </table>
    )}
  </>
);

export default RowTwo;
