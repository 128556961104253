import React from 'react';
import { Col, RadioChangeEvent, Row } from 'antd';
import ColumnChart from 'components/ColumnChart/ColumnChart';
import SheetTable from 'components/SheetTable/SheetTable';
import { RADIO_DATA } from 'pages/Client/AssignmentsResults/Tabs/tab1/assignmentsResults';
import CommonRadio from 'components/CommonRadio/CommonRadio';
import { TTargetYear } from 'libs/calculators';
import styles from '../style.module.scss';

const RowFour: React.FC<{
  configTable: any;
  onChange?: (e: RadioChangeEvent) => void;
  statementExpenseEachYear: TTargetYear;
}> = ({ configTable, onChange, statementExpenseEachYear }) => (
  <>
    <Row
      style={{ marginTop: '100px' }}
      gutter={[
        { xs: 0, sm: 30 },
        { xs: 20, sm: 0 },
      ]}
    >
      <Col xs={24} sm={12}>
        <CommonRadio radioOptions={RADIO_DATA} onChange={onChange} value={String(statementExpenseEachYear)} />
        <div className={styles.subTitle}>
          <div className="left">{statementExpenseEachYear}年目コスト</div>
        </div>
        {configTable?.dataColumn && <ColumnChart configChart={configTable?.dataColumn} />}
      </Col>
      <Col xs={24} sm={12}>
        {configTable?.dataTableDiagram2 && (
          <SheetTable dataCfg={configTable?.dataTableDiagram2} options={configTable?.OPTIONS_TABLE} />
        )}
      </Col>
    </Row>
    {configTable?.dataColumn && (
      <table className="for-test" hidden>
        {' '}
        <tr>
          <td data-testid="column-row0-year">{configTable.dataColumn.data[0].year}</td>
          <td data-testid="column-row0-value">{configTable.dataColumn.data[0].value}</td>
        </tr>
        <tr>
          <td data-testid="column-row1-year">{configTable.dataColumn.data[1].year}</td>
          <td data-testid="column-row1-value">{configTable.dataColumn.data[1].value}</td>
        </tr>
        <tr>
          <td data-testid="column-row2-year">{configTable.dataColumn.data[2].year}</td>
          <td data-testid="column-row2-value">{configTable.dataColumn.data[2].value}</td>
        </tr>
        <tr>
          <td data-testid="column-row3-year">{configTable.dataColumn.data[3].year}</td>
          <td data-testid="column-row3-value">{configTable.dataColumn.data[3].value}</td>
        </tr>
        <tr>
          <td data-testid="column-row4-year">{configTable.dataColumn.data[4].year}</td>
          <td data-testid="column-row4-value">{configTable.dataColumn.data[4].value}</td>
        </tr>
        <tr>
          <td data-testid="column-row5-year">{configTable.dataColumn.data[5].year}</td>
          <td data-testid="column-row5-value">{configTable.dataColumn.data[5].value}</td>
        </tr>
        <tr>
          <td data-testid="column-row6-year">{configTable.dataColumn.data[6].year}</td>
          <td data-testid="column-row6-value">{configTable.dataColumn.data[6].value}</td>
        </tr>
        <tr>
          <td data-testid="column-row7-year">{configTable.dataColumn.data[7].year}</td>
          <td data-testid="column-row7-value">{configTable.dataColumn.data[7].value}</td>
        </tr>
        <tr>
          <td data-testid="column-row8-year">{configTable.dataColumn.data[8].year}</td>
          <td data-testid="column-row8-value">{configTable.dataColumn.data[8].value}</td>
        </tr>
        <tr>
          <td data-testid="column-row9-year">{configTable.dataColumn.data[9].year}</td>
          <td data-testid="column-row9-value">{configTable.dataColumn.data[9].value}</td>
        </tr>
      </table>
    )}
    {configTable?.dataTableDiagram2 && (
      <table className="for-test" hidden>
        <tr>
          <td data-testid="tablediagram2-row0-pl">{configTable.dataTableDiagram2.data[0].PL}</td>
          <td data-testid="tablediagram2-row0-value">{configTable.dataTableDiagram2.data[0].value}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram2-row1-pl">{configTable.dataTableDiagram2.data[1].PL}</td>
          <td data-testid="tablediagram2-row1-value">{configTable.dataTableDiagram2.data[1].value}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram2-row2-pl">{configTable.dataTableDiagram2.data[2].PL}</td>
          <td data-testid="tablediagram2-row2-type">{configTable.dataTableDiagram2.data[2].type}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram2-row3-pl">{configTable.dataTableDiagram2.data[3].PL}</td>
          <td data-testid="tablediagram2-row3-type">{configTable.dataTableDiagram2.data[3].type}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram2-row4-pl">{configTable.dataTableDiagram2.data[4].PL}</td>
          <td data-testid="tablediagram2-row4-type">{configTable.dataTableDiagram2.data[4].type}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram2-row5-pl">{configTable.dataTableDiagram2.data[5].PL}</td>
          <td data-testid="tablediagram2-row5-type">{configTable.dataTableDiagram2.data[5].type}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram2-row6-pl">{configTable.dataTableDiagram2.data[6].PL}</td>
          <td data-testid="tablediagram2-row6-value">{configTable.dataTableDiagram2.data[6].value}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram2-row7-pl">{configTable.dataTableDiagram2.data[7].PL}</td>
          <td data-testid="tablediagram2-row7-type">{configTable.dataTableDiagram2.data[7].type}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram2-row8-pl">{configTable.dataTableDiagram2.data[8].PL}</td>
          <td data-testid="tablediagram2-row8-type">{configTable.dataTableDiagram2.data[8].type}</td>
        </tr>
        <tr>
          <td data-testid="tablediagram2-row9-pl">{configTable.dataTableDiagram2.data[9].PL}</td>
          <td data-testid="tablediagram2-row9-type">{configTable.dataTableDiagram2.data[9].type}</td>
        </tr>
      </table>
    )}
  </>
);
export default RowFour;
