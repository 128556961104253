import React from 'react';
import styles from './style.module.scss';
import SheetTable from '../../../components/SheetTable/SheetTable';
import {
  OPTIONS_TABLE,
  OPTIONS_TABLE2,
  OPTIONS_TABLE3,
  OPTIONS_TABLE7,
  THEME_TABLE,
} from './AssignmentQuestion7.state';

// eslint-disable-next-line complexity
const AssignmentQuestionForm: React.FC<{ configTable: any }> = ({ configTable }) => (
  <div>
    <div>
      {/* row 2 */}
      <p>①まず、下図の資金繰り計画表を確認してください。</p>
      {configTable?.configTable2 && (
        <SheetTable dataCfg={configTable?.configTable2} options={OPTIONS_TABLE2} themeCfg={THEME_TABLE} />
      )}
      <p>
        ②
        赤色のセルは、現金が不足している月を表しています。その月に現金がそれだけ不足し、給与の支払い、水道光熱費の支払い等ができずに倒産します。
      </p>
      <p className={styles.mb_50}>
        ③
        赤色のセルが表示されないように補助金を利用するか、資本金をいくら用意するのか、銀行からいくら借り入れるのかを設定してみましょう。
      </p>
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table2-row0-10">{configTable.configTable2.data[0]['10']}</td>
        <td data-testid="table2-row0-11">{configTable.configTable2.data[0]['11']}</td>
        <td data-testid="table2-row0-12">{configTable.configTable2.data[0]['12']}</td>
        <td data-testid="table2-row0-1">{configTable.configTable2.data[0]['1']}</td>
        <td data-testid="table2-row0-2">{configTable.configTable2.data[0]['2']}</td>
        <td data-testid="table2-row0-3">{configTable.configTable2.data[0]['3']}</td>
        <td data-testid="table2-row0-4">{configTable.configTable2.data[0]['4']}</td>
        <td data-testid="table2-row0-5">{configTable.configTable2.data[0]['5']}</td>
        <td data-testid="table2-row0-6">{configTable.configTable2.data[0]['6']}</td>
        <td data-testid="table2-row0-7">{configTable.configTable2.data[0]['7']}</td>
        <td data-testid="table2-row0-8">{configTable.configTable2.data[0]['8']}</td>
        <td data-testid="table2-row0-9">{configTable.configTable2.data[0]['9']}</td>
      </tr>
      <tr>
        <td data-testid="table2-row1-10">{configTable.configTable2.data[1]['10']}</td>
        <td data-testid="table2-row1-11">{configTable.configTable2.data[1]['11']}</td>
        <td data-testid="table2-row1-12">{configTable.configTable2.data[1]['12']}</td>
        <td data-testid="table2-row1-1">{configTable.configTable2.data[1]['1']}</td>
        <td data-testid="table2-row1-2">{configTable.configTable2.data[1]['2']}</td>
        <td data-testid="table2-row1-3">{configTable.configTable2.data[1]['3']}</td>
        <td data-testid="table2-row1-4">{configTable.configTable2.data[1]['4']}</td>
        <td data-testid="table2-row1-5">{configTable.configTable2.data[1]['5']}</td>
        <td data-testid="table2-row1-6">{configTable.configTable2.data[1]['6']}</td>
        <td data-testid="table2-row1-7">{configTable.configTable2.data[1]['7']}</td>
        <td data-testid="table2-row1-8">{configTable.configTable2.data[1]['8']}</td>
        <td data-testid="table2-row1-9">{configTable.configTable2.data[1]['9']}</td>
      </tr>
      <tr>
        <td data-testid="table2-row2-10">{configTable.configTable2.data[2]['10']}</td>
        <td data-testid="table2-row2-11">{configTable.configTable2.data[2]['11']}</td>
        <td data-testid="table2-row2-12">{configTable.configTable2.data[2]['12']}</td>
        <td data-testid="table2-row2-1">{configTable.configTable2.data[2]['1']}</td>
        <td data-testid="table2-row2-2">{configTable.configTable2.data[2]['2']}</td>
        <td data-testid="table2-row2-3">{configTable.configTable2.data[2]['3']}</td>
        <td data-testid="table2-row2-4">{configTable.configTable2.data[2]['4']}</td>
        <td data-testid="table2-row2-5">{configTable.configTable2.data[2]['5']}</td>
        <td data-testid="table2-row2-6">{configTable.configTable2.data[2]['6']}</td>
        <td data-testid="table2-row2-7">{configTable.configTable2.data[2]['7']}</td>
        <td data-testid="table2-row2-8">{configTable.configTable2.data[2]['8']}</td>
        <td data-testid="table2-row2-9">{configTable.configTable2.data[2]['9']}</td>
      </tr>
      <tr>
        <td data-testid="table2-row3-10">{configTable.configTable2.data[3]['10']}</td>
        <td data-testid="table2-row3-11">{configTable.configTable2.data[3]['11']}</td>
        <td data-testid="table2-row3-12">{configTable.configTable2.data[3]['12']}</td>
        <td data-testid="table2-row3-1">{configTable.configTable2.data[3]['1']}</td>
        <td data-testid="table2-row3-2">{configTable.configTable2.data[3]['2']}</td>
        <td data-testid="table2-row3-3">{configTable.configTable2.data[3]['3']}</td>
        <td data-testid="table2-row3-4">{configTable.configTable2.data[3]['4']}</td>
        <td data-testid="table2-row3-5">{configTable.configTable2.data[3]['5']}</td>
        <td data-testid="table2-row3-6">{configTable.configTable2.data[3]['6']}</td>
        <td data-testid="table2-row3-7">{configTable.configTable2.data[3]['7']}</td>
        <td data-testid="table2-row3-8">{configTable.configTable2.data[3]['8']}</td>
        <td data-testid="table2-row3-9">{configTable.configTable2.data[3]['9']}</td>
      </tr>
      <tr>
        <td data-testid="table2-row4-10">{configTable.configTable2.data[4]['10']}</td>
        <td data-testid="table2-row4-11">{configTable.configTable2.data[4]['11']}</td>
        <td data-testid="table2-row4-12">{configTable.configTable2.data[4]['12']}</td>
        <td data-testid="table2-row4-1">{configTable.configTable2.data[4]['1']}</td>
        <td data-testid="table2-row4-2">{configTable.configTable2.data[4]['2']}</td>
        <td data-testid="table2-row4-3">{configTable.configTable2.data[4]['3']}</td>
        <td data-testid="table2-row4-4">{configTable.configTable2.data[4]['4']}</td>
        <td data-testid="table2-row4-5">{configTable.configTable2.data[4]['5']}</td>
        <td data-testid="table2-row4-6">{configTable.configTable2.data[4]['6']}</td>
        <td data-testid="table2-row4-7">{configTable.configTable2.data[4]['7']}</td>
        <td data-testid="table2-row4-8">{configTable.configTable2.data[4]['8']}</td>
        <td data-testid="table2-row4-9">{configTable.configTable2.data[4]['9']}</td>
      </tr>
    </table>

    {/* row 3 */}
    <div>
      <p className={styles.p_2}>初期投資費用合計</p>
      {configTable.configTable3 && (
        <SheetTable dataCfg={configTable?.configTable3} options={OPTIONS_TABLE3} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table3-row0-col1">{configTable.configTable3.data[0].col1}</td>
      </tr>
    </table>

    {/* row 4 */}
    <div>
      <p className={styles.p_2}>資金調達</p>
      {configTable.configTable4 && (
        <SheetTable dataCfg={configTable?.configTable4} options={OPTIONS_TABLE} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table4-row0-value">{configTable.configTable4.data[0].value}</td>
      </tr>
      <tr>
        <td data-testid="table4-row1-value">{configTable.configTable4.data[1].value}</td>
      </tr>
      <tr>
        <td data-testid="table4-row2-value">{configTable.configTable4.data[2].value}</td>
      </tr>
      <tr>
        <td data-testid="table4-row3-value">{configTable.configTable4.data[3].value}</td>
      </tr>
    </table>

    {/* row 5 */}
    <div className={styles.w60}>
      <p className={styles.p_2}>銀行からの借入情報</p>
      {configTable.configTable5 && (
        <SheetTable dataCfg={configTable?.configTable5} options={OPTIONS_TABLE3} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table5-row0-value">{configTable.configTable5.data[0].value}</td>
        <td data-testid="table5-row1-value">{configTable.configTable5.data[1].value}</td>
      </tr>
    </table>

    {/* row 6 */}
    <div className={styles.w60}>
      <p className={styles.p_2}>銀行からの新規借入額</p>
      {configTable.configTable6 && (
        <SheetTable dataCfg={configTable?.configTable6} options={OPTIONS_TABLE} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table6-row0-value">{configTable.configTable6.data[0].value}</td>
      </tr>
      <tr>
        <td data-testid="table6-row1-value">{configTable.configTable6.data[1].value}</td>
      </tr>
      <tr>
        <td data-testid="table6-row2-value">{configTable.configTable6.data[2].value}</td>
      </tr>
      <tr>
        <td data-testid="table6-row3-value">{configTable.configTable6.data[3].value}</td>
      </tr>
      <tr>
        <td data-testid="table6-row4-value">{configTable.configTable6.data[4].value}</td>
      </tr>
    </table>

    {/* row 7 */}
    <div className={styles.w60}>
      <p className={styles.p_2}>銀行返済予定表</p>
      {configTable.configTable7 && (
        <SheetTable dataCfg={configTable?.configTable7} options={OPTIONS_TABLE7} themeCfg={THEME_TABLE} />
      )}
    </div>
    <table className="for-test" hidden>
      <tr>
        <td data-testid="table7-row0-col1">{configTable.configTable7.data[0].col1}</td>
        <td data-testid="table7-row0-col2">{configTable.configTable7.data[0].col2}</td>
        <td data-testid="table7-row0-col3">{configTable.configTable7.data[0].col3}</td>
        <td data-testid="table7-row0-col4">{configTable.configTable7.data[0].col4}</td>
        <td data-testid="table7-row0-col5">{configTable.configTable7.data[0].col5}</td>
      </tr>
      <tr>
        <td data-testid="table7-row1-col1">{configTable.configTable7.data[1].col1}</td>
        <td data-testid="table7-row1-col2">{configTable.configTable7.data[1].col2}</td>
        <td data-testid="table7-row1-col3">{configTable.configTable7.data[1].col3}</td>
        <td data-testid="table7-row1-col4">{configTable.configTable7.data[1].col4}</td>
        <td data-testid="table7-row1-col5">{configTable.configTable7.data[1].col5}</td>
      </tr>
      <tr>
        <td data-testid="table7-row2-col1">{configTable.configTable7.data[2].col1}</td>
        <td data-testid="table7-row2-col2">{configTable.configTable7.data[2].col2}</td>
        <td data-testid="table7-row2-col3">{configTable.configTable7.data[2].col3}</td>
        <td data-testid="table7-row2-col4">{configTable.configTable7.data[2].col4}</td>
        <td data-testid="table7-row2-col5">{configTable.configTable7.data[2].col5}</td>
      </tr>
      <tr>
        <td data-testid="table7-row3-col1">{configTable.configTable7.data[3].col1}</td>
        <td data-testid="table7-row3-col2">{configTable.configTable7.data[3].col2}</td>
        <td data-testid="table7-row3-col3">{configTable.configTable7.data[3].col3}</td>
        <td data-testid="table7-row3-col4">{configTable.configTable7.data[3].col4}</td>
        <td data-testid="table7-row3-col5">{configTable.configTable7.data[3].col5}</td>
      </tr>
      <tr>
        <td data-testid="table7-row4-col1">{configTable.configTable7.data[4].col1}</td>
        <td data-testid="table7-row4-col2">{configTable.configTable7.data[4].col2}</td>
        <td data-testid="table7-row4-col3">{configTable.configTable7.data[4].col3}</td>
        <td data-testid="table7-row4-col4">{configTable.configTable7.data[4].col4}</td>
        <td data-testid="table7-row4-col5">{configTable.configTable7.data[4].col5}</td>
      </tr>
    </table>
  </div>
);

export default AssignmentQuestionForm;
